const studies = [
  {
    id: "MB",
    label: "Inférieur au Bac",
  },
  {
    id: "B0",
    label: "Bac",
  },
  {
    id: "B2",
    label: "Bac+2",
  },
  {
    id: "B3",
    label: "Bac+3",
  },
  {
    id: "B5",
    label: "Bac+5 et plus",
  },
];
const StudiesService = {
  studies: {},
  getStudies: () => Object.values(StudiesService.studies),
  getStudiesIds: () => Object.keys(StudiesService.studies),
  getStudiesKeyed: () => StudiesService.studies,
  getStudy: id => StudiesService.studies[id] || null,
};
studies.forEach(study => {
  StudiesService.studies[study.id] = {
    id: study.id,
    value: study.id,
    ...study,
  };
}); 

export default StudiesService;