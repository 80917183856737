import { useCallback, useEffect, useState } from "react";

// Providers
import { useGame } from '../../providers/GameProvider';
import { useThemeToast } from "../../providers/ThemeProvider";

// Service
import AvatarsService from "../../services/avatars.service";

// Components
import { RadioGroup, cn } from "@nextui-org/react";
import { VCenter, VTitle, VCard, VButton, VPage, VMessenger, VImage, VSpace } from "../../components/Vues";
import RadioImage from "../RadioImage";
import ToastMessage from "../ToastMessage";
import { VideoPlayer } from "../VideoPlayer";

const BriefPersonnage = ({ onEnd, isNextLoading }) => {

  // Contexts
  const { avatar, changeAvatar } = useGame();

  // Toasts
  const showToast = useThemeToast(<ToastMessage image="01"><p>Excellent choix !</p></ToastMessage>);

  // States
  const [avatarList, setAvatarList] = useState([]);

  // Datalist
  useEffect(() => {
    setAvatarList(AvatarsService.getAvatars());
  }, []);

  // Events
  const handleChange = useCallback(avatarValue => {
    showToast(!!avatarValue);
    changeAvatar(avatarValue);
  }, [changeAvatar, showToast]);

  const goToNext = useCallback(() => {
    onEnd();
  }, [onEnd]);

  return (

    <VPage>

      <VCenter>

        <VideoPlayer className={`w-full border-2 border-ifpass-purple bg-black/50 mb-4`} src='/videos/common/ReglesDuJeu_IFCAST.mp4' poster='/videos/common/ReglesDuJeu_IFCAST.png'>
          <track default={false} kind="captions" srcLang="Sous-titrage français" src='/videos/common/ReglesDuJeu_IFCAST.vtt' />
        </VideoPlayer>


        <VTitle>
          CHOISIS TON <span className="font-bold">PERSONNAGE</span>
        </VTitle>

        <VMessenger audio="02_02.mp3" autoPlay={false} >
          C’est parti ! Choisis ton personnage !
        </VMessenger>

        <VCard className="w-full py-8 font-medium">
          <VCenter >

            <VSpace />

            <RadioGroup
              label=""
              value={avatar ? avatar.id : null}
              onValueChange={handleChange}
              classNames={{
                base: cn(
                  " flex ",
                ),
                wrapper: " grid grid-cols-3 gap-4 pb-4 ",
              }}
            >
              {avatarList.map(item => (
                <RadioImage value={item.id} key={item.id} className="">
                  <VImage src={item.src} width={140} radius="none" />
                </RadioImage>
              ))}
            </RadioGroup>

            <VButton onPress={goToNext} isLoading={isNextLoading} isDisabled={!avatar || !avatar.id}>valider</VButton>

          </VCenter>

        </VCard>

      </VCenter>

    </VPage>
  );
}

export default BriefPersonnage;