import { useEffect, useState } from "react";

// Providers
import { useGame } from "../providers/GameProvider";

// Services
import AssetsService from "../services/assets.service";
import AxesService from "../services/axes.service";
import AvatarsService from "../services/avatars.service";
import TeamsService from "../services/teams.service";
import AwardsService from "../services/awards.service";
import StudiesService from "../services/studies.service";
import CursusService from "../services/cursus.service";

// Components
import { VLoading, VTitle } from "./Vues";
import { JobInfosInner } from "./JobInfos";
import { competencesRatingBase } from "./game/CoachingCompetences";
import ProfileProgress from "./ProfileProgress";

// Icons
import { FaGears, FaRankingStar, FaRegStar, FaRegThumbsUp, FaStar } from "react-icons/fa6";

export const UserBook = ({ book, children, displayPermalink, ...props }) => {

  // Contexts
  const { confIsLoaded, configCursusIsLoaded } = useGame();

  // States
  const [isGlobalLoading, setIsGlobalLoading] = useState(false);
  const [team, setTeam] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [awards, setAwards] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [cursuses, setCursuses] = useState([]);

  // Wait for config (questions selection)
  useEffect(() => {
    setIsGlobalLoading(!confIsLoaded && !configCursusIsLoaded);
  }, [confIsLoaded, configCursusIsLoaded]);

  // Cursus
  useEffect(() => {
    if (configCursusIsLoaded && book && book.job) {
      if (book.job.cursus) {
        const cursuses = {};
        book.job.cursus.forEach(cursusId => {
          if (!Object.keys(cursuses).includes(cursusId)) {
            const cursus = CursusService.getCursus(cursusId);
            if (cursus && cursus !== null) {
              cursuses[cursusId] = cursus;
            }
          }
        });
        setCursuses(Object.values(cursuses));
      }
    }
  }, [configCursusIsLoaded, book, setCursuses]);

  // Avatar / Team
  useEffect(() => {
    setAvatar(prevAvatar => book && (!prevAvatar || (prevAvatar && prevAvatar.id !== book.avatar)) ? AvatarsService.getAvatar(book.avatar) : prevAvatar);
    setTeam(prevTeam => book && (!prevTeam || (prevTeam && prevTeam.id !== book.team)) ? TeamsService.getTeam(book.team) : prevTeam);
  }, [book]);

  // Awards
  useEffect(() => {
    setAwards(prevAwards => book && book.awards ? book.awards.map(awardId => ({
      id: awardId,
      ...(AwardsService.getAward(awardId)),
    })) : prevAwards);
  }, [book, setAwards]);

  // Per profile score
  useEffect(() => {
    const profiles = book && book.axes ? AxesService.compute(book.axes) : [];
    setProfiles(AxesService.sort(profiles));
  }, [book]);

  // useEffect(() => {
  //   console.log(book);
  // }, [book]);

  return (
    <section className="md:p-4 w-full" {...props}>
      <div className="text-left items-center flex flex-col flex-nowrap">

        <p className="font-bold text-4xl mb-7">BOOK PROFESSIONNEL</p>

        {!book ? <>
          <VTitle>BOOK à remplir</VTitle>
        </> : <>
    
          {!isGlobalLoading ? <>
    
            <div className="grid grid-cols-1 lg:grid-cols-4 gap-3 w-full">
    
              {/* Column 1 */}
    
              <div className="bg-black/30 p-4 rounded-lg">
                
                {/* PROFILE */}
                <div className="border-b-2 text-center">
                  {book && (avatar || team) ? <div className="grid place-items-center h-16">
                    {avatar && avatar.src ? <img src={AssetsService.getImageUrl(avatar.src)} className="grayscale invert w-16" alt="Avatar" /> : <></>}
                    {team && team.src ? <img src={AssetsService.getImageUrl(team.src)} className="w-12 relative top-[-30px]  right-[-20px]" alt="Team" /> : <></>}
                  </div> : <></>}
                  {book && book.infos ? <div className="text-md font-bold my-2">{book.infos.firstName} {book.infos.lastName}</div> : <></>}
                </div>
    
                {/* SOFT SKILLS */}
                {awards && awards.length ? <>
                  <div className="my-2 border-b-2 pb-2">
                    <div className="float-left"><FaStar className="w-9 h-9 bg-white p-2 rounded-full text-black mix-blend-screen" /></div>
                    <div className="pl-12 pb-4">
                      <div className="text-md font-bold mb-3">SOFT SKILLS</div>
                      {awards.sort((a, b) => a.weight - b.weight).map(award => (<div className="text-md" key={award.id}>{award.label}</div>))}
                    </div>
                  </div>
                </> : <></>}
    
                {/* PERSONNALITÉ */}
                {profiles && profiles.length ? <>
                  <div className="my-2 pb-2">
                    <div className="float-left"><FaRankingStar className="w-9 h-9 bg-white p-1 rounded-full text-black mix-blend-screen" /></div>
                    <div className="pl-12">
    
                      <div className="text-md font-bold mb-3">PERSONNALITÉ</div>
    
                      {profiles.map(profile => (
                        <ProfileProgress key={profile.id} profile={profile}
                          classNames={{
                            base: "mb-1 !gap-0",
                          }}
                        />
                      ))}
    
                    </div>
                  </div>
                </> : <></>}
                
              </div>
    
              {/* Column 2 */}
              <div className="bg-white/30 p-4 rounded-lg col-span-2">

                {book && book.job ? <>
                  <p className="font-bold text-3xl text-center border-b-2 mb-4">{book.job.name.toUpperCase()}</p>
                  <JobInfosInner job={book.job} classNames={{}} />
                </> : <></>}
    
              </div>
    
              {/* Column 3 */}
              <div className="bg-black/30 p-4 rounded-lg">

                {book && book.job && book.withCursus ? <>
                  <div className="my-2 border-b-2 pb-2">
                    <div className="text-md font-bold">SCRIPT DE CARRIÈRE</div>
                  </div>

                  {/* COMPÉTENCES */}
                  <div className="my-2 border-b-2 pb-2">
      
                    <div className="float-left"><FaGears className="w-9 h-9 bg-white p-2 rounded-full text-black mix-blend-screen" /></div>
                    <div className="">
                      <div className="ml-12 mb-6 text-md font-bold">COMPÉTENCES</div>

                      {book.job.skills.map((text, index) => (<div key={index} className="flex gap-6 my-2 rounded-full text-white p-0">
                        <div className={`flex gap-0 bg-white/10 py-1 px-2 rounded-full`}>
                          {[...Array(competencesRatingBase)].map((x, i) => book.competencesRating[index] > i ? <FaStar key={i} /> : <FaRegStar key={i} />)}
                        </div>
                        <span>{text}</span>
                      </div>))}
      
                    </div>
                  </div>
      
                  {/* FORMATIONS */}
                  {cursuses.length && <div className="my-2 border-b-2 pb-3">
                    <div className="text-md font-bold">FORMATIONS</div>
                    <div className="grid grid-cols-1 gap-x-3 gap-y-2">
                      {cursuses.map((cursus) => <div key={cursus.id} className="text-md leading-[1em] flex gap-2 items-center justify-start">
                        <span className="w-4">{book && book.cursusLikes && book.cursusLikes.includes(cursus.id) ? <FaRegThumbsUp /> : <></>}</span>
                        <span>{cursus.name}</span>
                      </div>)}
                    </div>
                  </div>}
      
                  {/* DIPLOME */}
                  {book.job.studies && StudiesService.getStudy(book.job.studies) ? <div className="my-2 border-b-2 pb-2">
                    <div className="text-md font-bold">DIPLÔME</div>
                    <div className="text-md">{StudiesService.getStudy(book.job.studies).label}</div>
                  </div> : <></>}
      
                  {/* MODALITÉ */}
                  {book.job.modality && <div className="my-2 pb-2">
                    <div className="text-md font-bold">MODALITÉ</div>
                    <div className="text-md">{book.job.modality}</div>
                  </div>}
                </> : <></>}
    
              </div>
            </div>
    
          </> : <VLoading label="CHARGEMENT" />}
      
        </>}
        
        {children}

        {displayPermalink && <div className="mt-4">
          <a href={AssetsService.getUrl('book')} target="_blank" rel="noreferrer">Voir le book dans une nouvelle page</a>
        </div>}

      </div>
    </section>
  );
}

export default UserBook;