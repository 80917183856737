import { useCallback } from "react";

// Components
import { VCenter, VTitle, VButton, VPage, VImage, VSpace } from "../../components/Vues";

const AccueilHome = ({ onEnd, isNextLoading }) => {

  const goToNext = useCallback(() => {
    onEnd();
  }, [onEnd]);

  return (
    <VPage>

      <VCenter>

        <VTitle>
          <p>TON <span className="font-bold">AVENIR</span></p>
          <p>COMMENCE <span className="font-bold">MAINTENANT</span></p>
        </VTitle>

        <VImage width={450} src="logo-ifcast.png" alt="Logo IFCAST"></VImage>

        <VSpace className="m-4">
          <VButton onPress={goToNext} isLoading={isNextLoading}>entrer</VButton>
        </VSpace>

        <div className="grid grid-cols-2 gap-12 text-center mt-4 mb-4">
          <div className="flex flex-col gap-2">
            <p>Serious game<br />proposé par l'IFPASS</p>
            <div className="w-full flex-grow flex items-center justify-center rounded bg-white p-4">
              <VImage width={160} src="logo-ifpass.svg" alt="Logo IFPASS"></VImage>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <p>Soutenu par<br />le programme Atlas</p>
            <div className="w-full flex-grow flex items-center justify-center rounded bg-white">
              <VImage width={120} src="logo-cfa-de-demain-atlas.jpg" alt="Logo Atlas"></VImage>
            </div>
          </div>
        </div>

      </VCenter>

    </VPage>
  );
}

export default AccueilHome;