import { useCallback, useEffect } from 'react';

// Providers
import { useGame } from '../../providers/GameProvider';

// Services
import JobsService from '../../services/jobs.service';

// Components
import SessionWrapper from './SessionWrapper';
import { gameProgression as toProgression, gamePage as toPage } from './Coaching';
import ReperagesHome from '../../components/game/ReperagesHome';
import ReperagesEnqueteIntro from '../../components/game/ReperagesEnqueteIntro';
import ReperagesEnquete from '../../components/game/ReperagesEnquete';
import ReperagesResults from '../../components/game/ReperagesResults';
import ReperagesChoose from '../../components/game/ReperagesChoose';
import ReperagesEnd from '../../components/game/ReperagesEnd';

export const gameProgression = 40;
export const gameProgressionTo = toProgression;
export const gameChapter = '03';
export const gameBackground = '06';
export const gamePage = 'reperages';
export const gameProgressBar = true;
export const gameFrame = true;

export const gameSteps = [
  {
    id: 'home',
    component: (props) => (<ReperagesHome {...props} />),
    saveOnEnd: false,
  },
  {
    id: 'enquete',
    component: (props) => (<><ReperagesEnqueteIntro {...props} /><ReperagesEnquete {...props} /></>),
    saveOnEnd: true,
  },
  {
    id: 'results',
    component: (props) => (<ReperagesResults {...props} />),
    saveOnEnd: true,
  },
  {
    id: 'choose',
    component: (props) => (<ReperagesChoose {...props} />),
    saveOnEnd: true,
  },
  {
    id: 'end',
    component: (props) => (<ReperagesEnd {...props} />),
    saveOnEnd: true,
  },
];

const Reperages = () => {

  // Contexts
  const { appDebugMode, configJobsIsLoaded, userInfos, avatarId, teamId, awards, userAxes, resetBookWithData, resetReperages, jobsQuestions, setJobsQuestions, userJobs, setUserJobs, userJobFavorite } = useGame();

  // Reset all infos
  const resetOnStart = useCallback(() => {
    resetReperages();
    // ensure book is completed
    if (userInfos) {
      resetBookWithData({
        infos: userInfos,
        avatar: avatarId,
        team: teamId,
        awards,
        axes: userAxes,
      });
    }
  }, [userInfos, avatarId, teamId, awards, userAxes, resetBookWithData, resetReperages]);

  // Log axes
  useEffect(() => {
    if (userAxes) {
      appDebugMode && console.log('axes', userAxes);
    }
  }, [appDebugMode, userAxes]);

  // Compute jobs scores
  useEffect(() => {
    if (configJobsIsLoaded && userAxes) {
      setUserJobs(prevUserJobs => {
        const newComputedJobs = JobsService.compute(userAxes);
        if (prevUserJobs === null || !Object.keys(prevUserJobs).length) {
          appDebugMode && console.log('set UserJobs', newComputedJobs);
          return newComputedJobs;
        }
        const prevIds = JobsService.sort(prevUserJobs, 8, 'axes').map(job => job.id).sort().join('-');
        const currentIds = JobsService.sort(newComputedJobs, 8, 'axes').map(job => job.id).sort().join('-');
        appDebugMode && console.log('test UserJobs', prevIds, currentIds, userAxes, newComputedJobs, prevUserJobs);
        if (prevIds !== currentIds) {
          appDebugMode && console.log('regenerate UserJobs');
          return newComputedJobs;
        }
        return prevUserJobs;
      });
    }
  }, [appDebugMode, configJobsIsLoaded, userAxes, setUserJobs]);

  // Generate random questions / answers based on userJobs
  const generateQuestions = useCallback((jobs) => {
    appDebugMode && console.log('generate questions');
    return JobsService.generateQuestions(JobsService.sort(jobs, 8, 'axes'));
  }, [appDebugMode]);
  useEffect(() => {
    if (configJobsIsLoaded && userJobs && Object.keys(userJobs).length) {
      setJobsQuestions(prevQuestions => {
        const newQuestions = JobsService.generateQuestions(JobsService.sort(userJobs, 8, 'axes'));
        if (!prevQuestions || !prevQuestions.step1 || !prevQuestions.step1.length) {
          return newQuestions;
        }
        const prevIds = Object.keys(prevQuestions).map(step => prevQuestions[step].map(answer => answer.taskId)).sort().join('-');
        const currentIds = Object.keys(newQuestions).map(step => newQuestions[step].map(answer => answer.taskId)).sort().join('-');
        appDebugMode && console.log('test JobsQuestions', prevIds, currentIds, newQuestions);
        if (prevIds !== currentIds) {
          appDebugMode && console.log('regenerate JobsQuestions');
          return newQuestions;
        }
        return prevQuestions;
      });
    }
  }, [appDebugMode, configJobsIsLoaded, userJobs, setJobsQuestions, generateQuestions]);
  useEffect(() => {
    if (jobsQuestions && appDebugMode) {
      console.log('questions', jobsQuestions);
    }
  }, [appDebugMode, userAxes, jobsQuestions]);

  return <SessionWrapper
    progression={gameProgression}
    progressionTo={gameProgressionTo}
    page={gamePage}
    steps={gameSteps}
    chapter={gameChapter}
    background={gameBackground}
    progressBar={gameProgressBar}
    frame={gameFrame}
    resetOnStart={resetOnStart}
    valuesToSave={{ userJobs, userJobFavorite, userTeam: teamId }}
    nextPage={toPage}
  />;
}

export default Reperages;
