// Components
import { VCenter, VTitle, VSpace, VVideo, VIcon, VCard, VSticker } from "../../components/Vues";
import { FaPeopleArrows } from "react-icons/fa6";
import { Tooltip } from "@nextui-org/react";

const BriefTooltip = ({ content, children, ...props }) => {
  return (
    <Tooltip showArrow classNames={{ content: 'max-w-[240px] px-4 pt-2 pb-3' }} placement="left" color="primary" content={<div className="text-md leading-[1em]">{content}</div>} {...props}>
      <div className="cursor-help">{children}</div>
    </Tooltip>
  );
};

const BriefVideo2 = () => {

  return (

    <VCenter>

      <VTitle>
        <p>DANS L'ASSURANCE, ON PEUT</p><p><span className="font-bold">TOUT FAIRE</span></p>
      </VTitle>

      <VSpace />

      <VVideo id="Brief02" controls={true} autoPlay={false} />

      <VSticker>Alerte loi</VSticker>

      <VCard className="w-full sm:w-[550px] pt-8  text-left  font-medium ">

        <div className="pl-4">
          <p className="text-3xl font-bold leading-6">3 organismes sont autorisés à proposer des produits d’assurance à des clients</p>
          <ul className="p-2 text-md">
            <BriefTooltip content={<>Les sociétés d'assurance sont des entreprises privées à but lucratif.</>}>- Les sociétés d'assurance</BriefTooltip>
            <BriefTooltip content={<>Les mutuelles sont des organisations à but non lucratif basées sur la solidarité entre les membres, où les risques sont mutualisés.</>}>- Les mutuelles</BriefTooltip>
            <BriefTooltip content={<>Les institutions de prévoyance fournissent des régimes de retraite complémentaires aux salariés, fonctionnent sur le principe de solidarité entre les membres.</>}>- Les institutions de prévoyance</BriefTooltip>
          </ul>
        </div>

        <div className="flex  items-center gap-4">
          <div className="p-4">
            <VIcon className="w-20 h-20" icon={<FaPeopleArrows className=" w-14 h-14" />} />
          </div>
          <div>
            <p className="text-3xl font-bold leading-6">via des intermédiaires</p>
            <ul className="p-2 text-md">
              <BriefTooltip content={<>Les agents d'assurance représentent une compagnie d'assurance spécifique.</>}>- Agents généraux</BriefTooltip>
              <BriefTooltip content={<>Les courtiers en assurance travaillent de manière indépendante avec plusieurs compagnies pour trouver les meilleures options pour leurs clients.</>}>- Courtiers</BriefTooltip>
            </ul>
          </div>
        </div>

      </VCard >

    </VCenter >

  );
}

export default BriefVideo2;