const awards = [
  {
    id: "accueil",
    numero: 0,
    label: "Accueil général",
  },
  {
    id: "curiosite",
    numero: 1,
    label: "Curiosité",
  },
  {
    id: "rigueur",
    numero: 2,
    label: "Rigueur",
  },
  {
    id: "solutions",
    numero: 3,
    label: "Capacité à trouver des solutions",
  },
  {
    id: "responsabilites",
    numero: 4,
    label: "Sens des responsabilités",
  },
  {
    id: "communication",
    numero: 5,
    label: "Sens de la communication",
  }
];
const AwardsService = {
  awards: {},
  getAwards: () => Object.values(AwardsService.awards),
  getAwardsIds: () => Object.keys(AwardsService.awards),
  getAwardsKeyed: () => AwardsService.awards,
  getAward: id => AwardsService.awards[id] || null,
};
awards.forEach(award => {
  AwardsService.awards[award.id] = {
    id: award.id,
    ...award,
  };
}); 

export default AwardsService;