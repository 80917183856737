import { useEffect } from "react";
import { useTheme } from "../../providers/ThemeProvider";

const Error = ({ type, ...props }) => {
  
  const { changeBackground } = useTheme();

  // set current background
  useEffect(() => {
    changeBackground('01');
  }, [changeBackground]);

  return <section>Erreur {type}</section >;
};
export default Error;