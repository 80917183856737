import { useCallback, useEffect, useRef, useState } from "react";

// Providers
import { useGame } from '../../providers/GameProvider';
import { useThemeToast } from "../../providers/ThemeProvider.js";

// Services
import JobsService from "../../services/jobs.service.js";
import PlacesService from "../../services/places.service.js";
import StudiesService from "../../services/studies.service.js";
import CursusService from "../../services/cursus.service.js";
import AssetsService from "../../services/assets.service.js";

// Components
import { Button, ModalBody, ModalContent, ModalFooter, ModalHeader, Select, SelectItem, Tooltip } from "@nextui-org/react";
import { VCenter, VButton, VPage, VCard, VSticker, VMessenger, VSpace, VModalConfirm, VImage } from "../../components/Vues";
import LikeSwitch from "../LikeSwitch.js";
import ToastMessage from "../ToastMessage.js";

// Icons
import { FaGraduationCap, FaMapLocationDot } from "react-icons/fa6";

// -- Swiper
import { Pagination, A11y, Navigation, FreeMode } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// import Carousel effect module
import EffectCarousel from '../../components/swiper/effect-carousel.esm.js';
// import Carousel effect styles
import '../../components/swiper/effect-carousel.css';

const PlacesMap = ({ places }) => {
  const [placesLoaded, setPlaces] = useState([]);
  useEffect(() => {
    setPlaces(prevPlaces => places ? places.map(placeId => PlacesService.getPlace(placeId)) : prevPlaces);
  }, [places]);
  return <>
    <div className="w-full max-w-[220px] mx-auto relative">
      {placesLoaded.map(place => place && <Tooltip content={place.label} key={place.id}><img
        src={AssetsService.getImageUrl(place.alt ? 'map-marker-distance.svg' : 'map-marker.svg')}
        className={`text-white font-light text-sm md:text-md uppercase`}
        style={{
          width: '20px',
          height: '20px',
          position: 'absolute',
          top: `${place.y}%`,
          left: `${place.x}%`,
        }}
        alt={place.label}
      /></Tooltip>)}
      <img src={AssetsService.getImageUrl('map-france.svg')} className="w-full max-w-[400px]" alt="Carte" />
    </div>
  </>;
};

const PlacesList = ({ places }) => {
  const [placesLoaded, setPlaces] = useState([]);
  useEffect(() => {
    setPlaces(prevPlaces => places ? places.map(placeId => PlacesService.getPlace(placeId)) : prevPlaces);
  }, [places]);
  return <>
    <div className="flex flex-wrap gap-1 justify-center">
      {placesLoaded.map(place => place &&
        (place.link) ?
        <a href={place.link} target="_blank" rel="noreferrer" key={place.id} className="text-white bg-primary font-medium text-sm md:text-md uppercase rounded-full border-1 border-white/50 px-3 py-0">{place.label}</a>
        : <p key={place.id} className="text-white font-medium text-sm md:text-md uppercase rounded-full border-1 border-white/50 px-3 py-0">{place.label}</p>

      )}
    </div>
  </>;
};

const Cursus = ({ cursus, isActive, onClick }) => {
  return <>
    <div className={`${
        'flex w-full !min-w-[180px] -translate-x-6 sm:translate-x-3 md:w-[300px] lg:w-[380px] max-w-[280px] md:max-w-[500px] !min-h-[180px] h-auto justify-center text-center'
      } ${isActive
        ? 'bg-[#f4554e] shadow-lg'
        : 'bg-[#de4a42] shadow-2xl blur-[1px] hover:blur-none'
      } ${
        'mb-2 rounded-lg px-4 py-5 whitespace-normal'
      }`} onClick={onClick}>
      <div className="flex flex-col items-center justify-center">
        {/* <p className="text-white font-light text-sm md:text-md uppercase mb-2">Le petit + de cette formation</p> */}
        <p className="text-white font-bold text-lg lg:text-xl xl:text-2xl !leading-[1em] uppercase">{cursus.name}</p>
        {/* <div className="mt-4"><LikeSwitch isLiked={false} /></div> */}
      </div>
    </div>
  </>;
};

const CoachingMap = ({ onEnd, isNextLoading }) => {

  // Contexts
  const { appDebugMode, configJobsIsLoaded, configCursusIsLoaded, configPlacesIsLoaded, userJobFavorite, userJobs, cursusLikes, setCursusLikes, addToBook } = useGame();

  // Toasts
  const showToast = useThemeToast(<ToastMessage image="01"><p>Tu hésites encore ?<br />Cliques sur « en savoir + » pour consulter les fiches métiers.</p></ToastMessage>);

  // States
  const [isLoading, setIsLoading] = useState(true);
  const [activeSlide, setActiveSlide] = useState(0);
  const [activedSlides, setActivedSlides] = useState([]);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [job, setJob] = useState(null);
  const [cursuses, setCursuses] = useState([]);
  const [places, setPlaces] = useState([]);
  const [activePlaces, setActivePlaces] = useState([]);
  const [studies, setStudies] = useState([]);
  const [activeStudies, setActiveStudies] = useState([]);
  const [filteredCursus, setFilteredCursus] = useState([]);

  // Get favorite job
  useEffect(() => {
    if (configJobsIsLoaded) {
      setJob({
        ...(userJobs[userJobFavorite] || {}),
        ...(JobsService.getJob(userJobFavorite)),
      });
    }
  }, [configJobsIsLoaded, userJobs, userJobFavorite, setJob]);

  // Finish loading state (get cursus from job)
  useEffect(() => {
    if (configCursusIsLoaded && job) {
      if (job.cursus) {
        const cursuses = {};
        job.cursus.forEach(cursusId => {
          if (!Object.keys(cursuses).includes(cursusId)) {
            const cursus = CursusService.getCursus(cursusId);
            if (cursus && cursus !== null) {
              cursuses[cursusId] = cursus;
            }
          }
        });
        setCursuses(Object.values(cursuses));
      }
    }
  }, [configCursusIsLoaded, job, setCursuses]);

  // Finish loading state (compute places / studies from cursuses)
  useEffect(() => {
    if (configPlacesIsLoaded && cursuses.length) {
      setIsLoading(false);
      let filteredPlaces = [];
      let filteredStudies = [];
      cursuses.forEach(cursus => {
        filteredPlaces = filteredPlaces.concat(cursus.places.filter((value) => !filteredPlaces.includes(value)));
        filteredStudies = filteredStudies.concat(cursus.studies.filter((value) => !filteredStudies.includes(value)));
      });
      filteredPlaces = filteredPlaces.length ? filteredPlaces.map(placeId => PlacesService.getPlace(placeId)) : PlacesService.getPlaces();
      filteredPlaces = filteredPlaces.filter(placeId => placeId !== null);
      setPlaces(filteredPlaces);
      filteredStudies = filteredStudies.length ? filteredStudies.map(studyId => StudiesService.getStudy(studyId)) : StudiesService.getStudies();
      filteredStudies = filteredStudies.filter(studyId => studyId !== null);
      setStudies(filteredStudies);
    }
  }, [configPlacesIsLoaded, cursuses, setPlaces, setStudies]);

  // Filters
  useEffect(() => {
    if (cursuses && cursuses.length) {
      setFilteredCursus(prevCursuses => {
        if (activePlaces.length || activeStudies.length) {
          console.log('filters with ', activePlaces, activeStudies);
          return cursuses.filter(cursus => (
            (!activePlaces.length || (activePlaces.length && cursus.places.reduce((included, placeId) => included || activePlaces.includes(placeId), false)))
            && (!activeStudies.length || (activeStudies.length && cursus.studies.reduce((included, studyId) => included || activeStudies.includes(studyId), false)))
          ));
        }
        return !prevCursuses || !prevCursuses.length || prevCursuses.length !== cursuses.length ? [...cursuses] : prevCursuses;
      });
    }
  }, [cursuses, activePlaces, activeStudies]);

  // Likes
  const toggleLikeCursus = useCallback(cursusId => (isLiked) => {
    // console.log('like', cursusId, isLiked);
    setCursusLikes(prevLikes => {
      if (prevLikes) {
        if (isLiked && !prevLikes.includes(cursusId)) {
          return [...prevLikes, cursusId];
        } else if (!isLiked && prevLikes.includes(cursusId)) {
          return prevLikes.filter(id => id !== cursusId);
        }
      }
      return prevLikes;
    });
  }, [setCursusLikes]);

  // show toast
  useEffect(() => {
    showToast(activedSlides.length === 2);
  }, [activedSlides, showToast]);
  useEffect(() => {
    setActivedSlides(prevActived => prevActived.indexOf(activeSlide) < 0 ? [...prevActived, activeSlide] : prevActived);
  }, [activeSlide, setActivedSlides]);

  // Debug
  useEffect(() => {
    (appDebugMode && filteredCursus) && console.log('filtered cursus', filteredCursus);
  }, [appDebugMode, filteredCursus]);
  useEffect(() => {
    (appDebugMode && places) && console.log('filtered places', places);
  }, [appDebugMode, places]);
  useEffect(() => {
    (appDebugMode && studies) && console.log('filtered studies', studies);
  }, [appDebugMode, studies]);

  // Refs
  const swiperMD1Ref = useRef(null);

  const onSlideChange = useCallback(e => {
    setActiveSlide(prevActive => e.realIndex !== prevActive ? e.realIndex : prevActive);
  }, [setActiveSlide]);

  const onPlacesChange = useCallback(({ target: { value } }) => {
    setActivePlaces(value.split(',').filter(v => v !== ''));
  }, [setActivePlaces]);

  const onStudiesChange = useCallback(({ target: { value } }) => {
    setActiveStudies(value.split(',').filter(v => v !== ''));
  }, [setActiveStudies]);

  const goToNextWithConfirm = useCallback(() => {
    setIsConfirmModalOpen(true);
  }, [setIsConfirmModalOpen]);

  const goToNext = useCallback(() => {
    addToBook('cursusLikes', cursusLikes);
    onEnd();
  }, [onEnd, addToBook, cursusLikes]);

  return (

    <VPage>

      <VCenter>
 
        {/* <VTitle>
          <p className="uppercase">Les solutions <span className="font-bold">de formation</span></p>
        </VTitle> */}

        <VMessenger audio="05_04.mp3" >
          Pour progresser, il n’y a pas de secret, il va falloir te former. Différentes solutions s’offrent à toi.
        </VMessenger>

        <VSpace />

        <VSticker>Les solutions de formation</VSticker>

        {!isLoading && <VCard className="!w-full lg:!w-[880px] xl:!w-[990px] m-0 py-8 bg-black/60 text-white ">
          <VCenter >

            {job && <p className="text-3xl lg:text-4xl font-bold uppercase my-5">{job.name}</p>}

            {(places && places.length) || (studies && studies.length) ? <div className='w-full grid grid-cols-1 sm:grid-cols-2 gap-2 mb-4'>

              {places && places.length ? <Select
                placeholder="Lieu de la formation"
                startContent={<FaMapLocationDot />}
                selectionMode="multiple"
                classNames={{
                  base: "m-0 p-0 text-black ",
                  label: "m-0 p-0 text-red-500",
                  trigger: "m-0 p-0 text-orange-500 rounded-lg h-10 px-4",
                  listboxWrapper: "m-0 p-0 text-black-500",
                }}
                onChange={onPlacesChange}
              >
                {places.map((place) => place && (
                  <SelectItem key={place.id} value={place.id}>
                    {place.label || place.id}
                  </SelectItem>
                ))}
              </Select> : <></>}

              {studies && studies.length ? <Select
                placeholder="Niveau d'études visé"
                startContent={<FaGraduationCap />}
                selectionMode="multiple"
                classNames={{
                  base: "m-0 p-0 text-black",
                  label: "m-0 p-0 text-red-500",
                  trigger: "m-0 p-0 text-orange-500 rounded-lg h-10 px-4",
                  listboxWrapper: "m-0 p-0 text-black-500",
                }}
                onChange={onStudiesChange}
              >
                {studies.map((study) => study && (
                  <SelectItem key={study.id} value={study.id}>
                    {study.label || study.id}
                  </SelectItem>
                ))}
              </Select> : <></>}

            </div> : <></>}

            {filteredCursus && filteredCursus.length ? <>

              {filteredCursus.length > 1 ? <>

                <Swiper
                  ref={swiperMD1Ref}
                  onSlideChange={onSlideChange}
                  modules={filteredCursus.length > 1 ? [Pagination, Navigation, A11y, EffectCarousel] : [Pagination, Navigation, A11y, FreeMode]}
                  navigation={true}
                  allowTouchMove={true}
                  simulateTouch={true}
                  slidesPerView={filteredCursus.length > 1 ? 2 : 1}
                  pagination={{ clickable: true }}
                  effect={filteredCursus.length > 1 ? 'carousel' : null}
                  speed={500}
                  loop={false}
                  grabCursor={true}
                >

                  {filteredCursus.map((cursus, index) => <SwiperSlide key={cursus.id}>
                    <div className="pb-4">
                      <Cursus
                        cursus={cursus}
                        isActive={activeSlide >= 0 && filteredCursus[activeSlide] && filteredCursus[activeSlide].id === cursus.id}
                        onClick={() => swiperMD1Ref.current.swiper.slideTo(index)}
                      />
                    </div>
                  </SwiperSlide>)}

                </Swiper>

              </> : <>

                {filteredCursus.map((cursus, index) => <div className="pb-4" key={cursus.id}>
                  <Cursus
                    cursus={cursus}
                    isActive={activeSlide >= 0 && filteredCursus[activeSlide] && filteredCursus[activeSlide].id === cursus.id}
                    onClick={() => swiperMD1Ref.current.swiper.slideTo(index)}
                  />
                </div>)}

              </>}

              {activeSlide >= 0 && filteredCursus[activeSlide] ? <>

                <div className="grid grid-cols-1 md:grid-cols-3 mt-4">
                  <div className="px-4 py-2 flex flex-col gap-4 justify-center text-center pb-4 mb-3 md:pb-0 md:mb-0 border-b-2 md:border-b-0 md:border-r-2 border-white">
                    {filteredCursus[activeSlide].places && <PlacesMap places={filteredCursus[activeSlide].places} />}
                    {filteredCursus[activeSlide].places && <PlacesList places={filteredCursus[activeSlide].places} />}
                  </div>
                  <div className="px-4 py-2 flex flex-col col-span-2 gap-4 justify-top text-left">
                    <div className="flex justify-center items-center gap-2">
                      <div><LikeSwitch isLiked={cursusLikes && cursusLikes.includes(filteredCursus[activeSlide].id)} onChange={toggleLikeCursus(filteredCursus[activeSlide].id)} /></div>
                      {filteredCursus[activeSlide].name && <p className="text-2xl leading-6 font-medium m-0">{filteredCursus[activeSlide].name}</p>}
                    </div>
                    {filteredCursus[activeSlide].desc && <>
                      <p className="text-2xl leading-6 font-medium m-0">
                        {filteredCursus[activeSlide].desc}
                        {filteredCursus[activeSlide].tempo && <><br />{filteredCursus[activeSlide].tempo}</>}
                      </p>
                    </>}

                    {filteredCursus[activeSlide].plus && <p className="text-lg leading-2 font-light m-0">{filteredCursus[activeSlide].plus}</p>}
                    {filteredCursus[activeSlide].title && <p className="text-lg leading-2 font-light m-0">{filteredCursus[activeSlide].title}</p>}
                    {filteredCursus[activeSlide].link && <div><VButton onPress={() => window.open(filteredCursus[activeSlide].link, '_blank')}>En savoir +</VButton></div>}
                  </div>
                </div>

              </> : <></>}

            </> : <></>}

          </VCenter>
        </VCard>}

        <div className="mt-6">
          <VButton onPress={goToNextWithConfirm} isLoading={isNextLoading}>continuer</VButton>
        </div>

      </VCenter>

      <VModalConfirm isOpen={isConfirmModalOpen} size='sm'>
        <ModalContent>
          <ModalHeader className="flex flex-col gap-1 text-2xl leading-7 font-semibold">As-tu terminé ton exploration ?</ModalHeader>
          <ModalBody>
            <div className="flex items-center content-center">
              <VImage
                src="tutor/01.png"
                width={80}
                radius="full"
                className="bg-white"
              />
              <div className="ml-4">
                <p>Si tu n’as pas terminé, tu peux continuer à explorer les différentes formations.</p>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="light" variant="light" className='text-md font-medium uppercase' onPress={() => setIsConfirmModalOpen(false)}>Non</Button>
            <Button color="primary" className='text-md font-medium uppercase' onPress={goToNext}>Oui</Button>
          </ModalFooter>
        </ModalContent>
      </VModalConfirm>

    </VPage>
  );
}

export default CoachingMap;