// Providers
import { useGame } from '../providers/GameProvider';
import { useTheme } from '../providers/ThemeProvider';

// Components
import Background from '../components/Background';
import { VLoading } from '../components/Vues';

// --Toast
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AppLayout = ({ children }) => {

	const { confIsLoaded } = useGame();
	const { theme, background } = useTheme();

	return (
		<div data-theme={theme}>
			<div className="text-foreground bg-background text-base min-h-screen overflow-x-hidden md:overflow-x-auto">
				<Background id={background}>
					{!confIsLoaded ? <VLoading label="CHARGEMENT" /> : <>{children}</>}
				</Background>
				<ToastContainer
					className="select-none"
					pauseOnHover
					pauseOnFocusLoss
					closeOnClick
					draggable
					stacked
					theme="dark"
					progressStyle={{
						height: '2px',
						backgroundColor: 'ifpass-purple',
					}}
				/>
			</div>
		</div>
	)
};
export default AppLayout;