import { useCallback } from "react";

// Providers
import { useTheme } from "../../providers/ThemeProvider";

// Services
import AssetsService from "../../services/assets.service";

// Components
import { VCenter, VButton, VSpace, VMessenger, VSticker, VCard } from "../Vues";

// Icons
import { FaRegCirclePlay, FaArrowUpRightFromSquare, FaArrowRotateLeft } from "react-icons/fa6";

const DebriefingExplorer = ({ onEnd, isNextLoading }) => {

  // Contexts
  const { toggleVideosModal, toggleMenuEv } = useTheme();

  const goToNext = useCallback(() => {
    onEnd();
  }, [onEnd]);

  return (

    <VCenter>

      <VMessenger audio="06_03.mp3"  >
        D’accord, c’est tout à fait normal d’hésiter.  Voici quelques éléments qui peuvent t’aider à explorer d’autres pistes
      </VMessenger>

      <VCenter>

        <VSticker>Et les autres pistes ?</VSticker>

        <VCard>

          <div className='p-4'>
            {/* <p className="font-bold text-2xl mb-2">Pour découvrir d'autres métiers</p> */}
            <VButton className="w-full py-8" onPress={() => toggleVideosModal()} startContent={<FaRegCirclePlay className="text-2xl" />}>Consulter les vidéos pour<br />découvrir d'autres métiers</VButton>

            <VSpace />

            {/* <p className="font-bold text-2xl mb-2">Découvrir d’autres formations en consultant le catalogue des formations de l'IFPASS</p> */}
            <VButton className="w-full py-8" onPress={() => window.open(AssetsService.getUrl('docs/IFPASS_Formations_2024.pdf'), '_blank')} startContent={<FaArrowUpRightFromSquare className="text-lg" />}>Consulter le catalogue<br />des formations de l'IFPASS</VButton>

            <VSpace />

            {/* <p className="font-bold text-2xl mb-2">Recommencer le casting en cliquant sur DÉBRIEFING en haut à gauche de l'écran</p> */}
            <VButton className="w-full py-8" onPress={() => toggleMenuEv()} startContent={<FaArrowRotateLeft className="text-xl" />}>Recommencer le casting</VButton>

          </div>

        </VCard>

        <VSpace size={6} />

        <p className="font-bold text-2xl mb-2">Prêt à terminer le jeu ?</p>
        <VButton className="text-black bg-none !bg-white" onPress={goToNext} isLoading={isNextLoading}>Terminer</VButton>

      </VCenter>

    </VCenter >
  );
}

export default DebriefingExplorer;