import { useEffect } from "react";
import { Outlet } from "react-router-dom";

// Providers
import { useTheme } from '../providers/ThemeProvider'
import { useAuth } from "../providers/AuthProvider";

// Components
import { Button } from "@nextui-org/react";
import { VCenter, VImage } from "../components/Vues";

// Icons
import { FaUniversalAccess } from "react-icons/fa6";

const MainLayout = () => {

	// Contexts
	const { doReloadOnce } = useAuth();
	const { toggleTheme } = useTheme();

	useEffect(() => {
	  doReloadOnce();
	}, [doReloadOnce]);

	return (
		<div className="flex flex-col h[100vh] min-h-screen">
			<header className="float-right right-0 absolute p-4">
				<Button isIconOnly={false} className="items-center" color="primary" variant="solid" onPress={() => { toggleTheme() }} ><FaUniversalAccess className="text-white" />Accessibilité</Button>
			</header>
			<div className="mx-auto max-w-[1024px] p-6 pt-12 flex-grow">
				<main>
					<Outlet />
				</main>
			</div>
			<footer className="text-center w-full p-4">
				<VCenter>
					<VImage width={200} src="logo-cfa-de-demain-atlas.jpg" alt="Logo Atlas"></VImage>
				</VCenter>
			</footer>
		</div>
	)
};

export default MainLayout;