import { useRadio, cn, VisuallyHidden } from "@nextui-org/react";

const RadioQuizImage = ({ isGood, isBad, hasSolution, ...props }) => {
    const {
        Component,
        children,
        description,
        getBaseProps,
        getInputProps,
        getLabelProps,
        getLabelWrapperProps,
    } = useRadio(props);

    return (
        <Component
            {...getBaseProps()}
            className={cn(
                'overflow-hidden tap-highlight-danger',
                'rounded-lg border-4 border-white-10',
                hasSolution ? 'hover:opacity-100 active:opacity-100' : 'cursor-pointer hover:opacity-70 active:opacity-50',
                hasSolution ? `data-[selected=true]:border-4 ${(isGood ? 'data-[selected=true]:border-green-500' : 'data-[selected=true]:border-red-500')}` : 'data-[selected=true]:border-ifpass-purple',
                'max-w-fit bg-white',
            )}
        >
            <VisuallyHidden>
                <input {...getInputProps()} />
            </VisuallyHidden>


            <div {...getLabelWrapperProps()}>
                {children &&
                    <h1 {...getLabelProps()}  >

                        {children}

                        {description && (
                            <div className="text-ifpass-purple uppercase font-medium p-2 text-sm">{description}</div>
                        )}
                    </h1>}

            </div>

        </Component>
    );
};
export default RadioQuizImage;