export const CGU = (props) => {
    return (
        <>

            <h1 className="font-bold">MENTIONS LÉGALES</h1>
            <h2 className="font-bold">Informations légales :</h2>

            <p> Le présent site web est édité par l'IFPASS, l'Institut de Formation de la Profession de l'Assurance.</p>
            <p>
                IFPASS<br />
                Immeuble Le Stratège<br />
                172-174 Rue de la République – CS40049 - 92817 Puteaux Cedex<br />
                Téléphone : 01 47 76 58 00
            </p>
            <h2 className="font-bold">Directeur de la publication</h2>
            <p>			Laurent Arachtingi – Directeur Général.<br />
                Contact : webmaster@ifpass.fr
            </p>

            <h2 className="font-bold">Propriété intellectuelle</h2>
            <p>Tous les contenus présents sur le site sont couverts par le droit d'auteur. Toute reprise est dès lors conditionnée à l'accord de l'auteur en vertu de l'article L.122-4 du Code de la Propriété Intellectuelle.</p>

            <h2 className="font-bold">Limitation de responsabilité</h2>
            <p>Ce site ne saurait être tenu pour responsable des erreurs rencontrées sur le site, problèmes techniques, interprétation des informations publiées et conséquences de leur utilisation.<br />
                L’utilisateur reconnaît donc :
                <br />
                • avoir pris connaissance des présentes conditions générales d’utilisation et s’engage à les respecter.<br />
                • utiliser ces informations sous sa responsabilité exclusive.<br />
                • reconnaît disposer de la compétence et des moyens nécessaires pour accéder et utiliser ce site.<br />

            </p>


            <h2 className="font-bold">Développements et hébergement du site</h2>
            <p>Le développement et la mise à jour du site sont assurés par NOVAE.
                Le site est hébergé sur des serveurs de l'entreprise AMAZON AWS - PARIS.
            </p>

            <h1 className="font-bold">POLITIQUE DE CONFIDENTIALITÉ</h1>

            <p>Le groupe IFPASS accorde une grande importance à la protection de votre vie privée. C’est la raison pour laquelle nous avons adopté des principes communs au sein de notre Politique de Confidentialité.<br />
                Le groupe IFPASS , dont le siège social est à PUTEAUX(92800), 172-174 rue de la République, est responsable du traitement de vos données personnelles. Le présent document a pour objet de vous informer de la manière dont nous utilisons et protégeons vos données personnelles, ainsi que des raisons pour lesquelles nous traitons ces données.<br />
                Ce documents s’applique uniformément à toutes les entités du groupe et à l’ensemble de nos traitements, Conformément au Règlement Général sur la Protection des Données (General Data Protection Régulation) adopté par le Parlement européen le 14 avril 2016, et à la Loi Informatique et Libertés du 6 janvier 1978 modifiée.<br />
                Si nécessaire des informations complémentaires pourront vous être communiquées dans le cas d’un traitement spécifique.
                <br /><br />

                Le groupe IFPASS regroupe les entités suivantes :<br />
                • Ifpass<br />
                • Ifpass Services<br />
                • CSCARH<br />
                • Agifpass<br />
                • Ifpass//Jedha<br />


                <br />
                Ces entités communiquent au travers des sites suivants :<br />
                ifpass.fr<br />
                agifpass.fr<br />
                boutique.agifpass.fr<br />
                eliza-formation.fr<br />
                formateurs.ifpass.fr<br />
                planetecscarh.com<br />
                plateforme.planetecscarh<br />
                emploi-assurance.com<br />
                hesca.fr<br />
                cfa-assurance.ifpass.fr<br />
                handifpass.fr<br />
                netypareo.ifpass.fr<br />
            </p>

            <h2 className="font-bold">1/ QUELLES DONNÉES PERSONNELLES TRAITONS-NOUS ?</h2>

            <p>La collecte des données personnelles se limite au strict nécessaire (principe de minimisation des données).<br />
                Nous collectons et utilisons uniquement les données personnelles qui nous sont nécessaires dans le cadre de notre activité pour vous proposer des produits et services personnalisés et de qualité.<br />
                Nous pouvons être amenés à collecter différentes catégories de données personnelles auprès de vous, notamment :<br />
                •	informations d’identification et de contact (nom, prénom, adresse postale et électronique, numéro de téléphone, sexe et signature) ; notamment pour la réalisation des inscriptions à nos formations.<br />
                •	données d’identification et d’authentification notamment lors de l’utilisation de nos services (logs techniques, traces informatiques, informations sur la sécurité, adresse IP).<br />
                •	informations relatives à votre emploi (entreprise, fonction).<br />
                •	données relatives aux interactions avec le groupe IFPASS sur nos sites Internet, sur nos applications, sur nos pages, sur les réseaux sociaux, lors des entretiens et conversations téléphoniques ou en visioconférence ainsi que dans les courriers électroniques).<br />
                <br />
                Nous ne sommes en aucun cas amenés à collecter des données sensibles au sens du RGPD.<br />

                <br />
                De façon générale chacun de nos formulaires de collecte de données, qu’il soit papier ou électronique indique notamment :<br />
                •	Les objectifs du recueil de ces données (finalités).<br />
                •	Si ces données sont obligatoires ou facultatives pour la gestion de votre demande.<br />
                •	Qui pourra en prendre connaissance (uniquement le groupe IFPASS en principe, sauf précision dans le formulaire lorsqu’une transmission à un tiers est nécessaire à la gestion de votre demande).<br />
                •	<a target="_blank" rel="noreferrer" href="https://www.cnil.fr/fr/comprendre-vos-droits">Vos droits Informatique et Libertés</a> et la façon de les exercer auprès du groupe IFPASS
            </p>

            <h2 className="font-bold">2/ POURQUOI ET SELON QUELLES BASES DE TRAITEMENT UTILISONS-NOUS VOS DONNÉES PERSONNELLES ?</h2>

            <h3>2.1/ POUR NOUS CONFORMER À NOS OBLIGATIONS LÉGALES ET RÉGLEMENTAIRES</h3>
            <p>
                Nous utilisons vos données personnelles pour nous acquitter de différentes obligations légales et réglementaires. La base légale de traitement est fondée sur l’intérêt légitime.
            </p>
            <h3>2.2/ POUR EXÉCUTER UN CONTRAT CONCLU AVEC VOUS OU VOUS FOURNIR DES INFORMATIONS PRÉ CONTRACTUELLES</h3>
            <p>Nous utilisons vos données personnelles pour conclure et exécuter nos contrats et dans le cadre de la gestion de la relation client y compris pour :<br />
                •	vous fournir des informations relatives à nos produits et services.<br />
                •	vous assister en parallèle de vos demandes.<br />
                •	gestion et exécution des produits et services.
            </p>

            <h2 className="font-bold">3/ À QUELLES CATÉGORIES D’ENTITÉS VOS DONNÉES PERSONNELLES POURRONT-ELLES ÊTRE DIVULGUÉES ?</h2>
            <p>Les données personnelles recueillies dans le cadre des services proposés par le groupe IFPASS sont traitées selon des protocoles sécurisés et permettent de gérer les données reçues dans ses applications informatiques. Afin d’accomplir les finalités précitées, nous sommes amenés à divulguer vos données personnelles uniquement aux :<br />
                •	personnels du groupe IFPASS pour exécuter les traitements mentionnés ci-dessus,<br />
                •	prestataires de services et sous-traitants, mandataires indépendants, intermédiaires et partenaires commerciaux réalisant des prestations pour notre compte,<br />
                •	autorités financières, judiciaires ou agences d’État, organismes publics sur demande et dans la limite de ce qui est permis par la réglementation ;<br />
                •	certaines professions réglementées telles qu’avocats, notaires, commissaires aux comptes.<br />
                <br />
                Les données collectées ne sont pas transmises hors UE.
            </p>

            <h2 className="font-bold">4/ PENDANT COMBIEN DE TEMPS CONSERVONS-NOUS VOS DONNÉES PERSONNELLES ?</h2>
            <p>
                Nous conservons vos données personnelles pour la plus longue des durées nécessaires au respect des dispositions légales et réglementaires applicables ou une autre durée compte tenu des contraintes opérationnelles. A ce jour, la majorité des informations sont conservées pendant la durée de la relation contractuelle et pendant 7 ans après la fin de la relation contractuelle.<br />
                S’agissant des prospects, les informations sont conservées 3 ans à compter de leur collecte ou de notre dernier contact avec ces derniers.
            </p>

            <h2 className="font-bold">5/ QUELS SONT VOS DROITS ET DE QUELLE MANIÈRE POUVEZ-VOUS LES EXERCER ?</h2>
            <p>Conformément à la réglementation applicable, vous disposez de différents droits, à savoir :<br />
                •	Droit d’accès : vous pouvez obtenir des informations concernant le traitement de vos données personnelles ainsi qu’une copie de ces données personnelles.<br />
                •	Droit de rectification : si vous estimez que vos données personnelles sont inexactes ou incomplètes, vous pouvez exiger que ces données personnelles soient modifiées en conséquence.<br />
                •	Droit à l’effacement : vous pouvez exiger l’effacement de vos données personnelles dans la limite de ce qui est permis par la réglementation.<br />
                •	Droit à la limitation du traitement : vous pouvez demander la limitation de traitement de vos données personnelles.<br />
                •	Droit d’opposition : vous pouvez vous opposer au traitement de vos données personnelles, pour des motifs liés à votre situation particulière. Vous disposez du droit absolu de vous opposer au traitement de vos données personnelles à des fins de prospection commerciale, y compris le profilage lié à cette prospection.<br />
                •	Droit à la portabilité de vos données, quand ce droit est applicable, vous avez le droit à ce que les données personnelles que vous nous avez fournies vous soient rendues ou, lorsque cela est possible, techniquement, de les transférer à un tiers.<br />
                •	Droit de définir des directives relatives à la conservation, l’effacement ou la communication de vos données personnelles, applicables après votre décès.<br />
                •	Droit de retirer votre consentement : si vous avez donné votre consentement au traitement de vos données personnelles, vous avez le droit de retirer votre consentement à tout moment.
            </p>

            <h2 className="font-bold">6/ GESTION DES COOKIES</h2>
            <p>Lors de votre première connexion sur notre site, vous êtes avertis par un bandeau en bas de votre écran que des informations relatives à votre navigation sont susceptibles d’être enregistrées dans des fichiers dénommés « cookies ». Notre politique d’utilisation des cookies vous permet de mieux comprendre les dispositions que nous mettons en œuvre en matière de navigation sur notre site web. Elle vous informe notamment sur l’ensemble des cookies présents sur notre site web, leur finalité (partie I.) et vous donne la marche à suivre pour les paramétrer (partie II.)</p>

            <h3>•    1. Informations générales sur les cookies présents sur le site</h3>
            <p>L’IFPASS en tant qu’éditeur du présent site web, pourra procéder à l’implantation d’un cookie sur le disque dur de votre terminal (ordinateur, tablette, mobile etc.) afin de vous garantir une navigation fluide et optimale sur notre site Internet.<br />
                Les « cookies » (ou témoins de connexion) sont des petits fichiers texte de taille limitée qui nous permettent de reconnaître votre ordinateur, votre tablette ou votre mobile aux fins de personnaliser les services que nous vous proposons.<br />
                Les informations recueillies par le biais des cookies ne permettent en aucune manière de vous identifier nominativement. Elles sont utilisées exclusivement pour nos besoins propres afin d’améliorer l’interactivité et la performance de notre site web et de vous adresser des contenus adaptés à vos centres d’intérêts. Aucune de ces informations ne fait l’objet d’une communication auprès de tiers sauf lorsque l’IFPASS a obtenu au préalable votre consentement ou bien lorsque la divulgation de ces informations est requise par la loi, sur ordre d’un tribunal ou toute autorité administrative ou judiciaire habilitée à en connaître.<br />
                Pour mieux vous éclairer sur les informations que les cookies identifient, vous trouverez ci-dessous un tableau listant les différents types de cookies susceptibles d’être utilisés sur le site web de l’IFPASS, leur nom, leur finalité ainsi que leur durée de conservation.
            </p>
            <h3>•    2. Configuration de vos préférences sur les cookies</h3>

            <p>Vous pouvez accepter ou refuser le dépôt de cookies à tout moment.<br />
                Lors de votre première connexion sur le site web de l’IFPASS, une bannière présentant brièvement des informations relatives au dépôt de cookies et de technologies similaires apparaît en bas de votre écran. Cette bannière vous avertit qu’en poursuivant votre navigation sur le site web de l’IFPASS (en chargeant une nouvelle page ou en cliquant sur divers éléments du site par exemple), vous acceptez le dépôt de cookies sur votre terminal. Vous êtes également réputé avoir donné votre accord au dépôt de cookies en cliquant sur le bouton « J’accepte » à droite de la bannière figurant en bas de votre écran.<br />
                Selon le type de cookie en cause, le recueil de votre consentement au dépôt et à la lecture de cookies sur votre terminal peut être impératif.</p>

            <h4>a. Les cookies exemptés de consentement</h4>
            <p>Conformément aux recommandations de la Commission Nationale de l’Informatique et des Libertés (CNIL), certains cookies sont dispensés du recueil préalable de votre consentement dans la mesure où ils sont strictement nécessaires au fonctionnement du site internet ou ont pour finalité exclusive de permettre ou faciliter la communication par voie électronique. Il s’agit notamment des cookies d’identifiant de session, d’authentification, de session d’équilibrage de charge ainsi que des cookies de personnalisation de votre interface. Ces cookies sont intégralement soumis à la présente politique dans la mesure où ils sont émis et gérés par l’IFPASS.</p>

            <h4>b. Les cookies nécessitant le recueil préalable de votre consentement</h4>
            <p>
                Cette exigence concerne les cookies émis par des tiers et qui sont qualifiés de « persistants » dans la mesure où ils demeurent dans votre terminal jusqu’à leur effacement ou leur date d’expiration.<br />
                De tels cookies étant émis par des tiers, leur utilisation et leur dépôt sont soumis à leurs propres politiques de confidentialité dont vous trouverez un lien ci-dessous. Cette famille de cookie regroupe les cookies de mesure d’audience (notamment Google Analytics), les cookies publicitaires (auxquels l’IFPASS n’a pas recours) ainsi que les cookies de partage de réseaux sociaux (notamment de Facebook, YouTube, Twitter et LinkedIn).<br />
                Les cookies de mesure d’audience établissent des statistiques concernant la fréquentation et l’utilisation de divers éléments du site web (comme les contenus/pages que vous avez visité). Ces données participent à l’amélioration de l’ergonomie du site web de l’IFPASS. Un outil de mesure d’audience est utilisé sur le présent site internet :
            </p>
            <p>
                •	GOOGLE analytics  dont la politique de confidentialité est disponible  à partir du lien suivant :  <a target="_blank" rel="noreferrer" href="https://policies.google.com/privacy">https://policies.google.com/privacy</a> /<br />
                •	Les cookies de partage des réseaux sociaux sont émis et gérés par l’éditeur du réseau social concerné. Sous réserve de votre consentement, ces cookies vous permettent de partager facilement une partie du contenu publié sur le site de l’IFPASS, notamment par l’intermédiaire d’un « bouton » applicatif de partage selon le réseau social concerné. Quatre types de cookies de partage des réseaux sociaux sont présents sur le site l’IFPASS :<br />
                •	Facebook, dont vous pouvez consulter la politique en matière de cookies en cliquant sur le lien suivant : <a target="_blank" rel="noreferrer" href="https://fr-fr.facebook.com/policies/cookies/">https://fr-fr.facebook.com/policies/cookies/</a><br />
                •	LinkedIn, dont vous trouverez la politique relative aux cookies en cliquant sur le lien suivant : <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/legal/cookie-policy?_l=fr_FR">https://www.linkedin.com/legal/cookie-policy?_l=fr_FR</a><br />
                •	Twitter, dont vous trouverez les options dédiées au contrôle ou à la restriction de l’utilisation des cookies ainsi que la politique d’utilisation des cookies : <a target="_blank" rel="noreferrer" href="https://support.twitter.com/articles/20170518#">https://support.twitter.com/articles/20170518#</a><br />
                •	YouTube, dont vous trouverez l’aide nécessaire pour supprimer les cookies à partir du navigateur Google Chrome en cliquant sur le lien suivant : <a target="_blank" rel="noreferrer" href="https://support.google.com/youtube/answer/32050?hl=fr">https://support.google.com/youtube/answer/32050?hl=fr</a> mais également la politique complète en matière de cookies à partir du lien suivant : <a target="_blank" rel="noreferrer" href="https://www.google.fr/intl/fr/policies/technologies/cookies/">https://www.google.fr/intl/fr/policies/technologies/cookies/</a>
            </p>

            <h4>c. Vous disposez de divers outils de paramétrage des cookies</h4>
            <p>
                La plupart des navigateurs Internet sont configurés par défaut de façon à ce que le dépôt de cookies soit autorisé. Votre navigateur vous offre l’opportunité de modifier ces paramètres standards de manière à ce que l’ensemble des cookies soit rejeté systématiquement ou bien à ce qu’une partie seulement des cookies soit acceptée ou refusée en fonction de leur émetteur.<br />
                ATTENTION : Nous attirons votre attention sur le fait que le refus du dépôt de cookies sur votre terminal est néanmoins susceptible d’altérer votre expérience d’utilisateur ainsi que votre accès à certains services ou fonctionnalités du présent site web. Le cas échéant, l’IFPASS décline toute responsabilité concernant les conséquences liées à la dégradation de vos conditions de navigation qui interviennent en raison de votre choix de refuser, supprimer ou bloquer les cookies nécessaires au fonctionnement du site. Ces conséquences ne sauraient constituer un dommage et vous ne pourrez prétendre à aucune indemnité de ce fait.<br />
                Votre navigateur vous permet également de supprimer les cookies existants sur votre terminal ou encore de vous signaler lorsque de nouveaux cookies sont susceptibles d’être déposés sur votre terminal. Ces paramétrages n’ont pas d’incidence sur votre navigation mais vous font perdre tout le bénéfice apporté par le cookie.<br />
                Veuillez ci-dessous prendre connaissance des multiples outils mis à votre disposition afin que vous puissiez paramétrer les cookies déposés sur votre terminal.<br />
            </p>
            <h4>d. Le paramétrage de votre navigateur Internet</h4>
            <p> Chaque navigateur Internet propose ses propres paramètres de gestion des cookies. Pour savoir de quelle manière modifier vos préférences en matière de cookies, vous trouverez ci-dessous les liens vers l’aide nécessaire pour accéder au menu de votre navigateur prévu à cet effet :<br />
                Chrome :  <a target="_blank" rel="noreferrer" href="https://support.google.com/chrome/answer/95647?hl=fr">https://support.google.com/chrome/answer/95647?hl=fr</a><br />
                Firefox : <a target="_blank" rel="noreferrer" href="https://support.mozilla.org/fr/kb/activer-desactiver-cookies">https://support.mozilla.org/fr/kb/activer-desactiver-cookies</a><br />
                Internet Explorer : <a target="_blank" rel="noreferrer" href="https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies#ie=ie-11">https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies#ie=ie-11</a><br />
                Opera : <a target="_blank" rel="noreferrer" href="http://help.opera.com/Windows/10.20/fr/cookies.html">http://help.opera.com/Windows/10.20/fr/cookies.html</a><br />
                Safari : <a target="_blank" rel="noreferrer" href="https://support.apple.com/kb/PH21411?viewlocale=fr_FR&locale=fr_FR">https://support.apple.com/kb/PH21411?viewlocale=fr_FR&locale=fr_FR</a><br />
                Pour de plus amples informations concernant les outils de maîtrise des cookies, vous pouvez consulter le site internet de la CNIL : <a target="_blank" rel="noreferrer" href="https://www.cnil.fr/fr/site-web-cookies-et-autres-traceurs">https://www.cnil.fr/fr/site-web-cookies-et-autres-traceurs</a><br />
                Pour toute interrogation ou demande complémentaire d’informations relative à la présente politique des cookies, merci de bien vouloir nous contacter par e-mail à l’adresse rgpd@ifpass.fr.
            </p>


            <h2 className="font-bold">7/ COMMENT NOUS CONTACTER ?</h2>
            <p>
                Si vous avez des questions concernant l’utilisation de vos données personnelles visée par le présent document, vous pouvez nous contacter à l’adresse :<br />

                IFPASS<br />
                172-174 rue de la République<br />
                92800 PUTEAUX<br />
                <br />
                Ou par email à <a href="mailto:rgpd@ifpass.fr">rgpd@ifpass.fr</a><br />


                ***
            </p>


        </>
    )
};
