import { useCallback } from 'react';

// Providers
import { useGame } from '../../providers/GameProvider';

// Services
import JobsService from '../../services/jobs.service';

// Components
import SessionWrapper from './SessionWrapper';
import { gameProgression as toProgression, gamePage as toPage } from './Debriefing';
import CoachingHome from '../../components/game/CoachingHome';
import CoachingCompetences from '../../components/game/CoachingCompetences';
import CoachingMap from '../../components/game/CoachingMap';
import CoachingEnd from '../../components/game/CoachingEnd';

export const gameProgression = 85;
export const gameProgressionTo = toProgression;
export const gameChapter = '04';
export const gameBackground = '07';
export const gamePage = 'coaching';
export const gameProgressBar = true;
export const gameFrame = true;

export const gameSteps = [
  {
    id: 'home',
    component: (props) => (<CoachingHome {...props} />),
    saveOnEnd: false,
  },
  {
    id: 'competences',
    component: (props) => (<CoachingCompetences {...props} />),
    saveOnEnd: true,
  },
  {
    id: 'map',
    component: (props) => (<CoachingMap {...props} />),
    saveOnEnd: true,
  },
  {
    id: 'end',
    component: (props) => (<CoachingEnd {...props} />),
    saveOnEnd: true,
  },
];

const Coaching = () => {

  // Contexts
  const { userInfos, avatarId, teamId, awards, userAxes, userJobFavorite, cursusLikes, resetBookWithData, resetCoaching, coachingCompetencesRating, userJobs, addVideos } = useGame();

  // Reset all infos
  const resetOnStart = useCallback(() => {
    resetCoaching();
    // ensure book is completed
    if (userInfos) {
      resetBookWithData({
        infos: userInfos,
        avatar: avatarId,
        team: teamId,
        awards,
        axes: userAxes,
        withCursus: false,
        job: userJobFavorite ? JobsService.getJob(userJobFavorite) : null,
      });
    }
    // ensure all jobs videos
    if (userJobs) {
      const jobsVideosIds = ['Brief01', 'Brief02'];
      JobsService.populate(userJobs).forEach((job) => {
        if (job.media) {
          jobsVideosIds.push(job.media);
        }
      });
      if (jobsVideosIds.length) {
        addVideos(jobsVideosIds);
      }
    }
  }, [userInfos, avatarId, teamId, awards, userAxes, userJobFavorite, resetBookWithData, resetCoaching, userJobs, addVideos]);

  return <SessionWrapper
    progression={gameProgression}
    progressionTo={gameProgressionTo}
    page={gamePage}
    steps={gameSteps}
    chapter={gameChapter}
    background={gameBackground}
    progressBar={gameProgressBar}
    frame={gameFrame}
    resetOnStart={resetOnStart}
    valuesToSave={{
      userJobFavorite: userJobFavorite,
      userCompetencesRating: coachingCompetencesRating,
      userCursusLikes: cursusLikes,
    }}
    nextPage={toPage}
  />;
}

export default Coaching;
