const categories = {
  all: {
    id: 'all',
    label: 'Tout',
    description: 'Navigue parmi toutes les vidéos',
    weight: 10,
  },
  common: {
    id: 'common',
    label: 'Le monde de l\'assurance',
    description: 'Découvre en vidéos le monde de l\'assurance',
    weight: 11,
  },
  'job-commerce': {
    id: 'job-commerce',
    label: 'Métiers du commerce',
    description: 'Découvre en vidéos les métiers du commerce',
    weight: 12,
  },
  'job-gestion': {
    id: 'job-gestion',
    label: 'Métiers de la gestion',
    description: 'Découvre en vidéos les métiers de la gestion',
    weight: 13,
  },
  'job-mgmt': {
    id: 'job-mgmt',
    label: 'Métiers du management',
    description: 'Découvre en vidéos les métiers du management',
    weight: 14,
  },
};
export const defaultPoster = 'defaultPoster.png';
const videos = [
  {
    id: 'Brief01',
    src: 'common/PourquoiOnSAssure.mp4',
    poster: 'common/PourquoiOnSAssure.png',
    vtt: 'common/PourquoiOnSAssure.vtt',
    categories: ['common'],
  },
  {
    id: 'Brief02',
    src: 'common/OnPeutToutFaire.mp4',
    poster: 'common/OnPeutToutFaire.png',
    vtt: 'common/OnPeutToutFaire.vtt',
    categories: ['common'],
  },
  // {
  //   id: 'Presentation',
  //   src: 'common/Presentation_IFCAST.mp4',
  //   poster: 'common/Presentation_IFCAST.png',
  //   vtt: 'common/Presentation_IFCAST.vtt',
  //   categories: ['common'],
  // },
  // {
  //   id: 'ReglesDuJeu',
  //   src: 'common/ReglesDuJeu_IFCAST.mp4',
  //   poster: 'common/ReglesDuJeu_IFCAST.png',
  //   vtt: 'common/ReglesDuJeu_IFCAST.vtt',
  //   categories: ['common'],
  // },
  // {
  //   id: 'Coaching01',
  //   src: 'common/01.mp4',
  //   poster: 'common/01.jpg',
  //   vtt: 'common/OnPeutToutFaire.vtt',
  //   categories: ['common'],
  // },
];
const VideosService = {
  defaultCategory: 'all',
  videos: {},
  getCategory: (categoryId) => categories[categoryId] || null,
  getVideos: () => Object.values(VideosService.videos),
  getVideosIds: () => Object.keys(VideosService.videos),
  getVideosKeyed: () => VideosService.videos,
  getVideo: id => VideosService.videos[id] || null,
  getVideoCategories: () => Object.values(categories),
  getVideoCategory: (videoId) => (VideosService.getVideo(videoId) ? VideosService.getVideo(videoId).categories : null) || [VideosService.getCategory('all')],
  sortVideos: (videos) => videos.sort((a, b) => b.weight - a.weight),
};
videos.forEach((video, index) => {
  VideosService.videos[video.id] = {
    id: video.id,
    poster: video.poster || null/* || video.src.replace('.mp4', '_thumb.png') || defaultPoster*/,
    vtt: video.vtt || null/* || video.src.replace('.mp4', '_thumb.png') || defaultPoster*/,
    categories: (video.categories || VideosService.getVideoCategory(video.id)).concat(['all']),
    weight: index,
    ...video,
  };
});

export default VideosService;