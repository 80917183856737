import { useCallback } from "react";

// Components
import { VCenter, VTitle, VSticker, VButton, VPage, VTutor, VMessenger } from "../../components/Vues";

const AuditionsHome = ({ onEnd, isNextLoading }) => {

  const goToNext = useCallback(() => {
    onEnd();
  }, [onEnd]);

  return (

    <VPage>

      <VCenter>
        <VTitle>
          <p>LE <span className="font-bold">POINT</span></p>
          <p>SUR TA PERSONNALITÉ</p>
        </VTitle>

        <VSticker>C'EST PARTI</VSticker>
        <VTutor id="01" />

        <VMessenger audio="03_01.mp3" >
          C’est parti pour les auditions… Commençons par faire le point sur ta personnalité !
        </VMessenger>

        <VButton onPress={goToNext} isLoading={isNextLoading}>Continuer</VButton>

      </VCenter>

    </VPage>
  );
}

export default AuditionsHome;