import { useCheckbox, VisuallyHidden, tv } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { FaRegThumbsUp } from "react-icons/fa6";

const checkbox = tv({
    slots: {
        base: "w-12 h-12 flex items-center justify-center rounded-full",
        content: "text-2xl"
    },
    variants: {
        isLiked: {
            true: {
                base: "!bg-primary hover:!bg-primary/80",
                content: "!text-white",
            },
            false: {
                base: "bg-white/20",
                content: "",
            },
        },
        isInverse: {
            true: {
                base: "hover:bg-black/10",
                content: "text-black",
            },
            false: {
                base: "hover:bg-white/40",
                content: "text-white",
            },
        },
        isFocusVisible: {
            true: {
                base: "outline-none ring-2 ring-focus ring-offset-2 ring-offset-background",
                content: "",
            },
        }
    },
});

export default function LikeSwitch({ onChange, isLiked = false, isInverse = false }) {
    const {
        children,
        isFocusVisible,
        getBaseProps,
        getInputProps,
    } = useCheckbox({
        defaultSelected: isLiked,
        onValueChange: onChange,
    });

    const [baseClassNames, setBaseClassNames] = useState('');
    const [contentClassNames, setContentClassNames] = useState('');

    useEffect(() => {
        const styles = checkbox({
            isLiked,
            isInverse,
            isFocusVisible,
        });
        setBaseClassNames(styles.base());
        setContentClassNames(styles.content());
    }, [isLiked, isInverse, isFocusVisible, setBaseClassNames, setContentClassNames]);

    return (
        <label {...getBaseProps()}>
            <VisuallyHidden>
                <input {...getInputProps()} />
            </VisuallyHidden>

            <div className={baseClassNames}>
                <div className={contentClassNames}>
                    <FaRegThumbsUp />
                </div>
            </div>

            {children}

        </label>
    );
}