import { useCallback } from "react";

// Components
import { VCenter, VTitle, VButton, VPage, VSpace, VMessenger, VCard, VImage, VSticker } from "../../components/Vues";
import { FaArrowRight, FaClapperboard } from "react-icons/fa6";

const CoachingEnd = ({ onEnd, isNextLoading }) => {

  const goToNext = useCallback(() => {
    onEnd();
  }, [onEnd]);

  return (

    <VPage>

      <VCenter>

        <VTitle>
          <p><span className="font-bold">BRAVO !</span></p>
        </VTitle>

        <VMessenger audio="05_05.mp3" >
          Comme tu peux le voir, les chemins sont nombreux, et … les opportunités le seront aussi ! Tu peux retrouver ton script de carrière dans ton book !
          Il ne nous reste plus qu’à débriefer !
        </VMessenger>

        <VCard className="mb-0">
          <VCenter>
            <VImage src="tutor/11.png" alt="Bravo" className="w-[300px]" />
            <div className="relative top-[-80px]">
              <VSticker>Étape terminée !</VSticker>
            </div>
          </VCenter>
        </VCard>

        <VSpace className="my-8">
          <VButton onPress={goToNext} isLoading={isNextLoading} endContent={<div className="flex gap-2"><FaArrowRight /><FaClapperboard /></div>}>Passer au debriefing</VButton>
        </VSpace>

      </VCenter>

    </VPage>
  );
}

export default CoachingEnd;