import React, { useState, createContext, useCallback, useContext, useEffect, useRef } from 'react';
import ProgressService from '../services/progress.service';
import { toast } from 'react-toastify';

export const ThemeContext = createContext();

export const useTheme = () => {
  return useContext(ThemeContext);
};

export const useThemeToast = (component) => {
  const onlyOnce = useRef(null);
  const [showingToast, setShowingToast] = useState(false);
  useEffect(() => {
    if (!(onlyOnce && onlyOnce.current) && showingToast) {
      toast(component, {
        position: "bottom-center",
        autoClose: 5000,
      });
      onlyOnce.current = true;
    }
  }, [component, showingToast]);
  const showToast = useCallback((show = true, force = false) => {
    if (force) {
      onlyOnce.current = false;
    }
    setShowingToast(prev => !prev && !!show ? true : prev);
  }, [setShowingToast]);
  return showToast;
};

export const buttonsAnimationDuration = 10000;

export const ThemeProvider = ({ children }) => {

  const callToast = useCallback((component) => {
    toast(component, {
      position: "bottom-center",
      autoClose: 5000,
    });
  }, []);

  // Theme
  const [theme, setTheme] = useState(ProgressService.get('theme', 'normal'));
  const toggleTheme = useCallback(() => {
    setTheme(prevTheme => prevTheme === 'normal' ? 'upsize' : 'normal');
  }, [setTheme]);
  useEffect(() => {
    ProgressService.set('theme', theme || 'normal');
  }, [theme]);

  // Background
  const [background, setBackground] = useState('01');
  const changeBackground = useCallback((id) => {
    setBackground(typeof id === 'function' ? id : (prevId => prevId !== id && id ? id : prevId));
  }, [setBackground]);
  const toggleBg = useCallback(() => {
    setBackground(prevBackground => Number(prevBackground) === 4 ? '01' : '0' + (Number(prevBackground) + 1));
  }, [setBackground]);

  // Progress bar
  const [hasProgressBar, setHasProgressBar] = useState(false);
  const toggleProgressBar = useCallback(() => {
    setHasProgressBar(prevHasProgressBar => !prevHasProgressBar);
  }, [setHasProgressBar]);

  // Frame
  const [hasFrame, setHasFrame] = useState(false);
  const toggleFrame = useCallback(() => {
    setHasFrame(prevHasFrame => !prevHasFrame);
  }, [setHasFrame]);

  // Chapter
  const [currentChapter, setCurrentChapter] = useState('00');

  // Buttons animations
  const [isButtonBookAnimated, setIsButtonBookAnimated] = useState(false);
  const animateButtonBook = useCallback((duration = null) => {
    setIsButtonBookAnimated(true);
    setTimeout(() => {
      setIsButtonBookAnimated(false);
    }, duration || buttonsAnimationDuration);
  }, [setIsButtonBookAnimated]);
  const cancelAnimateButtonBook = useCallback(() => {
    setIsButtonBookAnimated(false);
  }, [setIsButtonBookAnimated]);

  const [isButtonAwardsAnimated, setIsButtonAwardsAnimated] = useState(false);
  const animateButtonAwards = useCallback((duration = null) => {
    setIsButtonAwardsAnimated(true);
    setTimeout(() => {
      setIsButtonAwardsAnimated(false);
    }, duration || buttonsAnimationDuration);
  }, [setIsButtonAwardsAnimated]);
  const cancelAnimateButtonAwards = useCallback(() => {
    setIsButtonAwardsAnimated(false);
  }, [setIsButtonAwardsAnimated]);

  const [isButtonVideosAnimated, setIsButtonVideosAnimated] = useState(false);
  const animateButtonVideos = useCallback((duration = null) => {
    setIsButtonVideosAnimated(true);
    setTimeout(() => {
      setIsButtonVideosAnimated(false);
    }, duration || buttonsAnimationDuration);
  }, [setIsButtonVideosAnimated]);
  const cancelAnimateButtonVideos = useCallback(() => {
    setIsButtonVideosAnimated(false);
  }, [setIsButtonVideosAnimated]);

  // Modals

  // Awards
  const [isAwardModalOpen, setIsAwardModalOpen] = useState(false);
  const toggleAwardModal = useCallback(() => {
    setIsAwardModalOpen(prevModalOpen => !prevModalOpen);
  }, [setIsAwardModalOpen]);
  useEffect(() => {
    isAwardModalOpen && cancelAnimateButtonAwards();
  }, [isAwardModalOpen, cancelAnimateButtonAwards]);

  // Videos
  const [isVideosModalOpen, setIsVideosModalOpen] = useState(false);
  const toggleVideosModal = useCallback(() => {
    setIsVideosModalOpen(prevModalOpen => !prevModalOpen);
  }, [setIsVideosModalOpen]);
  useEffect(() => {
    isVideosModalOpen && cancelAnimateButtonVideos();
  }, [isVideosModalOpen, cancelAnimateButtonVideos]);

  // Book
  const [isBookModalOpen, setIsBookModalOpen] = useState(false);
  const toggleBookModal = useCallback(() => {
    setIsBookModalOpen(prevModalOpen => !prevModalOpen);
  }, [setIsBookModalOpen]);
  useEffect(() => {
    isBookModalOpen && cancelAnimateButtonBook();
  }, [isBookModalOpen, cancelAnimateButtonBook]);

  // Menu
  const [isMenuOpenEv, setIsMenuOpenEv] = useState(false);
  const toggleMenuEv = useCallback(() => {
    setIsMenuOpenEv(isMenuOpenEv => !isMenuOpenEv);
  }, [setIsMenuOpenEv]);

  return (
    <ThemeContext.Provider value={{
      theme,
      toggleTheme,

      callToast,

      background,
      changeBackground,
      toggleBg,

      hasProgressBar,
      setHasProgressBar,
      toggleProgressBar,
      hasFrame,
      setHasFrame,
      toggleFrame,
      currentChapter,
      setCurrentChapter,

      isButtonBookAnimated,
      animateButtonBook,
      cancelAnimateButtonBook,
      isBookModalOpen,
      toggleBookModal,
      
      isMenuOpenEv,
      setIsMenuOpenEv,
      toggleMenuEv,

      isButtonAwardsAnimated,
      animateButtonAwards,
      cancelAnimateButtonAwards,
      isAwardModalOpen,
      toggleAwardModal,

      isButtonVideosAnimated,
      animateButtonVideos,
      cancelAnimateButtonVideos,
      isVideosModalOpen,
      toggleVideosModal,
    }}>
      {children}
    </ThemeContext.Provider>
  )
}