import { useCallback, useEffect } from "react";

// Providers
import { useGame } from "../../providers/GameProvider";

// Components
import { VCenter, VTitle, VButton, VPage, VSpace, VMessenger, VCard, VImage, VSticker } from "../../components/Vues";
import { FaArrowRight, FaClapperboard } from "react-icons/fa6";

const BriefEnd = ({ onEnd, isNextLoading }) => {

  // Contexts
  const { userInfos, avatarId, teamId, mergeWithBook } = useGame();

  useEffect(() => {
    if (userInfos) {
      mergeWithBook({
        infos: userInfos,
        avatar: avatarId,
        team: teamId,
      });
    }
  }, [userInfos, avatarId, teamId, mergeWithBook]);

  const goToNext = useCallback(() => {
    onEnd();
  }, [onEnd]);

  return (

    <VPage>

      <VCenter>

        <VTitle>
          <p><span className="font-bold">BRAVO !</span></p>
        </VTitle>

        <VMessenger audio="02_08.mp3" >
          On progresse ! J’ai commencé à compléter ton book mais il me manque encore quelques infos… On passe aux auditions ?
        </VMessenger>

        <VCard className="mb-0">
          <VCenter>
            <VImage src="tutor/11.png" alt="Bravo" className="w-[300px]" />
            <div className="relative top-[-80px]">
              <VSticker>Étape terminée !</VSticker>
            </div>
          </VCenter>
        </VCard>

        <VSpace className="my-8">
          <VButton onPress={goToNext} isLoading={isNextLoading} endContent={<div className="flex gap-2"><FaArrowRight /><FaClapperboard /></div>}>PASSER AUX AUDITIONS</VButton>
        </VSpace>

      </VCenter>

    </VPage>
  );
}

export default BriefEnd;