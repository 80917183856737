import { useCallback, useEffect, useRef, useState } from "react";

// Providers
import { useGame } from '../providers/GameProvider';

// Services
import AssetsService from "../services/assets.service";
import VideosService, { defaultPoster } from "../services/videos.service";

// Components
import { Button, Image, Select, SelectItem } from "@nextui-org/react";
import { VideoPlayer } from "./VideoPlayer";

// -- Swiper
import { Pagination, A11y, Navigation, FreeMode } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-cards';

export const UserVideos = ({ book, children, displayPermalink, ...props }) => {

  const { appDebugMode, videos } = useGame();

  // States
  const [videosUnlocked, setVideosUnlocked] = useState([]);
  const [videosFiltered, setVideosFiltered] = useState([]);
  const [videosCategories, setVideosCategories] = useState([]);
  const [activeVideoCategory, setActiveVideoCategory] = useState('all');
  const [activeVideo, setActiveVideo] = useState(null);

  // Get videos
  useEffect(() => {
    setVideosUnlocked(Object.values(videos).filter(videoId => VideosService.getVideo(videoId) !== null).map(videoId => ({
      id: videoId,
      ...(VideosService.getVideo(videoId)),
    })));
  }, [videos, setVideosUnlocked]);

  // Get videos categories
  useEffect(() => {
    const categories = {};
    if (videosUnlocked) {
      videosUnlocked.forEach(video => {
        if (video.categories) {
          video.categories.forEach(categoryId => {
            categories[categoryId] = VideosService.getCategory(categoryId);
          });
        }
      });
    }
    setVideosCategories(Object.values(categories));
  }, [videosUnlocked, setVideosCategories]);

  // Default Active Video
  useEffect(() => {
    const ids = videosFiltered ? videosFiltered.map(video => video.id) : [];
    setActiveVideo(prevActive => videosFiltered.length && (!prevActive || (prevActive && !ids.includes(prevActive.id))) ? videosFiltered[0] : prevActive);
  }, [videosFiltered, activeVideo, setActiveVideo]);

  // Filter and sort unlocked videos by category
  useEffect(() => {
    setVideosFiltered(!activeVideoCategory || activeVideoCategory === 'all' ? videosUnlocked : videosUnlocked.filter(video => video.categories.includes(activeVideoCategory)));
  }, [videosUnlocked, activeVideoCategory, setVideosFiltered]);

  const onCategoryChange = useCallback(({ target: { value } }) => {
    setActiveVideoCategory(value || 'all');
  }, [setActiveVideoCategory]);

  const onClickSlide = useCallback(videoId => () => {
    setActiveVideo(VideosService.getVideo(videoId));
  }, [setActiveVideo]);

  // Refs
  const swiperMD1Ref = useRef(null);

  return !videos ? <></> : (<>
    <div className="text-center items-center flex flex-col flex-nowrap h-[100%] w-full justify-center pt-5 px-3">

      <div className="md:grid md:grid-flow-row-dense md:grid-cols-12 w-full mb-6 gap-4">
        <div className="md:col-span-3 flex flex-col gap-0 items-center">
          <p className="font-medium mb-4">Sélectionnez une catégorie :</p>
          <Select
            label="Thèmes des vidéos"
            className="max-w-xs mb-4"
            radius="sm"
            size="lg"
            value={activeVideoCategory}
            onChange={onCategoryChange}
          >
            {videosCategories.sort((a, b) => a.weight - b.weight).map(category => (
              <SelectItem key={category.id} value={category.id}>{category.label}</SelectItem>
            ))}
          </Select>
          {(activeVideoCategory && VideosService.getCategory(activeVideoCategory).description) && <div className="hidden md:block mt-auto text-md">{VideosService.getCategory(activeVideoCategory).description}</div>}
        </div>
        <div className="md:col-span-9">
          {activeVideo && activeVideo.src ? (<>
            <VideoPlayer src={AssetsService.getVideoUrl(activeVideo.src)} poster={AssetsService.getVideoUrl(activeVideo.poster || defaultPoster)} autoPlay={false} className='w-full aspect-video' >
              {activeVideo.vtt && <track default={false} kind="captions" srcLang="Sous-titrage français" src={AssetsService.getVideoUrl(activeVideo.vtt)} />}
            </VideoPlayer>

            {appDebugMode && <p className="text-sm">{activeVideo.id}</p>}
          </>
          ) : <div className="flex h-[100%] items-center justify-center"><p className="font-medium text-xl">Choisissez une vidéo</p></div>}
          {(activeVideoCategory && VideosService.getCategory(activeVideoCategory).description) && <div className="md:hidden mt-3 text-md">{VideosService.getCategory(activeVideoCategory).description}</div>}
        </div>
      </div>

      <Swiper
        ref={swiperMD1Ref}
        modules={[Pagination, Navigation, A11y, FreeMode]}
        navigation={true}
        allowTouchMove={true}
        simulateTouch={true}
        spaceBetween={20}
        pagination={{ clickable: true }}
        slidesPerView={1}
        freeMode={true}
        speed={500}
        followFinger={true}
        loop={true}
        grabCursor={true}
        className="w-full"
        breakpoints={{
          0: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
        }}
      >

        {/* ///////////////////////////////////////////// */}

        {VideosService.sortVideos(videosFiltered).map(video => (
          <SwiperSlide key={video.id} className={`p-0 aspect-video`}>
            <Button className={`w-full text-black p-0 rounded-none aspect-video h-auto${activeVideo && video.id === activeVideo.id ? ' border-2 border-black/50 ' : ''}`} onClick={onClickSlide(video.id)}>
              <p className="hidden text-white font-bold text-lg uppercase leading-4">Accueil général</p>
              <Image src={AssetsService.getVideoUrl(video.poster || defaultPoster)} radius={0} classNames={{ wrapper: "aspect-video", }}></Image>
            </Button>
          </SwiperSlide>
        ))}

      </Swiper>

    </div>
  </>);
}

export default UserVideos;
