import { useCallback, useEffect, useState } from "react";

// Providers
import { useGame } from '../../providers/GameProvider';

// Services
import ApiService from "../../services/api.service";
import JobsService from "../../services/jobs.service";

// Components
import { Button, ModalBody, ModalContent, ModalFooter } from "@nextui-org/react";
import { VCenter, VTitle, VButton, VMessenger, VSpace, VInput, VLoading, VSticker, VTutor, VModalConfirm } from "../Vues";

const DebriefingHome = () => {

  // Contexts
  const { userInfos, changeUserInfos, configJobsIsLoaded, userJobs, userJobFavorite } = useGame();

  // States
  const [isLoading, setIsLoading] = useState(true);
  const [job, setJob] = useState(null);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [email, setEmail] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [sendEmailIsLoading, setSendEmailIsLoading] = useState(false);
  const [isConfirmedEmailModalOpen, setIsConfirmedEmailModalOpen] = useState(false);

  // email
  useEffect(() => {
    setEmail(prevEmail => !prevEmail && userInfos.email ? userInfos.email : (!prevEmail ? '' : prevEmail));
  }, [userInfos, setEmail]);

  // Get favorite job
  useEffect(() => {
    if (configJobsIsLoaded) {
      setJob({
        ...(userJobs[userJobFavorite] || {}),
        ...(JobsService.getJob(userJobFavorite)),
      });
    }
  }, [configJobsIsLoaded, userJobs, userJobFavorite, setJob]);

  // Finish loading state
  useEffect(() => {
    if (job) {
      setIsLoading(false);
    }
  }, [job, setIsLoading]);

  // validate email
  useEffect(() => {
    if (email) {
      const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      setEmailError(!emailRegex.test(email) ? 'Ton adresse email n\'est pas valide.' : null);
    }
  }, [email, setEmailError]);

  // Events
  const onTypeEmail = useCallback(({ target: { value } }) => {
    setEmail(String(value).toLowerCase());
  }, [setEmail]);

  const openEmailModal = useCallback(() => {
    setIsEmailModalOpen(true);
  }, [setIsEmailModalOpen]);

  const validateEmailAndGoToNext = useCallback(() => {
    if (!emailError) {
      const cleanEmail = String(email).toLowerCase();
      changeUserInfos('email', cleanEmail);
      setSendEmailIsLoading(true);
      ApiService.emailBookLink(cleanEmail, {
        onSuccess: () => {
          setSendEmailIsLoading(false);
          setIsEmailModalOpen(false);
          setIsConfirmedEmailModalOpen(true);
        },
        onError: (message) => {
          setSendEmailIsLoading(false);
          setEmailError(message);
        },
      });
    } else {
      setEmailError('Tu dois saisir une adresse email pour recevoir le lien vers ton book.');
    }
  }, [email, emailError, changeUserInfos, setSendEmailIsLoading, setIsEmailModalOpen, setIsConfirmedEmailModalOpen]);

  return (

    <div className="mb-10">

      <VCenter>
        <VTitle>
          <p><span className="font-bold">LE DEBRIEFING</span> <br />DU DIRECTEUR DE CASTING</p>
        </VTitle>

        <VMessenger audio="06_01.mp3"  >
          Félicitations ! Convocation, auditions, repérage, coaching : tu as passé haut la main toutes les étapes, bravo ! Tu as le profil parfait ! Je te remets donc officiellement ton book professionnel.
        </VMessenger>

        <VCenter>

          <VSticker>Félicitations</VSticker>
          <VTutor id="11" />

          {job && <p className="text-3xl font-bold">Tu as le profil parfait pour devenir <br />{job.name} !</p>}

          <VSpace />

          {!isLoading ? <>
            <p className="text-2xl font-medium">Souhaites-tu recevoir par email le lien direct à ton book ?</p>
            <div className="flex gap-6 items-center">
              <VButton className="mt-6" onPress={openEmailModal}>Oui, je le veux !</VButton>
              {/* <VButtonLight className="mt-6" onPress={goToNext} isLoading={isNextLoading}>Je continue sans</VButtonLight> */}
            </div>
          </> : <VLoading label="CHARGEMENT" />}

        </VCenter>

      </VCenter>

      <VModalConfirm isOpen={isEmailModalOpen && !isConfirmedEmailModalOpen}>
        <ModalContent>
          <ModalBody>
            <p className="text-2xl leading-5 font-semibold">Renseignes ton adresse email pour recevoir un lien vers ton book !</p>
            <VInput
              type="text"
              label="Ton adresse email"
              aria-label="Ton adresse email"
              labelPlacement="inside"
              radius="lg"
              size="md"
              isInvalid={email && emailError !== null}
              value={email}
              onChange={onTypeEmail}
            />
            {(email && emailError) && <p className="text-white -mt-5">{emailError}</p>}
          </ModalBody>
          <ModalFooter>
            <Button color="light" variant="light" className='text-md font-medium uppercase' onPress={() => setIsEmailModalOpen(false)}>Annuler</Button>
            <Button color="primary" className='text-md font-medium uppercase' onPress={validateEmailAndGoToNext} isLoading={sendEmailIsLoading} isDisabled={!email || email === '' || emailError !== null}>Confirmer</Button>
          </ModalFooter>
        </ModalContent>
      </VModalConfirm>

      <VModalConfirm isOpen={isConfirmedEmailModalOpen}>
        <ModalContent>
          <ModalBody>
            <p className="text-2xl leading-5 font-semibold">Tu vas recevoir le lien vers ton book directement dans ta boîte email !</p>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" className='text-md font-medium uppercase' onPress={() => setIsConfirmedEmailModalOpen(false)}>Continuer</Button>
          </ModalFooter>
        </ModalContent>
      </VModalConfirm>

    </div>
  );
}

export default DebriefingHome;