import { CheckboxGroup, cn } from "@nextui-org/react";

export const CheckboxGrid = (props) => {
  const { children, ...otherProps } = props;

  return (
    <CheckboxGroup
      {...otherProps}
      classNames={{
        wrapper: cn(
          "!grid !grid-cols-1 md:!grid-cols-2 px-6"
        ),
      }}
    >
      {children}
    </CheckboxGroup>
  );
};