import { useCallback, useEffect, useRef, useState } from 'react';

// Providers
import { useGame } from '../../providers/GameProvider'
import { useThemeToast } from '../../providers/ThemeProvider';

// Components
import { RadioGroup } from '@nextui-org/react';
import { VCenter, VTitle, VButton, VPage, VSpace, VSticker, VCardSolid, VLoading, VCardPelliculeRepeat, VButtonLink } from "../../components/Vues";
import { RadioText } from '../../components/RadioText';
import ToastMessage from '../ToastMessage';

// Icons
import { FaArrowLeft } from 'react-icons/fa6';

// -- Swiper
import { EffectCards } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-cards';

const AuditionsTest = ({ onEnd, isNextLoading }) => {

  // Contexts
  const { appDebugMode, confIsLoaded, axesQuestions, userAxes, setUserAxesFromPersonalities } = useGame();

  // Toasts
  const showToast = useThemeToast(<ToastMessage image="01"><p>Super, tu peux valider tes réponses !</p></ToastMessage>);
  const showToastGo = useThemeToast(<ToastMessage image="01"><p>Intéressant, ça se précise !</p></ToastMessage>);
  const showToastTooLong = useThemeToast(<ToastMessage image="01"><p>Tu hésites ? Fais toi confiance, personne ne te connait mieux que toi-même !</p></ToastMessage>);

  // States
  const [isLoading, setIsLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [questionAnswers, setQuestionAnswers] = useState({});

  // Set answer for one question
  const setQuestionAnswer = useCallback((questionIndex) => (selected) => {
    setQuestionAnswers(prevQuestionAnswers => ({
      ...prevQuestionAnswers,
      [questionIndex]: selected,
    }));
  }, [setQuestionAnswers]);

  // Wait for config (questions selection)
  useEffect(() => {
    setIsLoading(!confIsLoaded);
  }, [confIsLoaded]);

  // init current question
  useEffect(() => {
    if (currentQuestion === null && axesQuestions.length) {
      setCurrentQuestion(axesQuestions[0].index);
      setUserAxesFromPersonalities([]);
    }
  }, [axesQuestions, currentQuestion, setCurrentQuestion, setUserAxesFromPersonalities]);

  // save personalities
  useEffect(() => {
    setUserAxesFromPersonalities(Object.values(questionAnswers));
  }, [questionAnswers, setUserAxesFromPersonalities]);

  // finish test
  useEffect(() => {
    if (userAxes && userAxes !== null) {
      let answered = [];
      Object.values(userAxes).forEach(axe => {
        answered = answered.concat(axe.personalities.filter((value) => !answered.includes(value)));
      });
      // console.log('completed', answered.length + '/' + axesQuestions.length, userAxes, answered, axesQuestions);
      setIsComplete(answered.length >= axesQuestions.length);
    }
  }, [axesQuestions, userAxes, setIsComplete]);

  useEffect(() => {
    if (isComplete) {
      showToast();
    }
  }, [isComplete, showToast]);

  // Refs
  const swiperElRef = useRef(null);

  useEffect(() => {
    swiperElRef.current.swiper.slideTo(currentQuestion);
  }, [currentQuestion]);

  useEffect(() => {
    if (currentQuestion === 5) {
      showToastGo();
    }
  }, [currentQuestion, showToastGo]);

  const timerTooLong = useRef(null);
  useEffect(() => {
    if (timerTooLong && timerTooLong.current) {
      clearTimeout(timerTooLong.current);
    }
    timerTooLong.current = setTimeout(() => {
      showToastTooLong();
    }, 5000);
    return () => {
      clearTimeout(timerTooLong.current);
    };
  }, [currentQuestion, showToastTooLong]);

  const goToPreviousQuestion = useCallback((e) => {
    setCurrentQuestion(prevQuestion => prevQuestion > 0 ? (prevQuestion - 1) : 0);
  }, [setCurrentQuestion]);

  const goToNextQuestion = useCallback((e) => {
    setCurrentQuestion(prevQuestion => axesQuestions.length > prevQuestion + 1 ? (prevQuestion + 1) : prevQuestion);
  }, [setCurrentQuestion, axesQuestions]);

  const goToNext = useCallback(() => {
    onEnd();
  }, [onEnd]);

  return (

    <VPage>

      <VCenter>
        <VTitle>
          <p>LE <span className="font-bold">POINT</span><br />SUR TA PERSONNALITÉ</p>
        </VTitle>

        {/* <VMessenger audio="03_05.mp3">Texte à définir</VMessenger> */}

        <div className="!w-full md:!w-[480px] lg:!w-[640px] m-0 py-8">
          {!isLoading ? <Swiper
            ref={swiperElRef}
            modules={[EffectCards]}
            allowTouchMove={false}
            simulateTouch={false}
            grabCursor={false}
            shortSwipes={false}
            loop={false}
            watchSlidesProgress={true}
            spaceBetween={0}
            slidesPerView={1}
            pagination={{ clickable: false }}
            speed={500}
            effect={'cards'}
          >

            {/* ///////////////////////////////////////////// */}

            {axesQuestions.map((question) => (
              <SwiperSlide key={question.index}>

                <VCardSolid className="w-auto">
                  <VCenter>
                    <VCardPelliculeRepeat />

                    <VSticker className="w-40 pb-6">{question.label}</VSticker>

                    <div className="py-8 overflow-hidden">
                      <p className="text-2xl font-medium">Parmi les phrases suivantes, choisis celle qui te correspond le plus.</p>
                      <p className="">Sois le plus spontané possible, il n'y a pas de mauvaise réponse !</p>
                    </div>

                    <RadioGroup onValueChange={setQuestionAnswer(question.index)} value={questionAnswers[question.index]}>
                      {question.answers.map((answer) => (
                        <RadioText key={answer.id} value={answer.id}>{appDebugMode ? `${answer.id} - ` : ''}{answer.phrase}</RadioText>
                      ))}
                    </RadioGroup>

                    <VSpace className="pt-6 mb-12 flex items-center justify-evenly w-full px-7 gap-8">
                      {question.index > 0 && (<VButtonLink onPress={goToPreviousQuestion}><FaArrowLeft size="15" /> Revenir</VButtonLink>)}
                      {/* {Object.keys(questionAnswers).map((a) => (<p key={a}>{questionAnswers[a]}</p>))} */}
                      {question.index < axesQuestions.length - 1 && (<VButton onPress={goToNextQuestion} isDisabled={!questionAnswers[question.index]}>Valider</VButton>)}
                      {question.index === axesQuestions.length - 1 && (<VButton onPress={goToNext} isLoading={isNextLoading} isDisabled={!isComplete}>{appDebugMode ? 'Valider & terminer' : 'Valider'}</VButton>)}
                    </VSpace>

                    <VCardPelliculeRepeat />
                  </VCenter>
                </VCardSolid>

              </SwiperSlide>
            ))}

          </Swiper> : <VLoading label="CHARGEMENT" />}
        </div>

      </VCenter >

    </VPage >
  );
}

export default AuditionsTest;