import { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";

// Providers
import { useAuth } from '../../providers/AuthProvider';
import { useGame } from '../../providers/GameProvider';

// Components
import { Divider, Spinner, CircularProgress, Button } from "@nextui-org/react";

const Auth = ({ authMode, onSuccessNavigate }) => {

  // nicolas@rkcreation.fr : 892a44cc-9730-438f-a1a1-df930aced705

  // Contexts
  const { token } = useParams();
  const navigate = useNavigate();
  const { doLogin, doLoginScorm, changeAuthMode } = useAuth();
  const { appDebugMode } = useGame();

  // States
  const [isLoading, setIsLoading] = useState(true);
  const [isValid, setIsValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [spinnerValue, setSpinnerValue] = useState(0);

  // Auth mode
  useEffect(() => {
    changeAuthMode(authMode);
  }, [changeAuthMode, authMode]);

  useEffect(() => {
    if (token) {
      const onSuccess = () => {
        setIsLoading(false);
        setIsValid(true);
        setSpinnerValue(100);
        navigate(onSuccessNavigate || '/game');
      };
      const onError = (message) => {
        setIsLoading(false);
        setIsValid(false);
        setSpinnerValue(0);
        setErrorMessage(message);
      };
      if (authMode === 'scorm') {
        appDebugMode && console.log('login with hash ', token);
        doLoginScorm(token, { onSuccess, onError });
      } else {
        appDebugMode && console.log('login with token ', token);
        doLogin(token, { onSuccess, onError });
      }
    }
  }, [appDebugMode, authMode, token, doLogin, doLoginScorm, onSuccessNavigate, setIsLoading, setIsValid, setSpinnerValue, navigate]);

  return (
    <section className="justify-center flex-col flex items-center h-60">

      {isLoading && <Spinner label="Connexion en cours..." color="secondary" />}
      {!isLoading && isValid && <CircularProgress label="Connexion" color="success" value={spinnerValue} />}

      {(!isLoading && !isValid) && <>
        <p className="text-2xl mb-3">Accès invalide. Merci de vous reconnecter.</p>
        <p className="text-lg color-danger bg-danger/10 rounded-large p-4 mb-3"><code>{errorMessage}</code></p>
        <Button color="primary" onClick={() => navigate('/#login')} className="mt-4 uppercase">Continuer</Button>
      </>}

      {appDebugMode && <><Divider className="my-6"></Divider><code size="sm" className="text-xs">Token: {token}</code></>}

    </section >
  );
};

export default Auth;