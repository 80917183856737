import { useCheckbox, VisuallyHidden, tv } from "@nextui-org/react";
import { useCallback, useEffect, useState } from "react";
import { FaStar } from "react-icons/fa6";

const checkbox = tv({
    slots: {
        base: "w-6 h-6 flex items-center justify-center rounded-full",
        content: "text-lg"
    },
    variants: {
        isSelected: {
            true: {
                base: "!bg-transparent",
                content: "!text-ifpass-yellow",
            },
            false: {
                base: "!bg-transparent",
                content: "!text-white hover:!text-ifpass-yellow/75",
            },
        },
        isFocusVisible: {
            true: {
                base: "outline-none ring-2 ring-focus ring-offset-2 ring-offset-background",
                content: "",
            },
        }
    },
});

const StarSwitch = ({ onChange, isSelected }) => {
    const {
        children,
        isFocusVisible,
        getBaseProps,
        getInputProps,
    } = useCheckbox({
        defaultSelected: isSelected,
        onValueChange: onChange,
    });

    const [baseClassNames, setBaseClassNames] = useState('');
    const [contentClassNames, setContentClassNames] = useState('');

    useEffect(() => {
        const styles = checkbox({
            isSelected,
            isFocusVisible,
        });
        setBaseClassNames(styles.base());
        setContentClassNames(styles.content());
    }, [isSelected, isFocusVisible, setBaseClassNames, setContentClassNames]);

    return (
        <label {...getBaseProps()}>
            <VisuallyHidden>
                <input {...getInputProps()} />
            </VisuallyHidden>

            <div className={baseClassNames}>
                <div className={contentClassNames}>
                    {isSelected ? <FaStar /> : <FaStar />}
                </div>
            </div>

            {children}

        </label>
    );
};

export default function StarsSwitch({ onChange, rating = 0, ratingBase = 5, ...props }) {
    const onValueChange = useCallback(index => value => {
        const clickedValue = index + 1;
        const newRating = clickedValue < rating || clickedValue > rating ? clickedValue : rating - 1;
        onChange && onChange(newRating);
    }, [rating, onChange]);
    return (
        <div className={`flex gap-0 justify-center bg-white/10 py-1 px-2 rounded-full`}>
            {[...Array(ratingBase)].map((x, i) =>
                <StarSwitch key={i} isSelected={rating > i} onChange={onValueChange(i)} {...props} />
            )}
        </div>
    );
}