import { Radio, cn } from "@nextui-org/react";

export const RadioText = ({ children, isBig, classNames, ...props }) => {
  const newClassNames = {
    label: "text-black leading-5 pr-2",
    base: cn(
      "inline-flex m-0 bg-content1 hover:bg-content2 items-center justify-start text-left",
      "cursor-pointer rounded-lg gap-2 border-2 border-transparent",
      "data-[selected=true]:border-primary",
      `max-w-[${isBig ? '480px' : '380px'}]`,
      `px-${isBig ? 4 : 2} py-${isBig ? 2 : 2}`
    ),
    labelWrapper: 'w-full',
  };
  const askedClassNames = classNames || {};
  return (
    <Radio
      {...props}
      classNames={{
        ...newClassNames,
        ...askedClassNames,
        label: `${newClassNames.label || ''} ${askedClassNames.label || ''}`,
        base: `${newClassNames.base || ''} ${askedClassNames.base || ''}`,
        labelWrapper: `${newClassNames.labelWrapper || ''} ${askedClassNames.labelWrapper || ''}`,
      }}
    >
      {children}
    </Radio>
  );
};