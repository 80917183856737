import { useCallback } from "react";

// Components
import { VCenter, VButton, VMessenger, VSpace, VSticker } from "../Vues";

const DebriefingBravo = ({ onEnd, isNextLoading }) => {

  const goToNext = useCallback(() => {
    onEnd();
  }, [onEnd]);

  return (

    <VCenter>

      <VMessenger audio="06_04.mp3"  >
        Rendez-vous sur le plateau en septembre, ta place t’attend !
      </VMessenger>

      <VSticker>Super, ta place t’attend !</VSticker>

      <VSpace size={4} />

      <VCenter>

        <p className="font-bold text-2xl mb-2">Une question ?</p>
        <VButton className="w-full !py-6" onPress={() => window.open('https://www.ifpass.fr/contact', '_blank')}>Contacter l'IFPASS</VButton>

        <VSpace size={6} />

        <p className="font-bold text-2xl mb-2">Prêt à terminer le jeu ?</p>
        <VButton className="w-full !py-6" onPress={goToNext} isLoading={isNextLoading}>Terminer</VButton>

      </VCenter>

    </VCenter>

  );
}

export default DebriefingBravo;