const PersonalitiesService = {
  perProfileCount: 8,
  personalities: {},
  populateFromConfig: ({ personalities, personalitiesPerProfileCount }) => {
    PersonalitiesService.perProfileCount = personalitiesPerProfileCount;
    personalities.forEach(personality => {
      PersonalitiesService.personalities[personality.id] = {
        id: personality.id,
        ...personality,
      };
    }); 
  },
  getPersonalities: () => Object.values(PersonalitiesService.personalities),
  getPersonalitiesIds: () => Object.keys(PersonalitiesService.personalities),
  getPersonalitiesKeyed: () => PersonalitiesService.personalities,
  getPersonality: id => PersonalitiesService.personalities[id] || null,
};

export default PersonalitiesService;