import { useCallback, useEffect, useState } from "react";

// Providers
import { useGame } from "../../providers/GameProvider";

// Services
import JobsService from "../../services/jobs.service";
import AssetsService from "../../services/assets.service";

// Components
import { Image } from "@nextui-org/react";
import { VCenter, VTitle, VButton, VPage, VSpace, VMessenger } from "../../components/Vues";

const CoachingHome = ({ onEnd, isNextLoading }) => {

  // Contexts
  const { configJobsIsLoaded, userJobs, userJobFavorite } = useGame();

  // States
  const [isLoading, setIsLoading] = useState(true);
  const [job, setJob] = useState(null);

  // Get favorite job
  useEffect(() => {
    if (configJobsIsLoaded && userJobs) {
      setJob({
        ...(userJobs[userJobFavorite] || {}),
        ...(JobsService.getJob(userJobFavorite)),
      });
    }
  }, [configJobsIsLoaded, userJobs, userJobFavorite, setJob]);

  // Finish loading state
  useEffect(() => {
    if (job) {
      setIsLoading(false);
    }
  }, [job, setIsLoading]);

  const goToNext = useCallback(() => {
    onEnd();
  }, [onEnd]);

  return (

    <VPage>

      <VCenter>
        <VTitle>
          <p className="uppercase">Vers la <span className="font-bold">bonne formation</span></p>
        </VTitle>

        <VMessenger audio="05_01.mp3" >
          Tu as la personnalité et le profil idéal pour ce métier ! A partir de maintenant, je m’occupe de tout… Tu n’as plus qu’à suivre le guide !
        </VMessenger>

        {!isLoading && job && <VCenter>

          {job && <div className="flex flex-col justify-around md:min-h-[60vh]">

            <div>
              <p className="text-4xl lg:text-6xl uppercase font-bold my-4 lg:my-8 relative z-2">{job.name}</p>
              <Image src={AssetsService.getImageUrl('tutor/11.png')} className="fixed z-1 bottom-0 right-0 w-[200px] lg:w-auto" />
            </div>

            <div>
              <VSpace />
              <VButton onPress={goToNext} isLoading={isNextLoading}>Continuer</VButton>
            </div>

          </div>}

        </VCenter>}

      </VCenter>

    </VPage>
  );
}

export default CoachingHome;