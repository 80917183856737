import React, { useEffect, useState } from "react";
import { useGame } from "../providers/GameProvider";
import { Image } from "@nextui-org/react";
import { AudioPlayer } from "../components/AudioPlayer";

export const Messenger = ({ children, image, audio, mustStop, autoPlay = true, hasVolumeControl = true, forceAutoplay = false }) => {

  // Contexts
  const { awardFullscreen } = useGame();

  // States
  const [isAutoplay, setIsAutoplay] = useState(autoPlay);

  useEffect(() => {
    setIsAutoplay(forceAutoplay || (autoPlay && !awardFullscreen));
  }, [autoPlay, forceAutoplay, awardFullscreen, setIsAutoplay]);

  return (

    <div className="flex gap-2 mb-1 p-2 items-center">

      {image &&
        <div className="w-[100px]">
          <Image src={image} width={120} />
        </div>}

      <div className="w-[360px]">

        <AudioPlayer src={audio} mustStop={mustStop} autoPlay={isAutoplay} hasVolumeControl={hasVolumeControl} transcript={children}></AudioPlayer>

      </div>
    </div >

  )
}
