import { useState, createContext, useContext, useCallback, useEffect, useRef } from 'react';
import ApiService from '../services/api.service';
import { useNavigate } from 'react-router-dom';
// import { Navigate } from 'react-router-dom';

export const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const { token } = useAuth();

  useEffect(() => {
    if (!token) {
      return navigate('/', { replace: true });
    }
  }, [token, navigate]);

  return children;
};

export const AuthProvider = ({ children }) => {

  // States
  const [token, setToken] = useState(ApiService.currentToken);
  const [authMode, setAuthMode] = useState('web');
  const [isUserRestoredFromApi, setUserRestoredFromApi] = useState(false);
  const [isUserRegistered, setUserRegistered] = useState(false);

  const reloadOnlyOnce = useRef(null);

  // Login / logout
  const handleRegisterNew = useCallback((email, { onSuccess, onError }) => {
    ApiService.registerNew(email, {
      onSuccess: (message) => {
        onSuccess && onSuccess(message);
      },
      onError: (message, error) => {
        onError && onError(message, error);
      },
    });
  }, []);
  const handleRegisterExisting = useCallback((email, { onSuccess, onError }) => {
    ApiService.registerExisting(email, {
      onSuccess: (message) => {
        onSuccess && onSuccess(message);
      },
      onError: (message, error) => {
        onError && onError(message, error);
      },
    });
  }, []);

  const handleLogin = useCallback((token, { onSuccess, onError }) => {
    ApiService.login(token, {
      onSuccess: (message) => {
        setUserRegistered(true);
        setToken(ApiService.currentToken);
        reloadOnlyOnce.current = true;
        setUserRestoredFromApi(true);
        onSuccess && onSuccess(message);
      },
      onError: (message, error) => {
        setUserRegistered(false);
        setToken(null);
        onError && onError(message, error);
      },
    });
  }, [setUserRegistered, setToken, setUserRestoredFromApi]);

  const handleScorm = useCallback((hash, { onSuccess, onError }) => {
    ApiService.scorm(hash, {
      onSuccess: (message) => {
        setUserRegistered(true);
        setToken(ApiService.currentToken);
        reloadOnlyOnce.current = true;
        setUserRestoredFromApi(true);
        onSuccess && onSuccess(message);
      },
      onError: (message, error) => {
        setUserRegistered(false);
        setToken(null);
        onError && onError(message, error);
      },
    });
  }, [setUserRegistered, setToken, setUserRestoredFromApi]);

  const handleReload = useCallback(({ onSuccess, onError }) => {
    handleLogin(ApiService.currentToken, { onSuccess, onError });
  }, [handleLogin]);

  const handleLogout = useCallback(({ onSuccess }) => {
    ApiService.logout({
      onSuccess: () => {
        setToken(null);
        onSuccess && onSuccess();
      },
    });
  }, [setToken]);

  // AUTH.MODE /////////////////////////////////////
  const changeAuthMode = useCallback((mode) => {
    setAuthMode(prevMode => prevMode !== mode && ['web', 'scorm'].indexOf(mode) >= 0 ? mode : prevMode);
  }, [setAuthMode]);

  // AUTH.MODE /////////////////////////////////////
  const doReloadOnce = useCallback(() => {
    if (!(reloadOnlyOnce && reloadOnlyOnce.current) && token) {
      handleReload({});
      reloadOnlyOnce.current = true;
    }
  }, [token, handleReload]);

  return (
    <AuthContext.Provider value={{
      token,
      doRegisterNew: handleRegisterNew,
      doRegisterExisting: handleRegisterExisting,
      doLogin: handleLogin,
      doLoginScorm: handleScorm,
      doReload: handleReload,
      doReloadOnce,
      doLogout: handleLogout,
      authMode,
      changeAuthMode,
      isUserRegistered,
      setUserRegistered,
      isUserRestoredFromApi,
      setUserRestoredFromApi,
    }}>
      {children}
    </AuthContext.Provider>
  );

}