import { useEffect, useState } from 'react';
import { FaEuroSign, FaGlobe, FaLocationCrosshairs, FaUser } from 'react-icons/fa6';
import VideosService, { defaultPoster } from '../services/videos.service';
import AssetsService from '../services/assets.service';
import { VideoPlayer } from './VideoPlayer';

export const JobInfosInner = ({ job, classNames: { itemClassNames: propsItemClassNames }, children }) => {

  // States
  const [activeVideo, setVideo] = useState(null);

  useEffect(() => {
    if (job && job.media) {
      setVideo(VideosService.getVideo(job.media));
    }
  }, [job]);

  const itemClassNames = `bg-black/30 p-2 rounded-lg mb-1 ${propsItemClassNames}`;

  return !job ? <></> : (<>

    <div className="grid grid-cols-1 sm:grid-cols-3 gap-2">

      {/* VIDEO */}
      {activeVideo ? <div className="sm:col-span-2 aspect-video">
        <VideoPlayer src={AssetsService.getVideoUrl(activeVideo.src)} poster={AssetsService.getVideoUrl(activeVideo.poster || defaultPoster)} autoPlay={false} className="aspect-video">
          {activeVideo.vtt && <track default={false} kind="captions" srcLang="Sous-titrage français" src={AssetsService.getVideoUrl(activeVideo.vtt)} />}
        </VideoPlayer>
      </div> : <div className="sm:col-span-2">
        <div className="bg-black aspect-video w-full"></div>
      </div>}

      <div>

        {/* SALAIRE */}
        {job.infos && job.infos.salary && <div className={itemClassNames}>
          <div className="float-left"><FaEuroSign className="w-8 h-8 bg-white p-2 rounded-full text-black mix-blend-screen" /></div>
          <div className="pl-10">
            <div className="text-sm font-bold leading-4">SALAIRE</div>
            <div className="text-sm leading-4">{job.infos.salary}</div>
          </div>
        </div>}

        {/* MOBILITÉ GÉOGRAPHIQUE */}
        {job.infos && job.infos.mobility && <div className={itemClassNames}>
          <div className="float-left"><FaGlobe className="w-8 h-8 bg-white p-2 rounded-full text-black mix-blend-screen" /></div>
          <div className="pl-10">
            <div className="text-sm font-bold leading-4">MOBILITÉ GÉOGRAPHIQUE</div>
            <div className="text-sm leading-4">{job.infos.mobility}</div>
          </div>
        </div>}

        {/* STATUT */}
        {job.infos && job.infos.status && <div className={itemClassNames}>
          <div className="float-left"><FaUser className="w-8 h-8 bg-white p-2 rounded-full text-black mix-blend-screen" /></div>
          <div className="pl-10">
            <div className="text-sm font-bold leading-4">STATUT</div>
            <div className="text-sm leading-4">{job.infos.status}</div>
          </div>
        </div>}

        {/* MISSIONS */}
        {job.infos && job.infos.missions && <div className={itemClassNames}>
          <div className="float-left"><FaLocationCrosshairs className="w-8 h-8 bg-white p-2 rounded-full text-black mix-blend-screen" /></div>
          <div className="pl-10">
            <div className="text-sm font-bold leading-4">MISSIONS</div>
            <div className="text-sm leading-4">{job.infos.missions}</div>
          </div>
        </div>}

      </div>
    </div>

    <div className="grid grid-cols-1 sm:grid-cols-12 gap-2 mt-2">
      {job.infos && job.infos.experience && <div className={`${itemClassNames} col-span-4`}>
        <div className="text-sm font-bold leading-4">EXPÉRIENCE</div>
        <div className="text-sm leading-4">{job.infos.experience}</div>
      </div>}
      {job.infos && job.infos.evolution && <div className={`${itemClassNames} col-span-4`}>
        <div className="text-sm font-bold leading-4">ÉVOLUTION PASSERELLES</div>
        <div className="text-sm leading-4"><ul>{job.infos.evolution.map((text, i) => (<li key={i}>{text}</li>))}</ul></div>
      </div>}
      {job.infos && job.infos.plus1 && <div className={`${itemClassNames} col-span-4`}>
        <div className="text-sm font-bold leading-4">LE PETIT PLUS</div>
        <div className="text-sm leading-4">{job.infos.plus1}</div>
      </div>}
      {job.skills && <div className={`${itemClassNames} col-span-4`}>
        <div className="text-sm font-bold leading-4">TÂCHES</div>
        <div className="text-sm leading-4"><ul>{job.skills.map((text, i) => (<li key={i}>{text}</li>))}</ul></div>
      </div>}
      {job.qualities && <div className={`${itemClassNames} col-span-4`}>
        <div className="text-sm font-bold leading-4">QUALITÉS NÉCESSAIRES</div>
        <div className="text-sm leading-4"><ul>{job.qualities.map((text, i) => (<li key={i}>{text}</li>))}</ul></div>
      </div>}
      {job.infos && job.infos.action && <div className={`${itemClassNames} col-span-4`}>
        <div className="text-sm font-bold leading-4">ACTION</div>
        <div className="text-sm leading-4">{job.infos.action}</div>
      </div>}
      {job.infos && job.infos.plus && <div className={`${itemClassNames} col-span-4`}>
        <div className="text-sm font-bold leading-4">LE +</div>
        <div className="text-sm leading-4">{job.infos.plus}</div>
      </div>}
    </div>

    {job.infos && job.infos.remark && <div className={itemClassNames}>
      <div className="text-sm leading-4">{job.infos.remark}</div>
    </div>}

    {children}

  </>);
};

export const JobInfos = ({ className, titleClassName, contentClassName, job, children }) => {

  return !job ? <></> : (
    <div className={`text-left items-center flex flex-col flex-nowrap ${className}`}>

      <p className={`hidden sm:block text-2xl text-center font-bold text-[#5421a6] py-4 ${titleClassName}`}>{job.name}</p>

      <div className={`bg-primary/60 p-4 rounded-lg col-span-2 text-white ${contentClassName}`}>

        <p className={`block md:hidden mt-0 text-2xl text-center font-bold text-[#5421a6] py-4 ${titleClassName}`}>{job.name}</p>

        <JobInfosInner job={job} classNames={{
          itemClassNames: 'bg-white/10 text-white',
        }} />

      </div>

      {children}

    </div>
  );
}

export default JobInfos;