import { VImage } from "./Vues";

const ToastMessage = ({ image, children, className, closeToast, toastProps, ...props }) => (
  <div className={`flex items-center content-center ${className}`} {...props}>
    {image && <VImage
      src={`tutor/${image}.png`}
      width={80}
      radius="full"
      className="bg-white"
    />}
    <div className="ml-4">
      {children}
    </div>
  </div>
);

export default ToastMessage;