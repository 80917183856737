import ProgressService from "./progress.service";

const DebugService = {
  isDebug: () => {
    return parseInt(ProgressService.get('debugEnabled')) === 1;
  },
  setDebug: (enable = true) => {
    ProgressService.set('debugEnabled', !!enable ? 1 : 0);
  },
};

export default DebugService;
