// Providers
import { useGame } from '../../providers/GameProvider';

// Components
import SessionWrapper from './SessionWrapper';
import FinDuJeuScreen from '../../components/game/FinDuJeuScreen';
import JobsService from '../../services/jobs.service';
import { useCallback } from 'react';

export const gameProgression = 100;
export const gameProgressionTo = 100;
export const gameChapter = '06';
export const gameBackground = '01';
export const gamePage = 'findujeu';
export const gameProgressBar = false;
export const gameFrame = true;

export const gameSteps = [
  {
    id: 'end',
    component: (props) => (<FinDuJeuScreen {...props} />),
    saveOnEnd: true,
  },
];

const FinDuJeu = () => {

  // Contexts
  const { userInfos, avatarId, teamId, awards, userAxes, userJobFavorite, coachingCompetencesRating, cursusLikes, resetBookWithData } = useGame();

  // Reset all infos
  const resetOnStart = useCallback(() => {
    // ensure book is completed
    if (userInfos) {
      resetBookWithData({
        infos: userInfos,
        avatar: avatarId,
        team: teamId,
        awards,
        axes: userAxes,
        job: userJobFavorite ? JobsService.getJob(userJobFavorite) : null,
        withCursus: true,
        competencesRating: coachingCompetencesRating,
        cursusLikes,
      });
    }
  }, [userInfos, avatarId, teamId, awards, userAxes, userJobFavorite, coachingCompetencesRating, cursusLikes, resetBookWithData]);

  return <SessionWrapper
    progression={gameProgression}
    progressionTo={gameProgressionTo}
    page={gamePage}
    steps={gameSteps}
    chapter={gameChapter}
    background={gameBackground}
    progressBar={gameProgressBar}
    frame={gameFrame}
    resetOnStart={resetOnStart}
    valuesToSave={{
      userInfos,
    }}
    nextPage={'book'}
  />;
}

export default FinDuJeu;
