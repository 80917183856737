import { useRef, useEffect, useCallback } from "react";

// Providers
import { useGame } from '../providers/GameProvider';

export const VideoPlayer = ({ controls, autoPlay, children, ...props }) => {

	// Context
	const { appDebugMode, volume, changeVolume } = useGame();

	// Refs
	const videoRef = useRef();

	useEffect(() => {
		appDebugMode && console.log(videoRef.current.volume + " : " + volume);
		videoRef.current.volume = volume;
	}, [appDebugMode, volume]);

	const onVolumeChanged = useCallback(() => {
		let videoPlayer = videoRef.current;
		if (videoPlayer.muted) {
			changeVolume(0);
		} else {
			changeVolume(videoPlayer.volume);
		}
	}, [changeVolume]);

	return (
		<video
			controls={controls !== false}
			autoPlay={autoPlay !== false}
			onVolumeChange={onVolumeChanged}
			// onCanPlay={() => autoPlay !== false ? videoRef.current.play() : {}}
			ref={videoRef}
			{...props}
		>
			{children}
		</video>
	);
}