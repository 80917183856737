const CursusService = {
  cursuses: {},
  populateFromConfig: ({ cursus }) => {
    cursus.forEach(cursus => {
      CursusService.cursuses[cursus.id] = {
        id: cursus.id,
        active: cursus.active || true,
        ...cursus,
      };
    }); 
  },
  getCursuses: () => Object.values(CursusService.cursuses),
  getCursusesIds: () => Object.keys(CursusService.cursuses),
  getCursusesKeyed: () => CursusService.cursuses,
  getCursus: id => {
    const cursus = CursusService.cursuses[id] || null;
    return cursus !== null && cursus.active ? cursus : null;
  },
};

export default CursusService;