import { useCallback, useEffect, useRef, useState } from 'react';

// Providers
import { useGame } from '../../providers/GameProvider.js';
import { useThemeToast } from '../../providers/ThemeProvider.js';

// Services
import JobsService from '../../services/jobs.service.js';
import { defaultPoster } from '../../services/videos.service.js';

// Components
import { Button, ModalBody, ModalContent, ModalFooter, ModalHeader } from "@nextui-org/react";
import { VCenter, VTitle, VButton, VPage, VSpace, VSticker, VImage, VLoading, VMessenger, VModal, VModalConfirm, VTutor } from "../../components/Vues";
import LikeSwitch from '../../components/LikeSwitch.js';
import JobInfos from '../JobInfos.js';
import ToastMessage from '../ToastMessage.js';

// Icons
import { FaPlay } from "react-icons/fa6";

// -- Swiper
import { Pagination, A11y, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// import Carousel effect module
import EffectCarousel from '../../components/swiper/effect-carousel.esm.js';
// import Carousel effect styles
import '../../components/swiper/effect-carousel.css';

const ReperagesResults = ({ onEnd, isNextLoading }) => {

  // Contexts
  const { configJobsIsLoaded, userJobs, setUserJobs, addAward, addVideos } = useGame();

  // Toasts
  const showToast = useThemeToast(<ToastMessage image="01"><p>N’oublie pas de liker les métiers qui peuvent t’intéresser !</p></ToastMessage>);

  // States
  const [isLoading, setIsLoading] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);
  const [mainJobs, setMainJobs] = useState([]);
  const [secondaryJobs, setSecondaryJobs] = useState([]);
  const [jobModalOpened, setJobModalOpened] = useState([]);
  const [isJobModalOpen, setIsJobModalOpen] = useState(false);
  const [jobModal, setJobModal] = useState(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  // init current step
  useEffect(() => {
    if (configJobsIsLoaded && userJobs) {
      const sortedJobs = JobsService.populate(JobsService.sort(userJobs, 8));
      setMainJobs(sortedJobs.slice(0, 3));
      setSecondaryJobs(sortedJobs.slice(-5));
      setIsLoading(false);
      const jobsVideosIds = [];
      JobsService.populate(userJobs).forEach((job) => {
        if (job.media) {
          jobsVideosIds.push(job.media);
        }
      });
      if (jobsVideosIds.length) {
        addVideos(jobsVideosIds);
      }
    }
  }, [configJobsIsLoaded, userJobs, setMainJobs, setSecondaryJobs, setIsLoading, addVideos]);

  // Likes
  const toggleLikeJob = useCallback(jobId => (isLiked) => {
    // console.log('like', isLiked, jobId);
    if (userJobs[jobId]) {
      setUserJobs({
        ...userJobs,
        [jobId]: {
          ...userJobs[jobId],
          liked: isLiked,
        },
      });
    }
  }, [userJobs, setUserJobs]);

  // Job modal
  const openJobModal = useCallback(jobId => () => {
    const job = userJobs && userJobs[jobId] ? JobsService.populateJob(userJobs[jobId]) : null;
    setIsJobModalOpen(!!job);
    setJobModal(job);
    setJobModalOpened(prev => !prev.includes(jobId) ? [...prev, jobId] : prev);
    if (userJobs[jobId]) {
      setUserJobs({
        ...userJobs,
        [jobId]: {
          ...userJobs[jobId],
          viewed: true,
        },
      });
    }
  }, [setIsJobModalOpen, setJobModal, setJobModalOpened, userJobs, setUserJobs]);

  const closeJobModal = useCallback(() => {
    setIsJobModalOpen(false);
    setJobModal(null);
  }, [setIsJobModalOpen]);

  // Award
  useEffect(() => {
    if (jobModalOpened.length === 3 && !isJobModalOpen) {
      addAward('solutions', true);
    }
  }, [jobModalOpened, isJobModalOpen, addAward]);

  useEffect(() => {
    showToast(jobModalOpened.length === 1 && !isJobModalOpen);
  }, [jobModalOpened, isJobModalOpen, showToast]);

  // Slider
  const onSlideChange = useCallback(e => {
    setActiveSlide(prevActive => e.realIndex !== prevActive ? e.realIndex : prevActive);
  }, [setActiveSlide]);

  // Refs
  const swiperMD1Ref = useRef(null);
  const swiperMD2Ref = useRef(null);

  const onClickMD1Slide = useCallback((index) => (e) => {
    if (swiperMD1Ref.current) {
      swiperMD1Ref.current.swiper.slideTo(index);
    }
  }, []);

  const goToNextWithConfirm = useCallback(() => {
    setIsConfirmModalOpen(true);
  }, [setIsConfirmModalOpen]);

  const goToNext = useCallback(() => {
    onEnd();
  }, [onEnd]);

  return (

    <VPage>

      <VCenter>
        <VTitle>
          <p>DÉCOUVRE <span className="font-bold">TON PROFIL</span></p>
        </VTitle>

        <VTutor id="01" />

        <VMessenger audio="04_04.mp3" >
          Bonne nouvelle ! J’ai trouvé trois  métiers qui semblent clairement correspondre à ta personnalité et à tes envies. Consulte les différents témoignages et like les métiers qui t’intéressent !
        </VMessenger>


        <div className="w-full">

          <VCenter>

            {!isLoading && mainJobs ? <>
              <Swiper
                ref={swiperMD1Ref}
                onSlideChange={onSlideChange}
                modules={[Pagination, Navigation, A11y, EffectCarousel]}
                navigation={true}
                allowTouchMove={true}
                simulateTouch={true}
                slidesPerView={2}
                pagination={{ clickable: true }}
                effect={'carousel'}
                centeredSlides={false}
                speed={500}
                loop={false}
                grabCursor={true}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 2,
                  },
                  1024: {
                    slidesPerView: 2,
                  },
                }}
              >

                {mainJobs.map((job, index) => (<SwiperSlide key={job.id}
                  className={`text-white font-bold text-sm sm:text-sm uppercase mb-2 ${activeSlide >= 0 && mainJobs[activeSlide] && mainJobs[activeSlide].id === job.id ? 'bg-[#f4554e] shadow-lg' : 'bg-[#de4a42] shadow-2xl blur-[1px] hover:blur-none'}`}
                  virtualIndex={index}
                  onClick={onClickMD1Slide(index)}
                >
                  <VCenter>
                    <div className="relative">
                      <VImage imageFromVideo={true} src={job.media ? "jobs/" + job.media + ".png" : defaultPoster} width={350} radius="none" className="aspect-video" />
                      <div className={`absolute w-full top-0 bottom-0 z-40 ${activeSlide >= 0 && mainJobs[activeSlide] && mainJobs[activeSlide].id === job.id ? '' : 'pointer-events-none'}`}>
                        <Button isIconOnly className='bg-transparent rounded-none h-full w-full flex items-center justify-center' onPress={openJobModal(job.id)}>
                          <div className={`bg-white/50 text-white rounded-full p-4 ${activeSlide >= 0 && mainJobs[activeSlide] && mainJobs[activeSlide].id === job.id && !jobModalOpened.includes(job.id) ? 'motion-safe:animate-pulse' : ''}`}><FaPlay /></div>
                        </Button>
                      </div>
                    </div>
                    <div className='flex items-center w-full !h-20'>
                      <div className='p-4 text-left w-3/4 flex flex-col items-start justify-center gap-1'>
                        <p className="text-lg">{job.name}</p>
                        <p className="text-sm font-medium">Compatibilité {Math.round(job.score * 100)}%</p>
                      </div>
                      <div className='p-4 w-1/4 '>
                        <LikeSwitch isLiked={job.liked} onChange={toggleLikeJob(job.id)} />
                      </div>
                    </div>
                  </VCenter>
                </SwiperSlide>))}

              </Swiper>
            </> : <VLoading label="CHARGEMENT" />}

            <VSpace className="mb-8" />

          </VCenter>

          {!isLoading && secondaryJobs ? <>

            <VSpace size={4} />

            <div className="w-full">

              <VSticker className="w-[320px] mx-auto mb-8">Autres métiers conseillés</VSticker>

              <Swiper
                ref={swiperMD2Ref}
                modules={[Pagination, Navigation, A11y]}
                navigation={true}
                spaceBetween={10}
                pagination={{ clickable: true }}
                slidesPerView={3}
                centeredSlides={false}
                speed={500}
                loop={true}
                grabCursor={true}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 2,
                  },
                  1024: {
                    slidesPerView: 3,
                  },
                }}
              >

                {secondaryJobs.map(job => (<SwiperSlide className="bg-white text-black font-bold text-sm uppercase mb-2" key={job.id}>
                  <div className="relative">
                    <VImage imageFromVideo={true} src={job.media ? "jobs/" + job.media + ".png" : defaultPoster} width={500} radius="none" className="aspect-video" />
                    <div className='absolute w-full top-0 bottom-0 z-40'>
                      <Button isIconOnly className='bg-transparent rounded-none h-full w-full flex items-center justify-center' onPress={openJobModal(job.id)}>
                        <div className={`bg-white/50 text-white rounded-full p-4 ${!jobModalOpened.includes(job.id) ? 'motion-safe:animate-pulse' : ''}`}><FaPlay /></div>
                      </Button>
                    </div>
                  </div>
                  <div className='p-4 text-center md:text-left'>
                    <p className="text-lg h-auto md:h-12">{job.name}</p>
                    <div className='text-center my-2'>
                      <LikeSwitch isLiked={job.liked} isInverse={true} onChange={toggleLikeJob(job.id)} />
                    </div>
                    <p className="text-sm text-center font-medium">Comptabilité {Math.round(job.score * 100)}%</p>
                  </div>
                </SwiperSlide>))}

              </Swiper>

            </div>

          </> : <VLoading label="CHARGEMENT" />}

        </div>

        <VSpace>
          <VButton onPress={goToNextWithConfirm}>continuer</VButton>
        </VSpace>

      </VCenter >

      <VModal isOpen={isJobModalOpen} size='4xl'
        onClose={closeJobModal}
        isDismissable={true}
        classNames={{
          body: "bg-black/70 min-h-screen md:h-auto overflow-auto md:overflow-hidden text-white",
          backdrop: "bg-[#000000]/70",
          base: "border-[#292f46] bg-none bg-transparent",
        }}
      >
        <ModalContent>
          <ModalBody>
            <JobInfos job={jobModal} titleClassName="text-white text-3xl uppercase" className="">
              <Button color="primary" radius="full" onPress={closeJobModal} className="my-4 text-white shadow-lg px-7 h-9 font-medium text-md">
                FERMER
              </Button>
            </JobInfos>
          </ModalBody>
        </ModalContent>
      </VModal>

      <VModalConfirm isOpen={isConfirmModalOpen} size='sm'>
        <ModalContent>
          <ModalHeader className="flex flex-col gap-1 text-2xl leading-7 font-semibold">As-tu terminé ton exploration ?</ModalHeader>
          <ModalBody>
            <div className="flex items-center content-center">
              <VImage
                src="tutor/01.png"
                width={80}
                radius="full"
                className="bg-white"
              />
              <div className="ml-4">
                <p>Si tu n’as pas terminé, tu peux continuer à regarder les différentes vidéos !</p>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="light" variant="light" className='text-xl font-medium uppercase' onPress={() => setIsConfirmModalOpen(false)}>Non</Button>
            <Button color="primary" className='text-xl font-medium uppercase' onPress={goToNext} isLoading={isNextLoading}>Oui</Button>
          </ModalFooter>
        </ModalContent>
      </VModalConfirm>

    </VPage >
  );
}

export default ReperagesResults;