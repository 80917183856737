import { useEffect, useState } from "react";

// Providers
import { useGame } from "../providers/GameProvider";
import { useTheme } from "../providers/ThemeProvider";

// Services
import AssetsService from "../services/assets.service";
import VideosService from "../services/videos.service";

// Components
import { Button, Avatar, Card, CircularProgress, Accordion, Image, Input, Select, SelectItem, Modal } from "@nextui-org/react";
import { AudioPlayer } from "./AudioPlayer";
import { VideoPlayer } from "./VideoPlayer";
import { Messenger } from "./Messenger";

// Icons
import { FaCircleCheck, FaCircleXmark } from "react-icons/fa6";

export const VPage = (props) => {
    return (
        <div className="mb-20" {...props}>
            {props.children}
        </div>
    )
};
// ////////////////////////////////
export const VCenter = ({ className, children, ...props }) => {
    return (
        <div {...props} className={`text-center justify-center flex-col flex items-center ${className}`}>
            {children}
        </div>
    )
};
// ////////////////////////////////
export const VSpace = ({ size, children, ...props }) => {
    return (
        <div className={`my-${size || 2}`} {...props} >
            {children}
        </div>
    )
};
// ////////////////////////////////
export const VColums = (props) => {
    return (
        <div className="flex items-center gap-4" {...props}>
            {props.children}
        </div>
    )
};
// ////////////////////////////////
export const VSplash = (props) => {
    return (
        <div className="uppercase font-bold text-4xl  leading-7 text-ifpass-flash drop-shadow-[-4px_4px_0px_rgba(0,0,0,1)] rotate-[-4deg] m-4 "  {...props}>
            {props.children}
        </div>
    )
};
// ////////////////////////////////
export const VSticker = ({ position, children, ...props }) => {
    return (
        <div  {...props} >
            <div className={`uppercase px-6 text-black font-bold bg-ifpass-yellow rounded drop-shadow-[-4px_4px_0px_rgba(0,0,0,1)] rotate-[-4deg] z-10 relative ${position ? position : 'top-2'}`}>
                {children}
            </div>
        </div>
    )
};
// ////////////////////////////////
export const VTitle = ({ children }) => {
    return (
        <div className=" m-4 text-4xl font-light border-b-1 leading-6 pb-2">
            {children}
        </div>
    )
};
// ////////////////////////////////
export const VSubTitle = ({ children }) => {
    return (
        <div className="mb-4 text-4xl font-light leading-8">
            {children}
        </div>
    )
};

// ////////////////////////////////
export const VButton = ({ children, className, ...props }) => {

    return (
        <Button {...props} className={`uppercase bg-gradient-to-r from-ifpass-purple to-ifpass-orange text-white shadow-lg px-7 h-9 font-medium text-md ${className}`}>
            {children}
        </Button >
    )
};

// ////////////////////////////////
export const VButtonLight = ({ children, className, ...props }) => {

    return (
        <Button {...props} className={`uppercase bg-white text-black shadow-lg px-7 h-9 font-medium text-md ${className}`}>
            {children}
        </Button >
    )
};

// ////////////////////////////////
export const VButtonLink = ({ children, className, ...props }) => {

    return (
        <Button {...props} className={`bg-transparent font-medium px-3 h-6 text-md ${className}`}>
            {children}
        </Button >
    )
};

// ////////////////////////////////
export const VButtonFlat = ({ children, className, ...props }) => {

    return (
        <Button {...props} className={`uppercase bg-ifpass-orange bg-gradient-to-r from-ifpass-orange to-[#ef3f3f] text-white shadow-lg px-7 h-9 font-medium text-md ${className}`}>
            {children}
        </Button >
    )
};

// ////////////////////////////////
export const V2Button = ({ children, className, ...props }) => {

    return (
        <div className="!max-w-[130px] flex text-center">
            <Button {...props} className={`bg-white font-medium shadow-lg px-3 h-6 text-md /*data-[hover=true]:opacity-50*/ ${className}`}>
                {children}
            </Button >
        </div>
    )
};
// ////////////////////////////////
export const V3Button = ({ children, ...props }) => {

    return (
        <Button {...props} className="bg-white text-black shadow-lg px-3 h-6 font-bold text-md /*data-[hover=true]:opacity-50*/">
            {children}
        </Button >
    )
};
// ////////////////////////////////
export const VInput = ({ children, className, classNames, ...props }) => {
    const newClassNames = {
        // inputWrapper: "bg-white",
        // label: "text-black ",
        // input: [
        //     "bg-transparent",
        //     "text-black",
        //     "placeholder:text-black/80 dark:placeholder:text-white",
        // ],
        // innerWrapper: "bg-transparent",
        // inputWrapper: [
        //     "shadow-xl",
        //     "bg-transparent",
        //     "text-camera-red",
        //     "backdrop-blur-xl",
        //     "backdrop-saturate-200",
        //     "hover:bg-default-200/70",
        //     "group-data-[focused=true]:bg-default-200/50",
        //     "!cursor-text",
        // ],
    };
    return (
        <Input className={`w-80 my-2 ${className}`} variant="flat" radius="lg" size="sm" labelPlacement="inside" {...props}
            classNames={{
                ...newClassNames,
                ...(classNames || {}),
            }}
        >
            {children}
        </Input >
    )
};
// ////////////////////////////////
export const VSelect = ({ children, className, classNames, ...props }) => {
    const newClassNames = {
        // trigger: "bg-white",
        /*input: [
            "bg-pink-500",
            "text-black",
            "placeholder:text-black/80 dark:placeholder:text-white",
        ],*/
        // innerWrapper: "bg-transparent",
        // inputWrapper: [
        //     "shadow-xl",
        //     "bg-transparent",
        //     "text-camera-red",
        //     "backdrop-blur-xl",
        //     "backdrop-saturate-200",
        //     "hover:bg-default-200/70",
        //     "group-data-[focused=true]:bg-default-200/50",
        //     "!cursor-text",
        // ],
    };
    return (
        <Select className={`w-80 my-2 ${className}`} variant="flat" radius="lg" size="sm" labelPlacement="inside" {...props}
            classNames={{
                ...newClassNames,
                ...(classNames || {}),
            }}
        >
            {children}
        </Select >
    )
};
export const VSelectItem = ({ children, className, ...props }) => {

    return (
        <SelectItem {...props} className={className}>
            {children}
        </SelectItem >
    )
};

// ////////////////////////////////
export const VTutor = (props) => {
    let id = props.id;
    let src = props.src;
    if (id === undefined) {
        id = '01';
    }
    src = AssetsService.getImageUrl(`tutor/${id}.png`);

    return (
        <Avatar
            {...props}
            src={src}
            classNames={{
                base: "bg-white bg-opacity-80 border-4 w-40 h-40 my-2",
                icon: "text-black/80",
            }}
        />
    )
};

// ////////////////////////////////
export const VIcon = (props) => {
    return (
        <Avatar
            {...props}
            classNames={{
                base: "bg-white",
                base_old: "bg-white w-12 h-12",
                icon: "text-ifpass-orange",
            }}
        />
    )
};
// ////////////////////////////////
export const VImage = ({ src, children, imageFromVideo, ...props }) => {
    return (
        <Image
            {...props}
            src={imageFromVideo ? AssetsService.getVideoUrl(src) : AssetsService.getImageUrl(src)}
        // classNames={{
        //     base: "bg-transparent bg-opacity-80 text-black p-2 mb-1 rounded-2xl shadow-none"
        // }}
        >
            {props.children}
        </Image>
    )
};

// ////////////////////////////////
export const VCard = (props) => {

    return (
        <Card  {...props}
            classNames={{
                base: "bg-white bg-opacity-80 text-black p-2 mb-1 rounded-2xl shadow-none w-96"
            }}
        >
            {props.children}
        </Card>
    )
};

// ////////////////////////////////
export const VCardSolid = (props) => {

    return (
        <Card  {...props}
            classNames={{
                base: "bg-white text-black p-2 mb-1 rounded-2xl shadow-none w-96"
            }}
        >
            {props.children}
        </Card>
    )
};

// ////////////////////////////////
export const VCardPelliculeRepeat = (props) => {

    return (
        <div className="w-full h-[15px] bg-repeat-round bg-[8px]" style={{ backgroundImage: `url('${AssetsService.getImageUrl('pellicule.svg')}')` }}></div>
    )
};

// ////////////////////////////////
export const VAudioPlayer = (props) => {
    return (
        <div className="mb-2">
            <AudioPlayer  {...props}>
                {props.children}
            </AudioPlayer>
        </div>
    )
};

// ////////////////////////////////
export const VLoading = (props) => {

    return (
        <section className="justify-center flex-col flex items-center h-60">
            <CircularProgress {...props} aria-label="Loading..." classNames={{
                svg: "w-20 h-20 drop-shadow-md",
                indicator: "stroke-white",
                track: "stroke-white/10",
                value: "text-3xl font-bold text-white",
            }} />
        </section>
    )
};

// ////////////////////////////////
export const VAccordion = (props) => {
    const itemClasses = {
        base: "data-[disabled=true]:hidden group-[.is-splitted]:px-0 group-[.is-splitted]:bg-transparent group-[.is-splitted]:shadow-none",
        title: "",
        trigger: "hidden",
        indicator: "",
        content: "px-0",
    };

    return (
        <Accordion {...props} itemClasses={itemClasses} showDivider={true} isCompact={true} variant="splitted" className="px-0">
            {props.children}
        </Accordion>
    )
};

// ////////////////////////////////
export const VVideo = ({ id, src, poster, vtt, className, children, ...props }) => {

    // Contexts
    const { addVideo } = useGame();

    const [realSrc, setSrc] = useState(src);
    const [realPoster, setPoster] = useState(poster);
    const [realVtt, setVtt] = useState(vtt);
    useEffect(() => {
        if (id) {
            const video = VideosService.getVideo(id);
            if (video) {
                addVideo(video.id);
                setSrc(video.src || src);
                setPoster(video.poster || poster);
                setVtt(video.vtt || vtt);
            }
        }
    }, [id, src, poster, vtt, setSrc, setPoster, setVtt, addVideo]);

    return !realSrc ? <></> : (
        <>
            <VideoPlayer controls autoPlay className={`w-full border-2 border-ifpass-purple bg-black/50 mb-4 ${className}`} src={AssetsService.getVideoUrl(realSrc)} poster={realPoster && AssetsService.getVideoUrl(realPoster)} {...props} >
                {children}
                {realVtt && <track default={false} kind="captions" srcLang="Sous-titrage français" src={AssetsService.getVideoUrl(realVtt)} />}
            </VideoPlayer>
        </>
    );
};

// ////////////////////////////////
export const VMessenger = ({ children, ...props }) => {
    return (
        <Messenger {...props} >
            {children}
        </Messenger>
    )
};
// ////////////////////////////////
export const VAnswerIcons = ({ visible, correct, ...props }) => {
    return (
        <>
            {visible &&
                <div className="absolute top-2 right-2 z-10 bg-white rounded-full p-[2px]" {...props} >
                    {correct && <FaCircleCheck className="w-[28px] h-[28px] text-green-500" />}
                    {!correct && <FaCircleXmark className="w-[28px] h-[28px] text-red-500" />}
                </div>
            }
        </>
    )
};

//////////////////////////////////
export const VModal = ({ children, classNames, size, ...props }) => {
    const { theme } = useTheme();
    const newClassNames = {
        // body: "py-6 px-8 text-center flex flex-col justify-center items-center gap-4",
        // backdrop: "bg-black/50",
        // base: `bg-black/50 text-white`,
        wrapper: `${theme} text-base`,
        // header: "border-b-1 border-white/10 text-center",
        // footer: "border-t-1 border-white/10",
        // closeButton: "hidden",
    };
    return (
        <>
            <Modal
                placement='center'
                isDismissable={false}
                backdrop={'blur'}
                size={size || 'md'}
                classNames={{
                    ...newClassNames,
                    ...(classNames || {}),
                }}
                data-theme={theme}
                {...props}
            >
                {children}
            </Modal>
        </>
    )
};

//////////////////////////////////
export const VModalConfirm = ({ children, classNames, size, ...props }) => {
    const newClassNames = {
        body: "py-6 px-8 text-center flex flex-col justify-center items-center gap-4",
        backdrop: "bg-black/50",
        base: "bg-black/50 text-white",
        header: "border-b-1 border-white/10 text-center",
        footer: "border-t-1 border-white/10",
        closeButton: "hidden",
    };
    return (
        <>
            <VModal
                placement='center'
                isDismissable={false}
                backdrop={'blur'}
                size={size || 'md'}
                classNames={{
                    ...newClassNames,
                    ...(classNames || {}),
                }}
                {...props}
            >
                {children}
            </VModal>
        </>
    )
};


