// Components
import { VCenter, VTitle, VButton, VPage, VImage } from "../Vues";

const FinDuJeuScreen = ({ isNextLoading }) => {

  return (
    <VPage>

      <VCenter>

        <VTitle>
          <p>MERCI POUR <span className="font-bold">TA PARTICIPATION !</span></p>
        </VTitle>

        <VImage width={300} src="logo-ifcast.png" alt="Logo IFCAST"></VImage>

        <VButton className="mt-2 mb-12" onPress={() => window.open('https://www.ifpass.fr/', '_blank')} isLoading={isNextLoading}>Consulter le site de l'IFPASS</VButton>

        <div className="grid grid-cols-2 gap-12 text-center mt-4 mb-4">
          <div className="flex flex-col gap-2">
            <p>Serious game<br />proposé par l'IFPASS</p>
            <div className="w-full flex-grow flex items-center justify-center rounded bg-white p-4">
              <VImage width={160} src="logo-ifpass.svg" alt="Logo IFPASS"></VImage>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <p>Soutenu par<br />le programme Atlas</p>
            <div className="w-full flex-grow flex items-center justify-center rounded bg-white">
              <VImage width={120} src="logo-cfa-de-demain-atlas.jpg" alt="Logo Atlas"></VImage>
            </div>
          </div>
        </div>

        <div className="grid justify-items-center">
          <div className="flex flex-col gap-2">
            <p>Réalisé par<br />NOVAE Digital Learning</p>
            <div className="w-full flex-grow flex items-center justify-center rounded bg-white py-3">
              <VImage width={120} src="logo-novae.png" alt="Logo Atlas"></VImage>
            </div>
          </div>
        </div>

      </VCenter>

    </VPage >
  );
}

export default FinDuJeuScreen;