import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Providers
import { useAuth } from "../../providers/AuthProvider";
import { useTheme } from "../../providers/ThemeProvider";

// Components
import { VButton, VCenter, VImage, VPage, VSpace, VTitle } from "../../components/Vues";

const Logout = () => {

  const navigate = useNavigate();
  const { doLogout } = useAuth();
  const { changeBackground } = useTheme();

  useEffect(() => {
    changeBackground('01');
  }, [changeBackground]);
  
  useEffect(() => {
    doLogout({
      // onSuccess: () => navigate('/'),
      onSuccess: () => {},
    });
  }, [doLogout]);

  return (
    <VPage className="relative">
      <VCenter>

        <VImage width={140} src="logo-ifpass-white.svg" alt="Logo IFPASS"></VImage>

        <VTitle>
          <p>Vous êtes <span className="font-bold">déconnecté</span></p>
        </VTitle>
        
        <VSpace />

        <p className="text-2xl">A bientôt.</p>

        <VSpace />

        <VButton onClick={() => navigate('/')} className="mt-4">Se reconnecter</VButton>

      </VCenter>
    </VPage>
  );
};

export default Logout;