import { Progress } from "@nextui-org/react";

export const profilesColors = {
  PG: "!text-[#5b23a1]",
  PA: "!text-[#782d92]",
  PM: "!text-[#a53c79]",
  PE: "!text-[#f4554e]",
  PC: "!text-[#ca4865]",
};
export const profilesLightColors = {
  PG: "!text-[#5b23a1]/50",
  PA: "!text-[#782d92]/50",
  PM: "!text-[#a53c79]/50",
  PE: "!text-[#f4554e]/50",
  PC: "!text-[#ca4865]/50",
};

export const profilesBgColors = {
  PG: "!bg-[#5b23a1]",
  PA: "!bg-[#782d92]",
  PM: "!bg-[#a53c79]",
  PE: "!bg-[#f4554e]",
  PC: "!bg-[#ca4865]",
};
export const profilesBgLightColors = {
  PG: "!bg-[#5b23a1]/50",
  PA: "!bg-[#782d92]/50",
  PM: "!bg-[#a53c79]/50",
  PE: "!bg-[#f4554e]/50",
  PC: "!bg-[#ca4865]/50",
};

export const profilesBorderColors = {
  PG: "!border-[#5b23a1]",
  PA: "!border-[#782d92]",
  PM: "!border-[#a53c79]",
  PE: "!border-[#f4554e]",
  PC: "!border-[#ca4865]",
};
export const profilesBorderLightColors = {
  PG: "!border-[#5b23a1]/50",
  PA: "!border-[#782d92]/50",
  PM: "!border-[#a53c79]/50",
  PE: "!border-[#f4554e]/50",
  PC: "!border-[#ca4865]/50",
};

const ProfileProgress = ({ profile, classNames, ...props }) => {
    return <Progress
      size="md"
      radius="lg"
      classNames={{
        base: "mb-1 !gap-0",
        track: "bg-white/20",
        indicator: profilesBgColors[profile.id] || '!bg-primary',
        color: profilesColors[profile.id] || '!text-primary',
        label: "font-medium text-white",
        value: "font-medium text-white ",
        ...(classNames || {}),
      }}
      label={profile.label}
      value={profile.score * 100}
      showValueLabel={true}
      {...props}
    />
};

export default ProfileProgress;