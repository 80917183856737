const teams = [
  {
    id: "T1",
    name: "Réflexion",
    src: "teams/03.png",
    videoCategory: "job-gestion",
  },
  {
    id: "T2",
    name: "Action",
    src: "teams/01.png",
    videoCategory: "job-commerce",
  },
  {
    id: "T3",
    name: "Relation",
    src: "teams/02.png",
    videoCategory: "job-mgmt",
  },
];
const TeamsService = {
  teams: {},
  getTeams: () => Object.values(TeamsService.teams),
  getTeamsIds: () => Object.keys(TeamsService.teams),
  getTeamsKeyed: () => TeamsService.teams,
  getTeam: id => TeamsService.teams[id] || null,
};
teams.forEach(team => {
  TeamsService.teams[team.id] = {
    id: team.id,
    ...team,
  };
}); 

export default TeamsService;