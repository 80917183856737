import TeamsService from "./teams.service";
import VideosService from "./videos.service";

const JobsService = {
  axesQuestionsWeight: 0.7,
  jobs: {},
  jobsTasks: {},
  populateFromConfig: ({ jobs }) => {
    jobs.forEach((job, index) => {
      const tasks = [];
      job.tasks.forEach(task => {
        const taskId = task.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
          return index === 0 ? word.toLowerCase() : word.toUpperCase();
        }).replace(/\s+/g, '').replace(/[^a-zA-Z0-9]/g, '');
        JobsService.jobsTasks[taskId] = {
          id: taskId,
          phrase: task,
        };
        tasks.push(taskId);
      });
      JobsService.jobs[job.id] = {
        id: job.id,
        active: job.active || true,
        image: job.media ? `${job.media}.png` : null,
        weight: index,
        ...job,
        tasks,
      };
      if (job.media) {
        const team = job.team ? TeamsService.getTeam(job.team) : null;
        VideosService.videos[job.media] = {
          id: job.media,
          src: `jobs/${job.media}.mp4`,
          poster: `jobs/${job.media}_thumb.png`,
          categories: team ? [team.videoCategory, 'all'] : ['all'],
          weight: 100 + index,
        };
      }
    });
    // console.log(JobsService.jobs);
  },
  getJobs: () => Object.values(JobsService.jobs),
  getJobsIds: () => Object.keys(JobsService.jobs),
  getJobsKeyed: () => JobsService.jobs,
  getJob: id => JobsService.jobs[id] || null,
  getJobsTasks: () => Object.values(JobsService.jobsTasks),
  getJobsTasksIds: () => Object.keys(JobsService.jobsTasks),
  getJobsTasksKeyed: () => JobsService.jobsTasks,
  getJobsTask: id => JobsService.jobsTasks[id] || null,
  sortByProperty: sortProperty => (a, b) => b[sortProperty] === a[sortProperty] ? a.weight - b.weight : b[sortProperty] - a[sortProperty], // sort by property DESC and by weight ASC if ==
  sort: (userJobs, max, score, favorite) => {
    const sortProperty = score ? `score${score.charAt(0).toUpperCase() + score.substr(1)}` : 'score';
    const sortedJobs = favorite
      ? Object.values(userJobs).sort((a, b) => a.favorite === b.favorite ? JobsService.sortByProperty(sortProperty)(a, b) : ((b.favorite ? 1 : 0) - (a.favorite ? 1 : 0)))
      : Object.values(userJobs).sort(JobsService.sortByProperty(sortProperty));
    return max ? sortedJobs.slice(0, max) : sortedJobs;
  },
  populateJob: (userJob) => ({
    ...(userJob || {}),
    ...(JobsService.getJob(userJob.id)),
  }),
  populate: (userJobs) => Object.values(userJobs).map(userJob => JobsService.populateJob(userJob)),
  populateKeyed: (userJobs) => {
    const jobs = {};
    Object.values(userJobs).forEach(userJob => {
      jobs[userJob.id] = JobsService.populateJob(userJob);
    });
    return jobs;
  },
  computeScore: (scoreAxes, scoreTasks) => (scoreAxes * JobsService.axesQuestionsWeight) + (scoreTasks * (1 - JobsService.axesQuestionsWeight)),
  compute: (userAxes) => {
    const userJobs = {};
    JobsService.getJobs().forEach(job => {
      if (job.active) {
        userJobs[job.id] = {
          id: job.id,
          weight: job.weight,
          score: 0,
          scoreAxes: 0,
          scoreTasks: 0,
          choosenTasks: 0,
          favorite: false,
          liked: false,
          viewed: false,
          scorePerProfile: {},
        };
        job.profiles.forEach(jobProfile => {
          userJobs[job.id].scorePerProfile[jobProfile.id] = userAxes[jobProfile.id] ? (userAxes[jobProfile.id].score * jobProfile.weight) : 0;
          userJobs[job.id].scoreAxes += userJobs[job.id].scorePerProfile[jobProfile.id];
        });
        userJobs[job.id].score = JobsService.computeScore(userJobs[job.id].scoreAxes, userJobs[job.id].scoreTasks);
      }
    });
    return userJobs;
  },
  getJobsThatNeedsStep3: (userJobs, questionsAnswers) => {
    const userJobsWithStep2 = JobsService.computeWithAnswers(userJobs, questionsAnswers, false);
    const sortedJobs = Object.values(JobsService.sort(userJobsWithStep2, 8));
    const firstJobs = sortedJobs.length > 2
      ? (sortedJobs[0].score === sortedJobs[1].score ? sortedJobs.filter(job => job.score === sortedJobs[0].score) : [sortedJobs[0]])
      : (sortedJobs.length > 0 ? [sortedJobs[0]] : []);
    const notFirstJobs = firstJobs.length > 0 ? sortedJobs.filter(job => job.score < firstJobs[0].score) : [];
    const secondJobs = notFirstJobs.length > 2
      ? (notFirstJobs[0].score === notFirstJobs[1].score ? notFirstJobs.filter(job => job.score === notFirstJobs[0].score) : [notFirstJobs[0]])
      : (notFirstJobs.length > 0 ? [notFirstJobs[0]] : []);
    const notSecondJobs = secondJobs.length > 0 ? notFirstJobs.filter(job => job.score < secondJobs[0].score) : [];
    const thirdJobs = notSecondJobs.length > 2
      ? (notSecondJobs[0].score === notSecondJobs[1].score ? notSecondJobs.filter(job => job.score === notSecondJobs[0].score) : [notSecondJobs[0]])
      : (notSecondJobs.length > 0 ? [notSecondJobs[0]] : []);
    // console.log('needs3', sortedJobs, firstJobs, secondJobs, thirdJobs);
    return [].concat(firstJobs.length > 1 ? firstJobs : [], secondJobs.length > 1 ? secondJobs : [], thirdJobs.length > 1 ? thirdJobs : []).map(job => job.id);
  },
  getJobsTasksNeeded: (jobs) => {
    const tasks = jobs.map(jobId => JobsService.getJob(jobId).tasks[2]);
    return tasks.filter((taskId, index) => tasks.indexOf(taskId) === index);
  },
  isStep3Needed: (userJobs, questionsAnswers) => {
    const needsStep3 = JobsService.getJobsThatNeedsStep3(userJobs, questionsAnswers);
    const step3Tasks = JobsService.getJobsTasksNeeded(needsStep3);
    return !!needsStep3.length && step3Tasks.length > 1;
  },
  computeWithAnswers: (userJobs, questionsAnswers, withStep3, flag) => {
    // console.log(userJobs, questionsAnswers);
    const newUserJobs = { ...userJobs };
    const countChoosenTasksForAnswers = (answers, jobId) => {
      let count = 0;
      answers.forEach(answer => {
        count += answer.jobs.includes(jobId) && answer.checked ? 1 : 0;
      });
      return count;
    };
    Object.keys(newUserJobs).forEach(jobId => {
      let choosenTasks = 0;
      for (let index = 1; index <= 2; index++) {
        choosenTasks += countChoosenTasksForAnswers(questionsAnswers[`step${index}`], jobId);
      }
      const scoreTasks = choosenTasks / 2;
      const score = JobsService.computeScore(newUserJobs[jobId].scoreAxes, scoreTasks);
      newUserJobs[jobId] = {
        ...newUserJobs[jobId],
        choosenTasks,
        scoreTasks,
        score,
      };
    });
    if (withStep3) {
      const needsStep3 = JobsService.getJobsThatNeedsStep3(newUserJobs, questionsAnswers);
      needsStep3.forEach(jobId => {
        const choosenTasks = newUserJobs[jobId].choosenTasks + countChoosenTasksForAnswers(questionsAnswers.step3, jobId);
        const scoreTasks = choosenTasks / 3;
        const score = JobsService.computeScore(newUserJobs[jobId].scoreAxes, scoreTasks);
        newUserJobs[jobId] = {
          ...newUserJobs[jobId],
          choosenTasks,
          scoreTasks,
          score,
        };
        flag === 10 && console.log(jobId, newUserJobs[jobId].scoreTasks, newUserJobs[jobId].choosenTasks, newUserJobs[jobId]);
      });
      // flag === 10 && console.log('compute step3 for', needsStep3);
    }
    // flag === 10 && console.log('computed', flag, userJobs);
    return newUserJobs;
  },
  generateQuestions: (userJobs) => {
    const questions = {};
    for (let index = 1; index <= 3; index++) {
      questions[`step${index}`] = {};
    }
    userJobs.forEach(userJob => {
      JobsService.getJobs().forEach(job => {
        if (job.id === userJob.id) {
          for (let index = 1; index <= 3; index++) {
            const task = JobsService.getJobsTask(job.tasks[index - 1]);
            if (task) {
              questions[`step${index}`][task.id] = {
                ...(questions[`step${index}`][task.id] || {}),
                jobs: questions[`step${index}`][task.id] ? questions[`step${index}`][task.id].jobs.concat([job.id]) : [job.id],
                taskId: task.id,
                phrase: task.phrase,
              };
            }
          }
        }
      });
    });
    for (let index = 1; index <= 3; index++) {
      questions[`step${index}`] = Object.values(questions[`step${index}`]).sort(() => 0.5 - Math.random()); // return arrays, randomly sorted
    }
    return questions;
  },
};

export default JobsService;