import React, { useState, useEffect, useRef, useCallback } from "react";
import { Button, Slider } from "@nextui-org/react";
import ReactAudioPlayer from 'react-audio-player';

// Providers
import { useGame } from '../providers/GameProvider';

// Services
import AssetsService from "../services/assets.service";

// Icons
import { FaVolumeHigh, FaVolumeLow, FaVolumeXmark, FaPlay, FaPause, FaUniversalAccess, FaAngleUp } from "react-icons/fa6";

export const AudioPlayer = ({ src, id, mustStop, autoPlay = true, hasVolumeControl = true, transcript = "" }) => {

	// Context
	const { volume, changeVolume, awardFullscreen } = useGame();

	// State
	const [canPlay, setCanPlay] = useState(false);
	const [isPlaying, setIsPlaying] = useState(false);
	const [hasPlayed, setHasPlayed] = useState(false);
	const [duration, setDuration] = useState(0);
	const [currentTime, setCurrentTime] = useState(0);

	const [hasTranscript, setHasTranscript] = useState(false);

	const audioSrc = AssetsService.getAudioUrl(src);
	const audioID = id;

	// Playback
	const playSound = useCallback(() => {
		let audioPlayer = audioRef.current.audioEl.current;
		audioPlayer.currentTime = 0;
		audioPlayer.play();
	}, []);
	const seekAndPlaySound = useCallback((time) => {
		let audioPlayer = audioRef.current.audioEl.current;
		audioPlayer.currentTime = time;
		playSound();
	}, [playSound]);
	const pauseSound = useCallback(() => {
		let audioPlayer = audioRef.current.audioEl.current;
		audioPlayer.pause();
	}, []);

	// Stop
	useEffect(() => {
		if (mustStop) {
			// console.warn("STOP !!");
			pauseSound(null);
		}
	}, [mustStop, pauseSound]);

	// Awards
	useEffect(() => {
		if (awardFullscreen) {
			// console.log('stop cause awards fullscreen');
			pauseSound();
		} else if (!awardFullscreen && autoPlay && canPlay && !hasPlayed) {
			// console.log('play');
			playSound();
		}
	}, [awardFullscreen, autoPlay, canPlay, hasPlayed, playSound, pauseSound]);

	// Refs
	const audioRef = useRef();

	// Events
	const onError = (e) => {
		console.log("onError", e);
	}

	const onListen = useCallback((e) => {
		let audioPlayer = audioRef.current.audioEl.current;
		setCurrentTime((audioPlayer.currentTime));
		setHasPlayed(prev => !prev ? (audioPlayer.currentTime > 1) : prev);
	}, [setCurrentTime]);

	const onCanPlay = useCallback((e) => {
		let audioPlayer = audioRef.current.audioEl.current;
		setDuration((audioPlayer.duration));
		if (autoPlay !== false) {
			// audioPlayer.play();
		}
	}, [setDuration, autoPlay]);

	const onCanPlayThrough = useCallback((e) => {
		setCanPlay(true);
	}, [setCanPlay]);

	const onPlay = useCallback((e) => {
		setIsPlaying(true);
	}, [setIsPlaying]);

	const onPause = useCallback((e) => {
		setIsPlaying(false);
	}, [setIsPlaying]);

	const onEnded = useCallback((e) => {
		setIsPlaying(false);
		onListen();
	}, [setIsPlaying, onListen]);

	const onSeeked = useCallback((e) => {
		let audioPlayer = audioRef.current.audioEl.current;
		setCurrentTime((audioPlayer.currentTime));
	}, [setCurrentTime]);

	const onVolumeChanged = useCallback(() => {
		let audioPlayer = audioRef.current.audioEl.current;
		if (audioPlayer.muted) {
			changeVolume(0);
		} else {
			changeVolume(audioPlayer.volume);
		}
	}, [changeVolume]);

	const onChangeVolume = useCallback((e) => {
		let audioPlayer = audioRef.current.audioEl.current;
		audioPlayer.volume = e;
	}, []);

	const toggleTranscript = useCallback((e) => {
		setHasTranscript(prev => !prev);
	}, [setHasTranscript]);

	return (
		<div className="max-w-full mx-auto ">

			<ReactAudioPlayer
				src={audioSrc}
				id={audioID}
				autoPlay={autoPlay}
				controls={false}
				volume={volume}
				onError={onError}
				onCanPlay={onCanPlay}
				onCanPlayThrough={onCanPlayThrough}
				onPlay={onPlay}
				onPause={onPause}
				listenInterval={50}
				onListen={onListen}
				onEnded={onEnded}
				onSeeked={onSeeked}
				onVolumeChanged={onVolumeChanged}
				ref={audioRef}
			/>

			<div className="flex flex-col  min-w-[300px] bg-white/80 px-2 rounded-b-lg">
				<div className="flex gap-2 items-center">

					{!isPlaying ? <Button isIconOnly size="sm" variant="light" radius="full" disabled={!canPlay} onPress={playSound} ><FaPlay width={16} height={16} className="text-black" /></Button> : <Button isIconOnly size="sm" variant="light" radius="full" disabled={!canPlay} onPress={pauseSound}><FaPause width={16} height={16} className="text-black" /></Button>}

					<Slider
						aria-label="Progress"
						size="sm"
						hideThumb={true}
						color="danger"
						step={0.05}
						maxValue={duration}
						value={currentTime}
						minValue={0}
						onChange={seekAndPlaySound}
						className="max-w-full "
						classNames={{
							track: "h-[6px] rounded bg-black/40",
							filler: "bg-white",
						}}
					/>

					{hasVolumeControl &&

						<Slider
							size="md"
							step={0.05}
							maxValue={1}
							minValue={0}
							showSteps={false}
							aria-label="Volume"
							onChange={onChangeVolume}
							value={volume}
							color="foreground"
							className="w-20"
							classNames={{
								track: "h-[6px] rounded bg-black/40",
								filler: "bg-white",
							}}
							renderThumb={(props) => (
								<div
									{...props}
									className="mt-[3px] group p-1 bg-white/80 text-black shadow-medium rounded-full cursor-grab data-[dragging=true]:cursor-grabbing"
								>
									<span className="transition-transform  block group-data-[dragging=true]:scale-80" >
										{volume > 0.5 && <FaVolumeHigh width={16} height={16} />}
										{volume > 0 && volume <= 0.5 && <FaVolumeLow width={16} height={16} />}
										{volume === 0 && <FaVolumeXmark width={16} height={16} />}
									</span>
								</div>
							)}

						/>
					}

					{
						(transcript.length <= 0) ? "" :
							!hasTranscript ? <Button isIconOnly size="sm" variant="light" radius="full" onPress={toggleTranscript} ><FaUniversalAccess width={16} height={16} className="text-black" /></Button> : <Button isIconOnly size="sm" variant="light" radius="full" onPress={toggleTranscript}><FaAngleUp width={16} height={16} className="text-black" /></Button>
					}

				</div>

				{
					hasTranscript ? <div className="p-2 my-2 text-black font-medium bg-white/30 rounded-lg">{transcript}</div> : <></>

				}
			</div>

		</div >
	);
}