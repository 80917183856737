import { useCallback } from 'react';

// Providers
import { useGame } from '../../providers/GameProvider';

// Components
import SessionWrapper from './SessionWrapper';
import { gameProgression as toProgression, gamePage as toPage } from './Brief';
import AccueilHome from '../../components/game/AccueilHome';
import AccueilInfos from '../../components/game/AccueilInfos';
import AccueilEnd from '../../components/game/AccueilEnd';

export const gameProgression = 0;
export const gameProgressionTo = toProgression;
export const gameChapter = '00';
export const gameBackground = '01';
export const gamePage = 'accueil';
export const gameProgressBar = false;
export const gameFrame = false;
export const gameBook = false;
export const gameVideos = false;

export const gameSteps = [
  {
    id: 'home',
    component: (props) => (<AccueilHome {...props} />),
    saveOnEnd: false,
  },
  {
    id: 'infos',
    component: (props) => (<AccueilInfos {...props} />),
    saveOnEnd: true,
    background: '02',
  },
  {
    id: 'end',
    component: (props) => (<AccueilEnd {...props} />),
    saveOnEnd: false,
    background: '02',
    progressBar: true,
  },
];

const Accueil = () => {

  // Contexts
  const { userInfos, resetAccueil } = useGame();

  // Reset all infos
  const resetOnStart = useCallback(() => {
    resetAccueil();
  }, [resetAccueil]);

  return <SessionWrapper
    progression={gameProgression}
    progressionTo={gameProgressionTo}
    page={gamePage}
    steps={gameSteps}
    chapter={gameChapter}
    background={gameBackground}
    progressBar={gameProgressBar}
    frame={gameFrame}
    book={gameBook}
    videos={gameVideos}
    resetOnStart={resetOnStart}
    valuesToSave={{
      userInfos,
    }}
    nextPage={toPage}
  />;
}

export default Accueil;