import { Checkbox, cn } from "@nextui-org/react";

export const CheckboxText = (props) => {
  const { children, ...otherProps } = props;

  return (
    <Checkbox
      {...otherProps}
      classNames={{
        label: cn("text-black leading-5 pr-2"),
        base: cn(
          "inline-flex m-0 bg-content1 hover:bg-content2 items-center justify-start text-left",
          "max-w-[380px] cursor-pointer rounded-lg gap-2 p-2 border-2 border-transparent",
          "data-[selected=true]:border-primary"
        ),
      }}
    >
      {children}
    </Checkbox>
  );
};