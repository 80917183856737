import { useCallback, useEffect } from 'react';

// Services
import AxesService from '../../services/axes.service';

// Providers
import { useGame } from '../../providers/GameProvider';

// Components
import SessionWrapper from './SessionWrapper';
import { gameProgression as toProgression, gamePage as toPage } from './Reperages';
import AuditionsHome from '../../components/game/AuditionsHome';
import AuditionsTest from '../../components/game/AuditionsTest';
import AuditionsScores from '../../components/game/AuditionsScores';
import AuditionsProfiles from '../../components/game/AuditionsProfiles';
import AuditionsEnd from '../../components/game/AuditionsEnd';

export const gameProgression = 10;
export const gameProgressionTo = toProgression;
export const gameChapter = '02';
export const gameBackground = '05';
export const gamePage = 'auditions';
export const gameProgressBar = true;
export const gameFrame = true;

export const gameSteps = [
  {
    id: 'home',
    component: (props) => (<AuditionsHome {...props} />),
    saveOnEnd: false,
  },
  {
    id: 'test',
    component: (props) => (<AuditionsTest {...props} />),
    saveOnEnd: true,
  },
  {
    id: 'scores',
    component: (props) => (<><AuditionsScores {...props} /><AuditionsProfiles {...props} /></>),
    saveOnEnd: false,
  },
  {
    id: 'end',
    component: (props) => (<AuditionsEnd {...props} />),
    saveOnEnd: true,
  },
];

const Auditions = () => {

  // Contexts
  const { configPersonalitiesIsLoaded, userInfos, avatarId, teamId, awards, resetBookWithData, resetAuditions, axesQuestions, setAxesQuestions, userAxes } = useGame();

  // Reset all infos
  const resetOnStart = useCallback(() => {
    resetAuditions();
    // ensure book is completed
    if (userInfos) {
      resetBookWithData({
        infos: userInfos,
        avatar: avatarId,
        team: teamId,
        awards,
      });
    }
  }, [userInfos, avatarId, teamId, awards, resetBookWithData, resetAuditions]);

  // Generate random questions / answers
  useEffect(() => {
    if (configPersonalitiesIsLoaded && (axesQuestions === null || !axesQuestions.length)) {
      const questions = AxesService.generateQuestions();
      setAxesQuestions(questions);
    }
  }, [configPersonalitiesIsLoaded, axesQuestions, setAxesQuestions]);

  return <SessionWrapper
    progression={gameProgression}
    progressionTo={gameProgressionTo}
    page={gamePage}
    steps={gameSteps}
    chapter={gameChapter}
    background={gameBackground}
    progressBar={gameProgressBar}
    frame={gameFrame}
    resetOnStart={resetOnStart}
    valuesToSave={{
      userAxes,
    }}
    nextPage={toPage}
  />;
}

export default Auditions;
