import { useCallback, useEffect, useState } from "react";

// Providers
import { useGame } from "../../providers/GameProvider";

// Services
import JobsService from "../../services/jobs.service";

// Components
import { VCenter, VTitle, VCard, VButton, VPage, VSpace, VMessenger, VTutor } from "../../components/Vues";
import StarsSwitch from "../StarsSwitch";

export const competencesRatingBase = 5;

const CoachingCompetences = ({ onEnd, isNextLoading }) => {

  // Contexts
  const { configJobsIsLoaded, userJobs, userJobFavorite, coachingCompetencesRating, setCoachingCompetencesRating, mergeWithBook } = useGame();

  // States
  const [isLoading, setIsLoading] = useState(true);
  const [job, setJob] = useState(null);
  const [isAllRated, setIsAllRated] = useState(false);

  // Get favorite job
  useEffect(() => {
    if (configJobsIsLoaded) {
      setJob({
        ...(userJobs[userJobFavorite] || {}),
        ...(JobsService.getJob(userJobFavorite)),
      });
    }
  }, [configJobsIsLoaded, userJobs, userJobFavorite, setJob]);

  // Finish loading state
  useEffect(() => {
    if (job) {
      setIsLoading(false);
      const newRating = {};
      job.skills.forEach((text, index) => {
        newRating[index] = 0;//Math.round(competencesRatingBase / 2);
      });
      setCoachingCompetencesRating(newRating);
    }
  }, [job, setIsLoading, setCoachingCompetencesRating]);

  // check if all are rated
  useEffect(() => {
    if (coachingCompetencesRating) {
      setIsAllRated(Object.values(coachingCompetencesRating).reduce((isRated, star) => isRated && star > 0, true));
    }
  }, [coachingCompetencesRating, setIsAllRated]);

  // Events
  const handleStars = useCallback(index => value => {
    setCoachingCompetencesRating(prevStars => ({
      ...prevStars,
      [index]: value,
    }));
  }, [setCoachingCompetencesRating]);

  const goToNext = useCallback(() => {
    mergeWithBook({
      withCursus: true,
      competencesRating: coachingCompetencesRating,
    });
    setWaitEndQuiz(true);
  }, [coachingCompetencesRating, mergeWithBook]);


  const goToNext2 = useCallback(() => {
    onEnd();
  }, [onEnd]);

  const [waitEndQuiz, setWaitEndQuiz] = useState(false);

  return (

    <VPage>


      <VCenter>

        <VTitle>
          <p className="uppercase">Vers la <span className="font-bold">bonne formation</span></p>
        </VTitle>

        <VMessenger audio="05_02.mp3" >
          Pour exercer ce métier, tu vas devoir maîtriser un certain nombre de compétences. La bonne nouvelle, c’est que je pense que tu en maîtrises déjà certaines !
          Peux-tu m’indiquer ton niveau de maîtrise actuel sur ces différents points ?
        </VMessenger>

        <VSpace />

        <VCard className="p-6 mb-6 !w-full md:!w-[540px] lg:!w-[880px]">

          {!isLoading && job && <VCenter>

            <p className="text-2xl font-bold uppercase">Les compétences nécessaires pour être {job.name}</p>

            <p className="text-xl font-medium">Clique sur les étoiles pour indiquer ton niveau de maîtrise actuel sur les compétences nécessaires à l’exercice du métier que tu as choisi.
              <br />1 étoile : j’ai encore du chemin à parcourir.<br />5 étoiles : je maîtrise parfaitement !</p>

            <div className="text-2xl leading-7 mt-5">
              {job.skills.map((text, i) => (<div key={i} className="flex flex-col md:flex-row gap-2 md:gap-6 my-2 rounded-large md:rounded-full bg-primary text-white p-3">
                <StarsSwitch onChange={handleStars(i)} rating={coachingCompetencesRating[i]} ratingBase={competencesRatingBase} />
                <span className="block md:inline-block">{text}</span>
              </div>))}
            </div>

            <VSpace />

            {!waitEndQuiz && <VButton onPress={goToNext} isLoading={isNextLoading} isDisabled={!isAllRated}>Valider</VButton>}

          </VCenter>}

        </VCard>


        {waitEndQuiz && <>
          <VTutor id="01" />

          <VMessenger audio="05_03.mp3" >
            Merci, c’est déjà super que tu maîtrises partiellement ces différentes compétences !
          </VMessenger>

          <VButton onPress={goToNext2} isLoading={isNextLoading}>continuer</VButton>
        </>}


      </VCenter>


    </VPage>
  );
}

export default CoachingCompetences;
