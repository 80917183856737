import { useEffect } from 'react';

// Providers
import { useGame } from '../../providers/GameProvider';

// Services
import JobsService from '../../services/jobs.service';

// Components
import { Spinner } from "@nextui-org/react";
import UserBook from '../../components/UserBook';

const Book = () => {

  const { book, isBookDisabled, userInfos, avatarId, teamId, awards, userAxes, userJobFavorite, coachingCompetencesRating, cursusLikes, resetBookWithData } = useGame();

  useEffect(() => {
    resetBookWithData({
      infos: userInfos,
      avatar: avatarId,
      team: teamId,
      awards,
      axes: userAxes,
      job: userJobFavorite ? JobsService.getJob(userJobFavorite) : null,
      withCursus: true,
      competencesRating: coachingCompetencesRating,
      cursusLikes, 
    });
  }, [userInfos, avatarId, teamId, awards, userAxes, userJobFavorite, coachingCompetencesRating, cursusLikes, resetBookWithData]);

  return (
    <section className="p-4 bg-gradient-to-r from-ifpass-purple to-ifpass-orange min-h-screen">

      {isBookDisabled || !book
        ? <Spinner label="Connexion en cours..." color="secondary" />
        : <>
          <UserBook book={book} displayPermalink={false} />
        </>}

    </section >
  );

};

export default Book;