import { useState, useEffect, useCallback } from 'react';
import { Outlet } from "react-router-dom";

// Providers
import { useAuth } from '../providers/AuthProvider';
import { useGame } from '../providers/GameProvider';
import { useTheme } from '../providers/ThemeProvider';

// Services
import AssetsService from '../services/assets.service';

// Components
import { ModalContent, Navbar, NavbarContent, NavbarItem, NavbarMenu, NavbarMenuItem, ModalBody, Button, Link, Image, Progress, Avatar, ModalHeader, ModalFooter } from "@nextui-org/react";
import { V2Button, VImage, VModal, VModalConfirm, VSticker } from "../components/Vues";
import { AwardsSlider, AwardFullscreen } from '../components/Awards';
import UserBook from '../components/UserBook';
import UserVideos from '../components/UserVideos';
import './animations.css';

// Icons
import { FaCircleCheck, FaCircle, FaCircleDot, FaIdCard, FaStar, FaRegCirclePlay, FaSquareXmark, FaUniversalAccess, FaTriangleExclamation, /*FaBug, FaBugSlash, */FaBars } from "react-icons/fa6";
import ChaptersService from '../services/chapters.service';

const menuVariants = {
  variants: {
    enter: {
      x: 0,
      width: "100%",
      // height_old: "calc(100vh - var(--navbar-height) - 1px)",
      height: "calc(100vh - 1px)",
      transition: {
        duration: 0.3,
        easings: "easeOut"
      }
    },
    exit: {
      x: -50,
      width: 0,
      // height_old: "calc(100vh - var(--navbar-height) - 1px)",
      height: "calc(100vh - 1px)",
      transition: {
        duration: 0.25,
        easings: "easeIn"
      }
    }
  }
};

const GameLayout = () => {

  // Contexts
  const { authMode, doReloadOnce } = useAuth();
  const { appDebugMode, /*enableDebugMode, */routeTo, userInfos, avatar, progression, maxProgression, book, isBookDisabled, isAwardsDisabled, isVideosDisabled, setNeedsCurrentSessionReload } = useGame();
  const { theme, toggleTheme, hasProgressBar, hasFrame, currentChapter, isButtonBookAnimated, isButtonAwardsAnimated, isButtonVideosAnimated, toggleBookModal, toggleAwardModal, toggleVideosModal, isAwardModalOpen, isBookModalOpen, isVideosModalOpen, toggleMenuEv, isMenuOpenEv, setIsMenuOpenEv } = useTheme();

  const [currentChapterName, setCurrentChapterName] = useState(null);
  const [currentChapterIndex, setCurrentChapterIndex] = useState(0);
  const [isSessionChangeConfirmModalOpen, setIsSessionChangeConfirmModalOpen] = useState(false);
  const [sessionChangeRequested, setSessionChangeRequested] = useState(null);

  // Update chapter info
  useEffect(() => {
    if (currentChapter) {
      const chapter = ChaptersService.getChapter(currentChapter);
      setCurrentChapterName(prevName => chapter !== null ? chapter.name : prevName);
      setCurrentChapterIndex(prevIndex => chapter !== null ? chapter.index : prevIndex);
    }
  }, [currentChapter, setCurrentChapterName, setCurrentChapterIndex]);

  // Force user signin if not already done
  useEffect(() => {
    doReloadOnce();
  }, [doReloadOnce]);

  // Session change
  const onClickSessionChange = useCallback((id) => () => {
    setSessionChangeRequested(ChaptersService.getChapter(id));
    setIsSessionChangeConfirmModalOpen(true);
  }, [setIsSessionChangeConfirmModalOpen, setSessionChangeRequested]);

  const confirmSessionChange = useCallback(() => {
    setIsMenuOpenEv(false);
    if (sessionChangeRequested !== null && sessionChangeRequested.id) {
      setSessionChangeRequested(null);
      if (sessionChangeRequested.id !== currentChapter) {
        routeTo(sessionChangeRequested.url);
      } else {
        setNeedsCurrentSessionReload(true);
      }
    }
  }, [setIsMenuOpenEv, sessionChangeRequested, routeTo, currentChapter, setNeedsCurrentSessionReload]);

  // Debug
  // const toggleDebug = useCallback(() => {
  //   enableDebugMode(prevAppDebugMode => (!prevAppDebugMode));
  // }, [enableDebugMode]);

  return (
    <>

      {hasFrame && <>
        <div className={"fixed z-50 w-24 h-0.5 m-3 bg-foreground"}></div>
        <div className={"fixed z-50 h-10 w-0.5 m-3 bg-foreground"}></div>
        <div className={"fixed z-50 w-24 h-0.5 m-3 right-0 top-0 bg-foreground"}></div>
        <div className={"fixed z-50 h-10 w-0.5 m-3 right-0 top-0 bg-foreground"}></div>
        <div className={"fixed z-50 w-24 h-0.5 m-3 left-0 bottom-12 bg-foreground"}></div>
        <div className={"fixed z-50 h-10 w-0.5 m-3 bg-foreground bottom-12"}></div>
        <div className={"fixed z-50 w-24 h-0.5 m-3 bg-foreground right-0 bottom-12"}></div>
        <div className={"fixed z-50 h-10 w-0.5 m-3 bg-foreground right-0 bottom-12"}></div>
      </>}

      {/* SMARTPHONES */}
      {hasProgressBar && <div className="md:hidden flex justify-center pt-2">
        <VImage src="logo-ifcast.png" width={100} className="z-40" />
      </div >}
      {/* ** */}

      <Navbar maxWidth="full" onMenuOpenChange={setIsMenuOpenEv} isMenuOpen={isMenuOpenEv} className="backdrop-blur-sm bg-background/0 backdrop-saturate-100 data-[menu-open=true]:backdrop-blur-sm"
        classNames={{ base: "", wrapper: "p-0 gap-2 md:gap-4", brand: "", content: "", item: "", menu: "bg-[black]/75 w-full md:max-w-[400px] m-0 p-0 top-0 pt-[120px] md:pt-[74px]", menuItem: "p-0 m-0 " }}>
        <NavbarContent className="pt-3 pl-2">
          {hasFrame && <Button onPress={() => toggleMenuEv()} color='foreground' className="px-2 sm:px-4" startContent={<span className='flex text-camera-red'><FaCircle /></span>}>
            <span className="bg-white text-black font-bold py-1 px-2 uppercase text-lg flex items-center gap-2">
              <span className="text-sm"><FaBars /></span>
              <span className="hidden sm:inline-block">{currentChapterName}</span>
            </span>
          </Button>}
        </NavbarContent>
        <NavbarMenu motionProps={menuVariants} data-theme={theme}>
          {ChaptersService.getChapters().filter(chapter => chapter.displayInMenu).map(chapter => (
            <NavbarMenuItem key={chapter.id} >
              <Button
                color={
                  chapter.index === currentChapterIndex ? "primary" : chapter.index > currentChapterIndex ? "none" : "secondary"
                }
                size={'lg'}
                className="w-full justify-start rounded-none uppercase font-bold text-white text-xl"
                variant={
                  chapter.index === currentChapterIndex ? "shadow" : chapter.index > currentChapterIndex ? "solid" : "flat"
                }
                startContent={
                  chapter.index === currentChapterIndex ? <FaCircleDot /> : chapter.index > currentChapterIndex ? <FaCircle /> : <FaCircleCheck />
                }
                isDisabled={
                  chapter.index > currentChapterIndex
                }
                id={
                  chapter.url
                }
                onPress={onClickSessionChange(chapter.id)}
              >
                {chapter.name}
              </Button>
            </NavbarMenuItem>
          ))}
          <div className="pt-2 pb-4 px-4 text-white bg-white/10 mx-6 rounded-lg">
            <div className="font-medium text-base flex items-center gap-2 text-warning">
              <FaTriangleExclamation />
              <p>Attention</p>
            </div>
            <p className="leading-4 text-base">Tout retour en arrière supprimera tes données précédentes.</p>
          </div>
        </NavbarMenu>

        <NavbarContent justify="center" className="flex-grow px-0">
          {hasProgressBar && <>
            {/* OTHERS DEVICES */}
            <div className="hidden md:block"><VImage src="logo-ifcast.png" width={140} height={50} /></div>
            {/* ** */}
            <Progress
              size="md"
              radius="sm"
              classNames={{
                base: "max-w-[280px] !gap-0",
                track: "drop-shadow-md",
                indicator: "bg-gradient-to-r from-ifpass-purple to-ifpass-orange",
                label: "font-medium text-sm pt-1",
                value: "text-foreground/60",
              }}
              label={<span><span className="sm:hidden">{"PROGRES"}</span><span className="hidden sm:block">{appDebugMode ? `PROGRESSION [${progression}% / ${maxProgression}%]` : 'PROGRESSION'}</span></span>}
              value={progression}
              showValueLabel={true}
            />
            <V2Button isIconOnly={false} className="bg-transparent shadow-none items-center text-transparent hover:text-white px-2 min-w-0 py-2" variant="light" onPress={() => { toggleTheme() }} >
              <FaUniversalAccess className={`text-white ${theme === 'upsize' ? 'text-xl' : 'text-md'}`} />
              <span className={`hidden md:inline-block ${theme === 'upsize' ? 'text-lg' : 'text-md'}`}>{theme === 'upsize' ? 'Réduire' : 'Agrandir'}</span>
            </V2Button>
          </>}
        </NavbarContent>

        <NavbarContent justify="end" className="pr-6">
          {!hasProgressBar && <>
            <Button isIconOnly={false} className="items-center" color="light" variant="solid" onPress={() => { toggleTheme() }} >
              <FaUniversalAccess className={`text-white ${theme === 'upsize' ? 'text-xl' : 'text-md'}`} />
              <span className={`hidden md:inline-block ${theme === 'upsize' ? 'text-lg' : 'text-md'}`}>Accessibilité</span>
            </Button>
          </>}
          {authMode === "web" && <NavbarItem>
            <Link color="foreground" className="font-medium flex items-center gap-2 text-lg sm:text-sm" href="/logout">
              <span className="hidden sm:inline-block">Quitter</span>
              <FaSquareXmark />
            </Link>
          </NavbarItem>}
        </NavbarContent>
      </Navbar >


      <div className="mx-auto max-w-[700px] p-6 font-medium">
        <Outlet />
      </div>

      <footer className="fixed w-full backdrop-blur-sm bg-background/0 h-[50px] bottom-0 z-20">
        {/* SMARTPHONES */}
        <div className="md:hidden w-full flex items-center justify-between gap-4 text-center justify-items-center">
          <div className="flex items-center justify-self-start ml-2 ">
            {avatar && avatar.id > 0 && avatar.src && <Avatar src={AssetsService.getImageUrl(avatar.src)} className="bg-white min-w-[40px]" />}
          </div>
          <V2Button isIconOnly isDisabled={isBookDisabled} className={`items-center ${isButtonBookAnimated ? 'layout-button-is-animated' : ''}`} onPress={() => { toggleBookModal() }}><FaIdCard className="text-ifpass-orange" /></V2Button>
          <V2Button isIconOnly isDisabled={isAwardsDisabled} className={`items-center ${isButtonAwardsAnimated ? 'layout-button-is-animated' : ''}`} onPress={() => { toggleAwardModal() }} ><FaStar className="text-ifpass-yellow" /></V2Button>
          <V2Button isIconOnly isDisabled={isVideosDisabled} className={`items-center ${isButtonVideosAnimated ? 'layout-button-is-animated' : ''}`} onPress={() => { toggleVideosModal() }}><FaRegCirclePlay /></V2Button>
          <div className="flex gap-0 items-center">
            {/* <V2Button isIconOnly className={`bg-transparent p-0 items-center${!appDebugMode ? ' opacity-50' : ''}`} variant="light" onPress={() => { toggleDebug() }} >{!appDebugMode ? <FaBugSlash className="text-white" /> : <FaBug className="text-white" />}</V2Button> */}
            <Image src={AssetsService.getImageUrl('logo-ifpass-white.svg')} className="w-[90px] p-[8px] rounded-none " />
          </div>
        </div>
        {/* ** */}

        {/* OTHERS DEVICES */}
        <div className="hidden md:grid grid-cols-5 gap-2 text-center items-center justify-items-center">
          <div className="flex items-center justify-self-start ml-2 ">
            {avatar && avatar.id > 0 && avatar.src && <Avatar src={AssetsService.getImageUrl(avatar.src)} className="bg-white min-w-[40px]" />}
            <div className="pl-4 font-medium leading-4">{userInfos.firstName}</div>
          </div>
          <V2Button isDisabled={isBookDisabled} className={isButtonBookAnimated ? 'layout-button-is-animated' : ''} onPress={() => { toggleBookModal() }} startContent={<FaIdCard className="text-ifpass-orange " />} >BOOK</V2Button>
          <V2Button isDisabled={isAwardsDisabled} className={isButtonAwardsAnimated ? 'layout-button-is-animated' : ''} onPress={() => { toggleAwardModal() }} startContent={<FaStar className="text-ifpass-yellow" />} >SUCCÈS</V2Button>
          <V2Button isDisabled={isVideosDisabled} className={isButtonVideosAnimated ? 'layout-button-is-animated' : ''} onPress={() => { toggleVideosModal() }} startContent={<FaRegCirclePlay />} >VIDÉOS</V2Button>
          <div className="justify-self-end flex gap-2 items-center">
            {/* <V2Button isIconOnly className={`bg-transparent items-center${!appDebugMode ? ' opacity-50' : ''}`} variant="light" onPress={() => { toggleDebug() }} >{!appDebugMode ? <FaBugSlash className="text-white" /> : <FaBug className="text-white" />}</V2Button> */}
            <Image src={AssetsService.getImageUrl('logo-ifpass-white.svg')} className="w-[90px] p-[8px] rounded-none " />
          </div>
        </div>
        {/* ** */}

      </footer >

      {/* // ///////////////// AWARDS */}

      <AwardFullscreen />

      <VModal isOpen={isAwardModalOpen} size='2xl' onClose={toggleAwardModal} isDismissable={false} backdrop={'blur'}
        classNames={{
          body: "bg-transparent p-0 shadow-none overflow-auto",
          backdrop: "bg-[#000000]/70",
          base: "bg-transparent p-0 shadow-none",
          header: "border-b-[1px] border-[#292f46]",
          footer: "bg-[#000000] ",
          closeButton: "hidden",
        }}
      >
        <ModalContent>
          <ModalBody>

            <div className="py-8 shadow-none">
              <div
                className="py-4 px-9 bg-black border-[#292f46] text-[#ffffff] bg-[#000000]/70 overflow-visible rounded-large"
                style={{ backgroundImage: `url('${AssetsService.getImageUrl('awards/confetti.png')}')` }}
              >
                <div className="text-center items-center flex flex-col flex-nowrap h-[100%] justify-center">

                  <VSticker><p className="bg-yellow-500 px-4 -mt-8 rounded-lg">MES SUCCÈS</p></VSticker>

                  {/* <p className="text-2xl text-center text-[#ffffff] pt-4 ">SOFT SKILLS</p>
                  <p className="text-normal text-center text-[#ffffff] pb-4 ">Très recherchées par les employeurs, les soft skills sont les capacités sociales, émotionnelles, de réflexion et d’apprentissage qui permettent à l’individu de s’adapter dans différentes situations.</p> */}

                  <AwardsSlider />

                  <Button color="primary" radius="full" onPress={() => { toggleAwardModal() }} className="my-4 -mb-8 text-white shadow-lg px-7 h-9 font-medium text-md">
                    FERMER
                  </Button>

                </div>
              </div>
            </div>

          </ModalBody>
        </ModalContent>
      </VModal>

      {/* // ///////////////// BOOK */}

      <VModal isOpen={isBookModalOpen} size='full' onClose={toggleBookModal} isDismissable={false} backdrop={'blur'}
        classNames={{
          body: "bg-none overflow-auto",
          backdrop: "bg-[#000000]/70",
          base: "bg-gradient-to-r from-[#5421a5] to-[#ff5948] min-h-screen text-[#ffffff]",
          header: "border-b-[1px] border-[#292f46]",
          footer: "bg-[#000000] ",
          closeButton: "hidden",
        }}
      >
        <ModalContent>
          <ModalBody>

            <div className="text-center md:flex md:flex-col md:flex-nowrap md:items-center justify-center py-0 md:py-4">

              <UserBook book={book} displayPermalink={true} />

              <Button color="primary" radius="full" onPress={() => { toggleBookModal() }} className="my-4  text-white shadow-lg px-7 h-9 font-medium text-md">
                FERMER
              </Button>

            </div>

          </ModalBody>
        </ModalContent>
      </VModal>

      {/* // ///////////////// VIDEOS */}

      <VModal isOpen={isVideosModalOpen} size='4xl' onClose={toggleVideosModal} isDismissable={false} backdrop={'blur'}
        classNames={{
          body: "bg-transparent p-0 shadow-none",
          backdrop: "bg-[#000000]/70",
          base: "bg-transparent p-0 shadow-none",
          header: "border-b-[1px] border-[#292f46]",
          footer: "bg-[#000000] ",
          closeButton: "hidden",
        }}
      >
        <ModalContent>
          <ModalBody>

            <div className="py-8 shadow-none">
              <div className="py-4 px-5 border-[#292f46] text-[#ffffff] bg-[#000000]/70 overflow-visible rounded-large">
                <div className="text-center items-center flex flex-col flex-nowrap h-[100%] justify-center pt-10 md:pt-0">

                  <VSticker><p className="bg-yellow-500 px-4 -mt-8 rounded-lg">VIDÉOS</p></VSticker>

                  <UserVideos />

                  <Button color="primary" radius="full" onPress={() => { toggleVideosModal() }} className="my-4 -mb-8 text-white shadow-lg px-7 h-9 font-medium text-md">
                    FERMER
                  </Button>

                </div>
              </div>
            </div>

          </ModalBody>
        </ModalContent>
      </VModal>

      <VModalConfirm isOpen={isSessionChangeConfirmModalOpen && sessionChangeRequested !== null} size='md'>
        <ModalContent>
          {(sessionChangeRequested !== null && sessionChangeRequested.name) && <ModalHeader className="flex flex-col gap-1 text-2xl leading-7 font-semibold">Es-tu sûr de vouloir revenir au chapitre "{sessionChangeRequested.name}" ?</ModalHeader>}
          <ModalBody>
            <div className="font-medium text-md flex items-center text-center flex-col gap-2">
              <div className="font-medium text-base flex items-center gap-2 text-warning">
                <div className="text-2xl"><FaTriangleExclamation /></div>
                <p className="m-0 text-warning">Attention</p>
              </div>
              <p className="m-0">Tout retour en arrière supprimera vos données précédentes.</p>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="light" variant="light" className='text-xl font-medium' onPress={() => setIsSessionChangeConfirmModalOpen(false)}>Non</Button>
            <Button color="primary" className='text-xl font-medium' onPress={confirmSessionChange}>Oui</Button>
          </ModalFooter>
        </ModalContent>
      </VModalConfirm>

    </ >
  )
};

export default GameLayout;