import PersonalitiesService from "./personalities.service";
import ProfilesService from "./profiles.service";

const AxesService = {
  sort: (userAxes, max) => {
    const sortProperty = 'score';
    const sortedAxes = Object.values(userAxes).sort((a, b) => b[sortProperty] - a[sortProperty]);
    return max ? sortedAxes.slice(0, max) : sortedAxes;
  },
  compute: (userAxes, profilesInfos) => {
    const axes = {};
    ProfilesService.getProfiles().forEach(profile => {
      axes[profile.id] = {
        id: profile.id,
        label: profile.name,
        score: userAxes[profile.id] ? (userAxes[profile.id].score || 0) : 0,
        image: profile.image,
        qualities: userAxes[profile.id] ? (userAxes[profile.id].qualities) : {},
      };
      if (profilesInfos) {
        axes[profile.id] = {
          ...axes[profile.id],
          ...profilesInfos[profile.id],
        };
      }
    });
    return axes;
  },
  getRandomId: (profile, max) => `${profile.id}-${(`00${Math.floor(Math.random() * max) + 1}`).slice(-2)}`,
  generateQuestions: () => {
    const max = PersonalitiesService.perProfileCount;
    const questions = [];
    // const already = {};
    const personalitiesById = PersonalitiesService.getPersonalitiesKeyed();
    // const personalitiesIds = Object.keys(personalitiesById);
    const profiles = ProfilesService.getProfiles();
    const answersPerProfile = {};
    profiles.forEach((profile) => {
      answersPerProfile[profile.id] = [];
      for (let index = 1; index <= max; index++) {
        answersPerProfile[profile.id].push(personalitiesById[`${profile.id}-${(`00${index}`).slice(-2)}`]);
      }
      answersPerProfile[profile.id].sort(() => 0.5 - Math.random());
    });
    for (let index = 0; index < max; index++) {
      const question = {
        index,
        label: `Question ${index + 1}`,
        answers: [],
      };
      profiles.forEach((profile) => {
        question.answers.push(answersPerProfile[profile.id][index]);
      });
      question.answers.sort(() => 0.5 - Math.random()); // random (-0.5 <> 0.5)
      questions.push(question);
    }
    // questions.forEach(question => console.log(`${question.label} : ${question.answers.map(answer => answer.id).join(' / ')}`));
    // console.log(answersPerProfile);
    // console.log(questions[0].answers[0].id, Object.keys(already).includes(questions[0].answers[0].id), already);
    return questions;
  },
};

export default AxesService;