import { useCallback, useEffect, useRef, useState } from "react";

// Providers
import { useGame } from "../../providers/GameProvider";
import { useThemeToast } from "../../providers/ThemeProvider";

// Services
import JobsService from "../../services/jobs.service";
import AssetsService from "../../services/assets.service";

// Components
import { VCenter, VTitle, VCard, VButton, VPage, VSpace, VMessenger, VLoading } from "../../components/Vues";
import { RadioGroup } from "@nextui-org/react";
import { RadioText } from "../RadioText";
import ToastMessage from "../ToastMessage";

// Icons
import { FaRegThumbsUp } from "react-icons/fa6";

const ReperagesChoose = ({ onEnd, isNextLoading }) => {

  // Contexts
  const { configJobsIsLoaded, appDebugMode, userJobs, setUserJobs, addAward, userJobFavorite, setUserJobFavorite } = useGame();

  // Toasts
  const showToast = useThemeToast(<ToastMessage image="01"><p>Tu hésites encore ?<br />Cliques sur « en savoir + » pour consulter les fiches métiers.</p></ToastMessage>);

  // States
  const [isLoading, setIsLoading] = useState(false);
  const [jobs, setJobs] = useState([]);

  // init current step
  useEffect(() => {
    if (configJobsIsLoaded && userJobs) {
      setJobs(JobsService.populate(JobsService.sort(userJobs, 8)));
      setIsLoading(false);
    }
  }, [configJobsIsLoaded, userJobs, setJobs, setIsLoading]);

  // change favorite
  const onJobChange = useCallback(jobId => {
    setUserJobs(prevUserJobs => {
      const jobs = { ...prevUserJobs };
      Object.keys(jobs).forEach(jobId2 => {
        jobs[jobId2] = {
          ...jobs[jobId2],
          favorite: jobId2 === jobId,
        };
      });
      return jobs;
    });
  }, [setUserJobs]);

  // determine favorite
  useEffect(() => {
    setUserJobFavorite(() => {
      let favorite = null;
      jobs.forEach(job => {
        favorite = job.favorite ? job.id : favorite;
      });
      // console.log('job favorite', favorite);
      return favorite;
    });
  }, [jobs, setUserJobFavorite]);

  // show toast
  const timerTooLong = useRef(null);
  useEffect(() => {
    if (timerTooLong && timerTooLong.current) {
      clearTimeout(timerTooLong.current);
    }
    timerTooLong.current = setTimeout(() => {
      showToast();
    }, 5000);
    return () => {
      clearTimeout(timerTooLong.current);
    };
  }, [showToast]);

  const goToNext = useCallback(() => {
    addAward('responsabilites', true);
    onEnd();
  }, [addAward, onEnd]);

  return (

    <VPage>

      <VCenter>
        <VTitle>
          <p>C'EST LE MOMENT <span className="font-bold">DE CHOISIR !</span></p>
        </VTitle>

        <VMessenger audio="04_05.mp3" >
          Alors c’est le moment de faire ton choix ! Parmi ces métiers, lequel semble être fait pour toi ? Quel rôle t’intéresse le plus ?
        </VMessenger>

        <VCard className="p-6 mb-6 font-bold !w-full md:!w-[540px] lg:!w-[680px]">

          <VCenter>

            {!isLoading && jobs ? <>
              <p className="text-2xl mb-4">Parmi ces métiers, lequel semble être fait pour toi ?</p>

              <RadioGroup onValueChange={onJobChange} value={userJobFavorite}>
                {jobs.map((job) => (
                  <RadioText key={job.id} value={job.id} isBig={true}>
                    <div className='flex justify-between items-center gap-4 w-full'>
                      <div className='grow flex items-center gap-2 text-left'>
                        <div className={job.liked ? 'text-primary' : 'opacity-0'}>
                          <FaRegThumbsUp />
                        </div>
                        <div>
                          <div className="uppercase text-primary">{appDebugMode ? `${job.id} - ` : ''}{job.name}</div>
                          <div className="text-left text-black font-medium text-sm">{Math.round(job.score * 100)}% d'affinité</div>
                        </div>
                      </div>
                      <VButton className="normal-case" onPress={() => window.open(AssetsService.getUrl(`docs/jobs/${job.infos.link}`), '_blank')}>En savoir +</VButton>

                    </div>
                  </RadioText>
                ))}
              </RadioGroup>

              <VSpace />

              <VButton onPress={goToNext} isLoading={isNextLoading} isDisabled={!userJobFavorite}>valider</VButton>
            </> : <VLoading label="CHARGEMENT" />}

          </VCenter>

        </VCard>

      </VCenter>

    </VPage>
  );
}

export default ReperagesChoose;