import { useCallback, useEffect, useRef, useState } from "react";

// Providers
import { useGame } from '../../providers/GameProvider';

// Components
import { RadioGroup } from "@nextui-org/react";
import { VCenter, VTitle, VCard, VButton, VPage, VMessenger, VImage, VSpace, VSticker, VAnswerIcons } from "../../components/Vues";
import RadioQuizImage from "../RadioQuizImage";

// Icons
import { FaRegStar, FaStar } from "react-icons/fa6";

// -- Swiper
import { A11y, EffectCreative } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-creative';

const BriefConnaissances = ({ onEnd, isNextLoading }) => {

  // Contexts
  const { connaissancesScore, setConnaissancesScore, addAward } = useGame();

  // States
  const [solutionVisible, setSolutionVisible] = useState(false);
  const [Q1, setQ1] = useState(0);
  const [Q2, setQ2] = useState(0);
  const [Q3, setQ3] = useState(0);

  // Refs
  const swiperElRef = useRef(null);

  // Skip if no results
  useEffect(() => {
    if (connaissancesScore === -1) {
      onEnd();
    }
  }, [onEnd, connaissancesScore]);

  // Results
  const isQ1Valid = useCallback(() => parseInt(Q1, 10) === 1, [Q1]);
  const isQ2Valid = useCallback(() => parseInt(Q2, 10) === 1, [Q2]);
  const isQ3Valid = useCallback(() => parseInt(Q3, 10) === 2, [Q3]);

  // Events
  const handleClick = useCallback(() => {
    let score = (isQ1Valid() ? 1 : 0) + (isQ2Valid() ? 1 : 0) + (isQ3Valid() ? 1 : 0);
    setConnaissancesScore(score);
    setSolutionVisible(true);
  }, [isQ1Valid, isQ2Valid, isQ3Valid, setConnaissancesScore, setSolutionVisible]);

  const swipeNextSlide = useCallback(() => {
    setSolutionVisible(false);
    swiperElRef.current.swiper.slideNext();
  }, [setSolutionVisible]);

  const skipToNext = useCallback(() => {
    setConnaissancesScore(-1);
  }, [setConnaissancesScore]);

  const goToNextWithAward = useCallback(() => {
    addAward('curiosite', true);
    onEnd();
  }, [onEnd, addAward]);

  return (

    <VPage>

      <VCenter>

        <VTitle>
          <span className="font-bold">T'ASSURES ?</span>
        </VTitle>

        <VMessenger audio="02_03.mp3" autoPlay={true} >
          Pas mal ! T’assures ! Allez, on commence par un petit quiz sur le monde de l’assurance !
        </VMessenger>

        <p className="text-2xl font-medium">Quelle connaissance as-tu du monde de l’assurance ?</p>
        <p className="">Clique sur la bonne réponse</p>

        <VSpace />

        <VCard className="w-full block">

          <Swiper
            ref={swiperElRef}
            modules={[A11y, EffectCreative]}
            allowTouchMove={false}
            simulateTouch={false}
            spaceBetween={50}
            slidesPerView={1}
            pagination={{ clickable: false }}
            effect={'creative'}
            speed={500}
            followFinger={true}
            creativeEffect={{
              prev: {
                shadow: false,
                translate: ['-100%', 0, -1],
              },
              next: {
                translate: ['100%', 0, 0],
              },
            }}
            className="min-h-96 !z-0"
          >

            {/* ///////////////////////////////////////////// */}

            <SwiperSlide>

              <VCenter>
                <VSticker className="w-40 pb-6">Question 1/3</VSticker>
              </VCenter>

              <p className="font-medium">Quel événement a donné lieu à la création de la première assurance ?</p>
              <VSpace className="my-4" />

              <RadioGroup
                orientation="horizontal"
                onValueChange={setQ1}
                isDisabled={solutionVisible}
                value={Q1}
                classNames={{
                  wrapper: "flex gap-2 w-full justify-center",
                }}
              >

                <RadioQuizImage isGood={isQ1Valid()} hasSolution={solutionVisible} description="Le grand incendie de Londres (1666)" value={1} classNames={{ labelWrapper: "!w-[120px] md:!w-[180px] m-0 " }}>
                  <VImage src="quiz/Q01_01a.png" radius="none" />
                  <VAnswerIcons visible={solutionVisible} correct={true} />
                </RadioQuizImage>

                <RadioQuizImage isBad={!isQ1Valid()} hasSolution={solutionVisible} description="Le naufrage du Titanic (1912)" value={2} classNames={{ labelWrapper: "!w-[120px] md:!w-[180px] m-0 " }}>
                  <VImage src="quiz/Q01_01b.png" radius="none" />
                  <VAnswerIcons visible={solutionVisible} correct={false} />
                </RadioQuizImage>

                <RadioQuizImage isBad={!isQ1Valid()} hasSolution={solutionVisible} description="Le chantier de construction de la tour Eiffel (1887)" value={3} classNames={{ labelWrapper: "!w-[120px] md:!w-[180px] m-0 " }}>
                  <VImage src="quiz/Q01_01c.png" radius="none" />
                  <VAnswerIcons visible={solutionVisible} correct={false} />
                </RadioQuizImage>

              </RadioGroup>

              <VSpace className="pt-6 mb-12">
                {solutionVisible && <p className='pb-6 px-4'>Le grand incendie de Londres a détruit plus de 13 000 maisons, 87 églises et une cathédrale !<br />Tout ça parce qu’un boulanger a oublié d’éteindre son four !</p>}
                {!solutionVisible && <VButton onPress={handleClick} isDisabled={!(Q1 > 0)} >Valider</VButton>}
                {solutionVisible && <VButton onPress={swipeNextSlide}>Question suivante</VButton>}
              </VSpace>

            </SwiperSlide>

            {/* ///////////////////////////////////////////// */}

            <SwiperSlide>

              <VCenter>
                <VSticker className="w-40 pb-6">Question 2/3</VSticker>
              </VCenter>

              <p className="font-medium">À ton avis, peut-on s’assurer contre l’enlèvement par des extraterrestres ? </p>
              <VSpace className="my-4" />

              <RadioGroup
                orientation="horizontal"
                onValueChange={setQ2}
                isDisabled={solutionVisible}
                value={Q2}
                classNames={{
                  wrapper: "flex gap-2 w-full justify-center",
                }}
              >

                <RadioQuizImage isGood={isQ2Valid()} hasSolution={solutionVisible} description="Oui" value={1} classNames={{ labelWrapper: "!w-[120px] md:!w-[180px] m-0 " }}>
                  <VImage src="quiz/Q01_02a.png" radius="none" />
                  <VAnswerIcons visible={solutionVisible} correct={true} />
                </RadioQuizImage>

                <RadioQuizImage isBad={!isQ2Valid()} hasSolution={solutionVisible} description="Non" value={2} classNames={{ labelWrapper: "!w-[120px] md:!w-[180px] m-0 " }}>
                  <VImage src="quiz/Q01_02b.png" radius="none" />
                  <VAnswerIcons visible={solutionVisible} correct={false} />
                </RadioQuizImage>

              </RadioGroup>

              <VSpace className="pt-6 mb-12">
                {solutionVisible && <p className='pb-6 px-4'>Tous les risques incertains s’assurent. Aux Etats-Unis, une compagnie d’assurance verse même jusqu’à 10 millions de dollars dans ce cas… à la seule condition que la victime revienne et fournisse la preuve de sa captivité !
                  L’indemnisation est plus importante lorsque l’assuré subit une grossesse non désirée provoquée par des extraterrestres.</p>}
                {!solutionVisible && <VButton onPress={handleClick} isDisabled={!(Q2 > 0)} >Valider</VButton>}
                {solutionVisible && <VButton onPress={swipeNextSlide}>Question suivante</VButton>}
              </VSpace>

            </SwiperSlide>

            {/* ///////////////////////////////////////////// */}

            <SwiperSlide>

              <VCenter>
                <VSticker className="w-40 pb-6">Question 3/3</VSticker>
              </VCenter>

              <p className="font-medium">En France, il est obligatoire d’assurer son véhicule. Selon toi, combien d’automobilistes roulent néanmoins sans assurance ? </p>
              <VSpace className="my-4" />

              <RadioGroup
                orientation="horizontal"
                onValueChange={setQ3}
                isDisabled={solutionVisible}
                value={Q3}
                classNames={{
                  wrapper: "flex gap-2 w-full justify-center",
                }}
              >

                <RadioQuizImage isBad={!isQ3Valid()} hasSolution={solutionVisible} description="100 000" value={1} classNames={{ labelWrapper: "!w-[120px] md:!w-[180px] m-0 " }}>
                  <VImage src="quiz/Q01_03a.jpg" radius="none" />
                  <VAnswerIcons visible={solutionVisible} correct={false} />
                </RadioQuizImage>

                <RadioQuizImage isGood={isQ3Valid()} hasSolution={solutionVisible} description="800 000" value={2} classNames={{ labelWrapper: "!w-[120px] md:!w-[180px] m-0 " }}>
                  <VImage src="quiz/Q01_03b.jpg" radius="none" />
                  <VAnswerIcons visible={solutionVisible} correct={true} />
                </RadioQuizImage>

                <RadioQuizImage isBad={!isQ3Valid()} hasSolution={solutionVisible} description="2 000 000" value={3} classNames={{ labelWrapper: "!w-[120px] md:!w-[180px] m-0 " }}>
                  <VImage src="quiz/Q01_03c.jpg" radius="none" />
                  <VAnswerIcons visible={solutionVisible} correct={false} />
                </RadioQuizImage>

              </RadioGroup>

              <VSpace className="pt-6 mb-6">
                {solutionVisible && <p className='pb-6 px-4'>800 000, c’est un chiffre énorme compte tenu des risques encourus. L’assurance est obligatoire pour protéger les victimes en cas d’accident.</p>}
                {!solutionVisible && <VButton onPress={handleClick} isDisabled={!(Q3 > 0)} >Valider</VButton>}
                {/* {solutionVisible && <VButton onPress={goToNextWithAward} isLoading={isNextLoading}>Continuer</VButton>} */}
              </VSpace>

            </SwiperSlide>
          </Swiper>

        </VCard>

        {
          !((Q3 > 0) && solutionVisible) &&
          <VSpace className="mt-8 mb-8" >
            <VButton onPress={skipToNext} isDisabled={(Q3 > 0) && solutionVisible}>passer le quiz</VButton>
          </VSpace>
        }

        {
          ((Q3 > 0) && solutionVisible) &&
          <>
            <VMessenger audio="02_04.mp3">
              Certaines réponses sont surprenantes n’est-ce pas ?
            </VMessenger>

            <VSticker>Ton score</VSticker>

            <VCard className="py-6">
              <VSpace className="my-4" >

                <VCenter>
                  <div className="flex">
                    {connaissancesScore > 0 ? <FaStar className="w-10 h-10 text-ifpass-yellow " /> : <FaRegStar className="w-10 h-10 text-ifpass-yellow " />}
                    {connaissancesScore > 1 ? <FaStar className="w-10 h-10 text-ifpass-yellow " /> : <FaRegStar className="w-10 h-10 text-ifpass-yellow " />}
                    {connaissancesScore > 2 ? <FaStar className="w-10 h-10 text-ifpass-yellow " /> : <FaRegStar className="w-10 h-10 text-ifpass-yellow " />}
                  </div>
                </VCenter>

                <p className="font-bold text-4xl">{String(connaissancesScore)}/3</p>
                <p className="font-medium">Certaines réponses sont surprenantes, non ?</p>

              </VSpace>
            </VCard>

            <VSpace className="my-4" />
            <VButton onPress={goToNextWithAward} isLoading={isNextLoading}>continuer</VButton>
          </>
        }

      </VCenter>

    </VPage>
  );
}

export default BriefConnaissances;