import { BrowserRouter, Routes, Route } from "react-router-dom";

// Providers
import { GameProvider } from './providers/GameProvider';
import { ThemeProvider } from './providers/ThemeProvider';
import { AuthProvider, ProtectedRoute } from "./providers/AuthProvider";

// Services
import AssetsService from "./services/assets.service";

//Layouts
import AppLayout from './layouts/AppLayout'
import MainLayout from "./layouts/MainLayout";
import GameLayout from "./layouts/GameLayout";

// Pages -> Main
import Home from "./pages/main/Home";
import Error from "./pages/main/Error";
import Book from "./pages/main/Book";

// Pages -> Auth
import Logout from "./pages/auth/Logout";
import Auth from "./pages/auth/Auth";

// Pages -> Sessions
import Accueil from "./pages/session/Accueil";
import Brief from "./pages/session/Brief";
import Auditions from "./pages/session/Auditions";
import Reperages from "./pages/session/Reperages";
import Coaching from "./pages/session/Coaching";
import Debriefing from "./pages/session/Debriefing";
import FinDuJeu from "./pages/session/FinDuJeu";
import Index from "./pages/session/Index";


function App() {
  return (
    <BrowserRouter basename={AssetsService.routerBase}>
      <AuthProvider>
        <ThemeProvider>
          <GameProvider>
            <AppLayout>
              <Routes>

                <Route path="/game" element={<ProtectedRoute><GameLayout /></ProtectedRoute>}>

                  <Route index element={<Index />} />
                  <Route path="/game/accueil" element={<Accueil />} />
                  <Route path="/game/brief" element={<Brief />} />
                  <Route path="/game/auditions" element={<Auditions />} />
                  <Route path="/game/reperages" element={<Reperages />} />
                  <Route path="/game/coaching" element={<Coaching />} />
                  <Route path="/game/debriefing" element={<Debriefing />} />
                  <Route path="/game/findujeu" element={<FinDuJeu />} />

                </Route>

                <Route path="/book" element={<Book />} />
                <Route path="/book/:token" element={<Auth onSuccessNavigate='/book' />} />

                <Route path="/" element={<MainLayout />}>
                  <Route index element={<Home />} />
                  <Route path="/logout" element={<Logout />} />

                  <Route path="/:token" element={<Auth authMode="web" onSuccessNavigate='/game' />} />
                  <Route path="/scorm/:token" element={<Auth authMode="scorm" onSuccessNavigate='/game' />} />

                  <Route path="/error/403" element={<Error type="403" />} />
                  <Route path="*" element={<Error type="404" />} />
                </Route>

              </Routes>
            </AppLayout>
          </GameProvider>
        </ThemeProvider>
      </AuthProvider>
    </BrowserRouter>

  );
};
export default App;