import { useCallback, useEffect, useState } from "react";

// Providers
import { useGame } from "../../providers/GameProvider";

// Components
import { VCenter, VImage, VTitle, VSticker, VCard, VButton, VPage, VSpace, VMessenger, VModalConfirm } from "../../components/Vues";
import { Button, ModalBody, ModalContent, ModalFooter, ModalHeader } from "@nextui-org/react";
import { FaArrowRight, FaClapperboard } from "react-icons/fa6";

// Services
import JobsService from "../../services/jobs.service";
import TeamsService from "../../services/teams.service";

const ReperagesEnd = ({ onEnd, isNextLoading }) => {

  // Contexts
  const { configJobsIsLoaded, team, changeTeam, userJobs, userJobFavorite, mergeWithBook } = useGame();

  // States
  const [job, setJob] = useState(null);
  const [isTeamModalOpen, setIsTeamModalOpen] = useState(false);
  const [teamChanges, setTeamChanges] = useState({ from: null, to: null });

  // Get favorite job
  useEffect(() => {
    if (configJobsIsLoaded) {
      setJob({
        ...(userJobs[userJobFavorite] || {}),
        ...(JobsService.getJob(userJobFavorite)),
      });
    }
  }, [configJobsIsLoaded, userJobs, userJobFavorite, setJob]);

  // Need team change ?
  useEffect(() => {
    if (job) {
      setIsTeamModalOpen(job.team !== (team ? team.id : null));
    }
  }, [team, job, setIsTeamModalOpen]);

  // Team changes
  useEffect(() => {
    if (job && team) {
      setTeamChanges({
        from: TeamsService.getTeam(team.id),
        to: TeamsService.getTeam(job.team),
      });
    }
  }, [team, job, setTeamChanges]);

  // Change team
  const validateTeamChange = useCallback(() => {
    if (job) {
      // console.log('change team', job.team);
      changeTeam(job.team);
      setIsTeamModalOpen(false);
    }
  }, [job, changeTeam]);

  // Update book
  useEffect(() => {
    if (job && team) {
      mergeWithBook({
        team: team.id,
        job,
      });
    }
  }, [job, team, mergeWithBook]);

  const goToNext = useCallback(() => {
    onEnd();
  }, [onEnd]);

  return (

    <VPage>

      <VCenter>
        <VTitle>
          <p><span className="font-bold">BRAVO !</span></p>
        </VTitle>

        <VMessenger audio="04_06.mp3" >
          Quelle bonne idée ! Je suis sûr que ce métier te conviendra parfaitement. Tu as donc franchi l’étape du repérage haut la main !
        </VMessenger>

        <VCard className="mb-0">
          <VCenter>
            <VImage src="tutor/11.png" alt="Bravo" className="w-[300px]" />
            <div className="relative top-[-80px]">
              <VSticker>Étape terminée !</VSticker>
            </div>
            {job && <>
              <p className="font-semibold text-2xl">Ton métier</p>
              <p className="font-medium text-3xl mt-1 mb-4">{job.name}</p>
            </>}
          </VCenter>
        </VCard>

        <VSpace className="my-8">
          <VButton onPress={goToNext} isLoading={isNextLoading} endContent={<div className="flex gap-2"><FaArrowRight /><FaClapperboard /></div>}>Passer au coaching</VButton>
        </VSpace>

      </VCenter >

      <VModalConfirm isOpen={isTeamModalOpen && teamChanges.from && teamChanges.to} size='md'>
        <ModalContent>
          <ModalHeader className="flex flex-col gap-1 text-2xl leading-7 font-semibold">On change d'équipe ?</ModalHeader>
          <ModalBody>
            <p className="text-3xl text-center">A priori, tu vas devoir changer d’équipe ! C’est une bonne nouvelle, tu as toutes tes chances&nbsp;!</p>
            <div className="flex justify-center items-center gap-4 mt-4">
              <div className="font-medium text-2xl text-center flex flex-col items-center">
                <VImage src={teamChanges.from && teamChanges.from.src ? teamChanges.from.src : 'teams/00.png'} width={140} radius="none" className="overflow-hidden border-2 border-default !rounded-full w-[100px] bg-white z-10" />
                <VSticker position="-top-6" className="text-sm px-1 z-20">{teamChanges.from && teamChanges.from.name ? teamChanges.from.name : 'Inconnu'}</VSticker>
              </div>
              <span className="font-medium">vers</span>
              <div className="font-medium text-2xl text-center flex flex-col items-center">
                <VImage src={teamChanges.to && teamChanges.to.src ? teamChanges.to.src : 'teams/00.png'} width={140} radius="none" className="overflow-hidden border-2 border-default !rounded-full w-[100px] bg-white z-10" />
                <VSticker position="-top-6" className="text-sm px-1 z-20">{teamChanges.to && teamChanges.to.name ? teamChanges.to.name : 'Inconnu'}</VSticker>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" className='text-md font-medium uppercase' onPress={validateTeamChange}>Continuer</Button>
          </ModalFooter>
        </ModalContent>
      </VModalConfirm>

    </VPage>
  );
}

export default ReperagesEnd;