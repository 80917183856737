import { useRadio, cn, VisuallyHidden } from "@nextui-org/react";

const RadioImage = (props) => {
  const {
    Component,
    children,
    description,
    getBaseProps,
    // getWrapperProps,
    getInputProps,
    getLabelProps,
    getLabelWrapperProps,
    // getControlProps,
  } = useRadio(props);

  return (
    <Component
      {...getBaseProps()}
      className={cn(
        "overflow-hidden hover:opacity-70 active:opacity-50 tap-highlight-danger",
        "cursor-pointer border-2 border-default rounded-lg !rounded-full ",
        "data-[selected=true]:border-ifpass-orange",
        "max-w-fit bg-white",
      )}
    >
      <VisuallyHidden>
        <input {...getInputProps()} />
      </VisuallyHidden>

      <div {...getLabelWrapperProps()} className={cn(
        "flex flex-col ml-0",
      )}>
        {children && <h1 {...getLabelProps()}  >
          {children}
          {description && (
            <div className="text-black p-2 absolute bottom-2 w-full z-10">{description}</div>
          )}
        </h1>}
      </div>

    </Component >
  );
};
export default RadioImage;