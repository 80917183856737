import { useCallback, useRef, useState, useEffect } from 'react';

// Providers
import { useGame } from '../providers/GameProvider';
import { useTheme } from '../providers/ThemeProvider';

// Services
import AssetsService from '../services/assets.service';
import AwardsService from '../services/awards.service';

// Components
import { ModalBody, ModalContent } from "@nextui-org/react";
import { VCenter, VButton, VImage, VMessenger, VLoading, VVideo, VModal } from "./Vues";
import Background from './Background';

// -- Swiper
import { Pagination, A11y, Navigation, EffectCards } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-cards';

const awardsInfos = {
  /* accueil: {
     description: <>Très recherchées par les employeurs, les soft skills sont les capacités sociales, émotionnelles, de réflexion et d’apprentissage qui permettent à l’individu de s’adapter dans différentes situations.</>,
     image: 'awards/SensDeLaCommunication.gif',
     imageSize: { w: 'auto', h: 'auto' },
     sliderImage: 'awards/SensDeLaCommunication.gif',
     sliderImageSize: { w: 260, h: 'auto' },
     extraClassNames: '',
     weight: 10,
     audio: {
       file: '02_05.mp3',
       description: <>Quel que soit ton score, le fait que tu ais répondu à ces questions de culture générale révèle quelque chose de ta personnalité : tu es quelqu’un de curieux ! Et la bonne nouvelle, c’est qu’il s’agit d’une qualité recherchée dans le monde de l’assurance !
         <br />D’ailleurs, je suis sûr que tu as d’autres talents cachés qui peuvent s’avérer très intéressants pour trouver ta voie … Prêt à en savoir plus ? </>,
     },
   },*/
  curiosite: {
    description: <>La curiosité est loin d’être un vilain défaut ! C’est une qualité largement recherchée par les employeurs. Dans l’assurance, un salarié curieux, c’est par exemple un salarié qui va rechercher et explorer de nouvelles informations.</>,
    image: 'awards/Curiosite.gif',
    imageSize: { w: 'auto', h: 'auto' },
    sliderImage: 'awards/Curiosite.gif',
    sliderImageSize: { w: 210, h: 200 },
    extraClassNames: '',
    weight: 9,
    audio: null,
  },
  rigueur: {
    description: <>Vous n'êtes pas une machine!  Pourtant l'exactitude, la précision et la régularité demeurent des qualités largement plébiscitées par les employeurs. Dans l'assurance, c'est une des premières qualités recherchées dans les offres d'emploi. Et pour cause, la rigueur garantie la qualité des résultats et favorise donc  la culture de l'excellence et la confiance client nécessaires.</>,
    image: 'awards/Rigueur.gif',
    imageSize: { w: 'auto', h: 'auto' },
    sliderImage: 'awards/Rigueur.gif',
    sliderImageSize: { w: 210, h: 150 },
    extraClassNames: '',
    weight: 8,
    audio: null,
  },
  solutions: {
    description: <>A chaque problème sa solution ! Pourtant lorsqu'un problème inconnu survient, la capacité à l'identifier, l'analyser, à en percevoir la complexité, et surtout à le résoudre deviennent des compétences très recherchées. Cette capacité à changer d'état d'esprit  et à trouver de nouvelles solutions font grandir les équipes et les sociétés d'assurance !</>,
    image: 'awards/TrouverDesSolutions.gif',
    imageSize: { w: 'auto', h: 'auto' },
    sliderImage: 'awards/TrouverDesSolutions.gif',
    sliderImageSize: { w: 210, h: 150 },
    extraClassNames: '',
    weight: 7,
    audio: null,
  },
  responsabilites: {
    description: <>Assumez vos choix... quels qu'ils soient ! Le sens des responsabilités est une qualité utile pour occuper des postes importants. En effet, un collaborateur qui a le sens des responsabilités ne cherche pas d'excuse ou de bouc émissaire et assume ses actes, ses décisions, ses erreurs.  Intègre et honnête, il sait se remettre en question, ce qui fait de lui une personne de confiance, porteuse d'une ambiance de travail respectueuse et confortable.</>,
    image: 'awards/SensDesResponsabilites.gif',
    imageSize: { w: 'auto', h: 'auto' },
    sliderImage: 'awards/SensDesResponsabilites.gif',
    sliderImageSize: { w: 210, h: 150 },
    extraClassNames: '',
    weight: 6,
    audio: null,
  },
  communication: {
    description: <>Clarté, exhaustivité et précision... Avoir le sens de la communication, c'est être capable de s'exprimer, d'échanger des idées, d'écouter et de transmettre une information. C'est également s'assurer d'être compris par ses interlocuteurs et c'est donc tout naturellement l'une des compétences les plus indispensables dans les métiers de l'assurance !</>,
    image: 'awards/SensDeLaCommunication.gif',
    imageSize: { w: 'auto', h: 'auto' },
    sliderImage: 'awards/SensDeLaCommunication.gif',
    sliderImageSize: { w: 210, h: 150 },
    extraClassNames: '',
    weight: 5,
    audio: null,
  },
};

export const AwardsSlider = () => {

  // Contexts
  const { isAwardsDisabled, awards } = useGame();

  // States
  const [awardsUnlocked, setAwardsUnlocked] = useState([]);
  const [activeAward, setActiveAward] = useState(null);

  // Get award
  useEffect(() => {
    setAwardsUnlocked(Object.values(awards).map(awardId => ({
      id: awardId,
      ...(AwardsService.getAward(awardId)),
      ...awardsInfos[awardId],
    })));
  }, [awards, setAwardsUnlocked]);

  // Active award
  // useEffect(() => {
  //   if (!activeAward && awardsUnlocked.length) {
  //     // setActiveAward(awardsUnlocked[0]);
  //   }
  // }, [awardsUnlocked, activeAward, setActiveAward]);

  const onChangeSlide = useCallback((e) => {
    if (e.realIndex === 0) {
      setActiveAward(null);
    } else {
      setActiveAward(awardsUnlocked[(e.realIndex - 1)] ? awardsUnlocked[(e.realIndex - 1)] : null);
    }
  }, [awardsUnlocked, setActiveAward]);

  // Refs
  const swiperRef = useRef(null);

  return isAwardsDisabled ? <></> : (<>
    <Swiper
      ref={swiperRef}
      onSlideChange={onChangeSlide}
      modules={[Pagination, Navigation, A11y, EffectCards]}
      navigation={true}
      allowTouchMove={true}
      simulateTouch={true}
      spaceBetween={50}
      slidesPerView={1}
      pagination={{ clickable: true }}
      effect={'cards'}
      speed={500}
      followFinger={true}
      loop={false}
      grabCursor={true}
      className="w-full md:w-[480px] h-[340px] mb-4"
    >


      <SwiperSlide key='splash' className={'bg-[#000]/80 rounded-lg overflow-hidden justify-center items-center text-black px-12 pt-7 pb-9 leading-5 bg-top bg-cover bg-origin-content bg-no-repeat'}>
        <p className="text-2xl text-center text-[#ffffff] pt-16 mb-4">SOFT SKILLS</p>
        <p className="text-normal text-center text-[#ffffff] pb-4 px-8">Très recherchées par les employeurs, les soft skills sont les capacités sociales, émotionnelles, de réflexion et d’apprentissage qui permettent à l’individu de s’adapter dans différentes situations.</p>
      </SwiperSlide>

      {awardsUnlocked.sort((a, b) => a.weight - b.weight).map(award => (<SwiperSlide
        key={award.id}
        className={`${award.extraClassNames} bg-[#ff5a47] rounded-lg overflow-hidden justify-center items-center text-black px-12 pt-7 pb-9 leading-5 bg-top bg-cover bg-origin-content bg-no-repeat`}
        style={{ backgroundImage: `url('${AssetsService.getImageUrl('awards/bg.png')}')` }}
      >
        <p className="text-lg text-white font-bold uppercase">{award.label}</p>
        <div className="w-full flex justify-center my-6">
          {award.sliderImage && (award.sliderImage.includes('.mp4')
            ? <VVideo className="!border-0 rounded-lg !w-auto" src={AssetsService.getVideoUrl(award.sliderImage)} width={award.sliderImageSize.w} height={award.sliderImageSize.h} loop muted autoPlay isPreload='auto' controls={false} />
            : <VImage src={AssetsService.getImageUrl(award.sliderImage)} width={award.sliderImageSize.w} height={award.sliderImageSize.h} />)}
        </div>
      </SwiperSlide>))}

    </Swiper >

    {activeAward && activeAward.description ? <p className="text-md font-medium">{activeAward.description}</p> : <></>
    }

  </>);
};

export const AwardFullscreen = () => {

  // Contexts
  const { awardFullscreen, setAwardFullscreen } = useGame();
  const { theme, animateButtonAwards } = useTheme();

  // States
  const [award, setAward] = useState(null);

  // Get award
  useEffect(() => {
    let award = null;
    const awardId = awardFullscreen && AwardsService.getAward(awardFullscreen) ? awardFullscreen : AwardsService.getAwardsIds()[0];
    if (AwardsService.getAward(awardId)) {
      award = {
        id: awardId,
        ...(AwardsService.getAward(awardId)),
        ...awardsInfos[awardId],
      };
    }
    setAward(award);
  }, [awardFullscreen]);

  // Close
  const closeAwardFullscreen = useCallback(() => {
    setAwardFullscreen(false);
    animateButtonAwards();
  }, [setAwardFullscreen, animateButtonAwards]);

  return !awardFullscreen ? <></> : (
    <VModal isOpen={true} size='full' isDismissable={true} backdrop={'blur'}
      classNames={{
        body: "bg-[#5421a6]/20 overflow-auto p-0",
        backdrop: "bg-[#000000]/70",
        base: "border-[#292f46] bg-none text-[#a8b0d3]",
        header: "border-b-[1px] border-[#292f46]",
        footer: "bg-[#000] ",
        closeButton: "hidden",
      }}
    >
      <ModalContent>
        <ModalBody>
          <div className={theme}>
            <div className="text-foreground bg-background min-h-screen h-auto">
              <Background id={10}>
                <div className="md:flex md:flex-col min-h[100vh] min-h-screen">
                  <div className="mx-auto max-w-[1024px] p-6 pt-12 md:flex-grow md:flex md:items-center">
                    <div className="mx-auto max-w-[700px] p-6 font-medium">

                      <VCenter>

                        {award !== null ? <>

                          {/*<VSpace>
                            {award.image && <>
                              {award.image.includes('.mp4')
                                ? <VVideo className="!border-0 !w-auto" src={AssetsService.getVideoUrl(award.image)} width={award.imageSize.w} height={award.imageSize.h} loop muted autoPlay isPreload='auto' controls={false} />
                                : <VImage src={AssetsService.getImageUrl(award.image)} width={award.imageSize.w} height={award.imageSize.h} /> }
                            </>*}
                            <div className="uppercase text-4xl font-bold relative top-[-180px]  sm:top-[-220px] z-10 hidden">{award.label}</div>
                          </VSpace> */}
                          <div className="relative mx-auto">
                            <img src={AssetsService.getImageUrl('awards/award.svg')} alt="" className="w-[480px] h-auto block" />
                            <div className="absolute left-0 right-0 text-center top-[18%] md:top-[15%] text-[5rem] sm:text-[6rem] md:text-[8rem] lg:text-[16rem] font-bold">1</div>
                            <div className="absolute left-0 right-0 text-center bottom-[15%] sm:bottom-[26%] md:bottom-[32%] text-[1rem] sm:text-[1.5rem] md:text-[2rem] leading-[1em] font-bold uppercase">
                              <span className="flex flex-col items-center justify-center mx-auto max-w-[240px] h-[100px]">{award.label}</span>
                            </div>
                          </div>

                          {(award.audio && (award.audio.file || award.audio.description)) && <VMessenger audio={award.audio.file} forceAutoplay={true}>{award.audio.description}</VMessenger>}

                          <p className="text-2xl lg:text-4xl font-bold">Tu as une qualité recherchée !</p>
                          <p className="pb-4 text-lg font-medium mb-6">RDV dans la zone « Succès » en bas de l'écran</p>

                        </> : <VLoading label="CHARGEMENT" />}

                        <VButton onPress={closeAwardFullscreen}>continuer</VButton>

                      </VCenter>

                    </div>
                  </div>
                </div>
              </Background>
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </VModal>
  );
}

export default AwardFullscreen;