import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

// Providers
import { useAuth } from '../../providers/AuthProvider.js';
import { useTheme, useThemeToast } from '../../providers/ThemeProvider.js';

// Services
import ApiService from '../../services/api.service.js';

// Components
import { Button, Checkbox, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure } from "@nextui-org/react";
import { VButton, VCard, VCenter, VImage, VInput, VPage, VSpace, VSplash, VSticker, VTitle } from "../../components/Vues.js";
import { CGU } from "../../components/CGU.js";
import { VideoPlayer } from "../../components/VideoPlayer.js";

// Icons
import { FaRegCircleRight, FaRegCircleCheck, FaRegEnvelope } from "react-icons/fa6";
import HomeEmailSent from '../../components/HomeEmailSent.js';
import ToastMessage from '../../components/ToastMessage.js';

const Home = () => {

  // Contexts
  const navigate = useNavigate();
  const { changeAuthMode, doRegisterNew, doRegisterExisting, doReload, doLogout } = useAuth();
  const { changeBackground } = useTheme();
  const { isOpen: isCGOpen, onOpen: onCGOpen, onOpenChange: onCGOpenChange } = useDisclosure();

  // Toasts
  // const showToastWaitEmail = useThemeToast(<ToastMessage><p>Vous devez désormais cliquer dans l'email pour finir de vous connecter.</p></ToastMessage>);
  const showToastApiError = useThemeToast(<ToastMessage><p>Il y a eu une erreur, merci de réessayer.</p></ToastMessage>);

  // States
  const [isLoading, setIsLoading] = useState(false);
  const [hasToken, setHasToken] = useState(ApiService.currentToken !== null);
  const [emailAddress, setEmailAddress] = useState('');
  const [isEmailValid, setEmailValid] = useState(false);
  const [isEmailEmpty, setEmailEmpty] = useState(true);
  const [isCGReadOnly, setCGReadOnly] = useState(true);
  const [isCGSelected, changeCG] = useState(false);
  const [currentView, setCurrentView] = useState(window.location.hash === '#login' ? 'login' : 'register');
  const [emailSentType, setEmailSentType] = useState('register');

  // set current auth mode
  useEffect(() => {
    changeAuthMode('web');
  }, [changeAuthMode]);

  // set current background
  useEffect(() => {
    changeBackground('01');
  }, [changeBackground]);

  // Events
  // "Login" or register (same API)
  const onClick = useCallback((type) => (e) => {
    setIsLoading(true);
    const usedFunction = type === 'register' ? doRegisterNew : doRegisterExisting;
    usedFunction(emailAddress, {
      onSuccess: (message) => {
        console.log(message);
        // showToastWaitEmail();
        setIsLoading(false);
        setEmailSentType(type);
        setCurrentView('email-sent');
      },
      onError: (message, error) => {
        // @todo: test register existing error (if user does not exist)
        console.log(message, error);
        showToastApiError();
        setIsLoading(false);
      },
    });
  }, [emailAddress, setIsLoading, doRegisterNew, doRegisterExisting, setEmailSentType, setCurrentView, showToastApiError]);
  const onClickRegister = useCallback((e) => {
    onClick('register')(e);
  }, [onClick]);
  const onClickLogin = useCallback((e) => {
    onClick('login')(e);
  }, [onClick]);

  // If token is present, try to reload infos to validate token
  const onClickBackToGame = useCallback((e) => {
    setIsLoading(true);
    doReload({
      onSuccess: () => {
        setIsLoading(false);
        navigate('/game');
      },
      onError: (message, error) => {
        // console.log(message, error);
        showToastApiError();
        setIsLoading(true);
        doLogout({
          onSuccess: () => {
            setIsLoading(false);
            setHasToken(false);
            setCurrentView('login');
          },
        });
      },
    });
  }, [navigate, doReload, setIsLoading, showToastApiError, doLogout]);

  // Logout
  const onClickLogout = useCallback((e) => {
    setIsLoading(true);
    doLogout({
      onSuccess: () => {
        setIsLoading(false);
        setHasToken(false);
        setCurrentView('login');
      },
    });
  }, [setIsLoading, setHasToken, doLogout]);

  // Auto validate on scroll
  const onCGScroll = useCallback((e) => {
    if (e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 50) {
      if (isCGReadOnly) {
        setCGReadOnly(false);
        changeCG(true);
      }
    }
  }, [isCGReadOnly, setCGReadOnly, changeCG]);

  // Check email
  const onChangeEmail = useCallback((e) => {
    setEmailAddress(e.target.value);
    if (e.target.value === "") {
      setEmailEmpty(true);
    } else {
      setEmailEmpty(false);
    }
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.target.value)) {
      setEmailValid(false);
    } else {
      setEmailValid(true);
    }
  }, [setEmailAddress, setEmailValid]);
  
  return currentView === 'email-sent' ? (
    <HomeEmailSent onButtonClick={() => setCurrentView('login')} buttonLabel={'Retour à la connexion'} type={emailSentType} />
  ) : (
    <>
      <VPage className="relative">
        <VCenter>

          <VImage width={140} src="logo-ifpass-white.svg" alt="Logo IFPASS"></VImage>

          <VideoPlayer className={`w-full border-2 border-ifpass-purple bg-black/50 my-4`} src='videos/common/IFPASS-Ifcast-Teaser.mp4' poster='videos/common/IFPASS-Ifcast-Teaser.png' >
            <track default={false} kind="captions" srcLang="Sous-titrage français" src='' />
          </VideoPlayer>

          <VTitle>
            <p>TON <span className="font-bold">AVENIR</span></p>
            <p>COMMENCE <span className="font-bold">MAINTENANT</span></p>
          </VTitle>

          <VSplash>
            <p>C'est pas</p>
            <p className="mb-2">du cinéma !</p>
          </VSplash>

          <VImage src="welcome.png" alt="Bienvenue" className="fixed z-0 bottom-0 right-0 hidden md:block md:w-[350px]" />

          {!hasToken &&
            <>

              {currentView !== "login"
                ? <VSticker>Inscris-toi !</VSticker>
                : <VSticker>Connecte-toi !</VSticker>}

              <VCard className="w-96 mb-4 pt-4 font-medium">
                <VSpace>
                  <VCenter>

                    <VInput
                      type="email"
                      label="Ton e-mail"
                      aria-label="Ton e-mail"
                      placeholder=""
                      endContent={
                        <FaRegEnvelope className="text-4xl text-ifpass-orange pointer-events-none" />
                      }
                      value={emailAddress}
                      onChange={onChangeEmail}
                      isInvalid={!isEmailValid && !isEmailEmpty}
                      errorMessage={!isEmailValid && !isEmailEmpty && "Ton email ne semble pas correct"}
                    />
                    
                    {currentView !== "login" && <>
                      <VSpace />
                      <Button onPress={onCGOpen} className="text-black bg-white" startContent={isCGSelected ? <FaRegCircleCheck className="text-green-500" /> : <FaRegCircleRight />}>Valider les conditions générales</Button>
                    </>}

                    <VSpace />
                    
                    {currentView !== "login"
                      ? <VButton onClick={onClickRegister} isLoading={isLoading} isDisabled={!isEmailValid || isEmailEmpty || !isCGSelected}>VALIDER</VButton>
                      : <VButton onClick={onClickLogin} isLoading={isLoading} isDisabled={!isEmailValid || isEmailEmpty}>VALIDER</VButton>}

                    <VSpace />

                    {currentView !== "login"
                      ? <Button variant="light" color="primary" className="font-medium" onPress={() => setCurrentView('login')}>Tu as déjà un compte ?</Button>
                      : <Button variant="light" color="primary" className="font-medium" onPress={() => setCurrentView('register')}>Tu n'as encore de compte ?</Button>}

                  </VCenter>
                </VSpace>
              </VCard>

            </>
          }

          {/* // CONTINUE ///////////////////////// */}
          {hasToken &&
            <>
              <VSticker>Bienvenue !</VSticker>
              <VCard className="w-96 mb-4 pt-4 font-medium">
                <VSpace>
                  <VCenter>

                    <p className="text-2xl font-bold uppercase">{hasToken.firstName}</p>
                    <p>De retour parmi nous ? Tu étais attendu !</p>

                    <VSpace />
                    <VButton onClick={onClickBackToGame} isLoading={isLoading}>REPRENDRE</VButton>

                    <VSpace />
                    <Button variant="light" color="primary" className="font-medium" onPress={onClickLogout}>Ce n'est pas mon compte !</Button>

                  </VCenter>
                </VSpace>
              </VCard>
            </>
          }

        </VCenter>
      </VPage >

      <Modal isOpen={isCGOpen} onOpenChange={onCGOpenChange} placement="top" scrollBehavior="inside" isDismissable={false} size="5xl"  >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader>CONDITIONS GÉNÉRALES</ModalHeader>
              <ModalBody onScroll={onCGScroll} className="m-4 ">
                <CGU />
              </ModalBody>
              <ModalFooter>
                <div className="flex justify-between w-full">
                  <Checkbox isSelected={isCGSelected} isReadOnly={isCGReadOnly} isDisabled={isCGReadOnly} onValueChange={changeCG}>J'ai lu et je valide les CGU</Checkbox>
                  <VButton onPress={onClose} color="primary">Fermer</VButton>
                </div>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal >

    </>
  );

};

export default Home;