import { useNavigate } from "react-router-dom";

// Components
import { VButton, VImage } from "./Vues";
import { useCallback, useEffect, useState } from "react";

const HomeEmailSent = ({ type, onButtonClick, buttonLabel }) => {

  // Navigate
  const navigate = useNavigate();
  const [ message, setMessage ] = useState('');

  // Events
  const onClick = useCallback(() => {
    if (onButtonClick) {
      onButtonClick();
    } else {
      navigate('/');
    }
  }, [onButtonClick, navigate]);

  useEffect(() => {
    setMessage(type === 'register' ? 'Consulte ta boite mail, pour valider ton inscription.' : 'Consulte ta boite mail, puis clique sur le lien pour te connecter.');
  }, [type, setMessage]);
  return (
    <section className="text-center justify-center flex-col flex items-center">

      <VImage src="images/sent.png"></VImage>

      <div className="text-2xl">Email envoyé !</div>
      <p className="text-md mb-6">{message}</p>

      <VButton onClick={onClick} >{buttonLabel ? buttonLabel : 'Retour'}</VButton>

    </section>
  );
};

export default HomeEmailSent;