import { useCallback } from 'react';

// Providers
import { useGame } from '../../providers/GameProvider';

// Components
import SessionWrapper from './SessionWrapper';
import { gameProgression as toProgression, gamePage as toPage } from './Auditions';
import BriefPersonnage from '../../components/game/BriefPersonnage';
import BriefConnaissances from '../../components/game/BriefConnaissances';
import BriefVideoInter from '../../components/game/BriefVideoInter';
import BriefVideo2 from '../../components/game/BriefVideo2';
import BriefTeam from '../../components/game/BriefTeam';
import BriefEnd from '../../components/game/BriefEnd';
import BriefTassures from '../../components/game/BriefTassures';

export const gameProgression = 5;
export const gameProgressionTo = toProgression;
export const gameChapter = '01';
export const gameBackground = '03';
export const gamePage = 'brief';
export const gameProgressBar = true;
export const gameFrame = true;

export const gameSteps = [
  {
    id: 'personnage',
    component: (props) => (<BriefPersonnage {...props} />),
    saveOnEnd: true,
  },
  {
    id: 'connaissances',
    component: (props) => (<BriefConnaissances {...props} />),
    saveOnEnd: true,
  },
  {
    id: 'tassures',
    component: (props) => (<BriefTassures {...props} />),
    saveOnEnd: false,
  },
  {
    id: 'team',
    component: (props) => (<><BriefVideoInter {...props} /><BriefVideo2 {...props} /><BriefTeam {...props} /></>),
    saveOnEnd: true,
  },
  {
    id: 'end',
    component: (props) => (<BriefEnd {...props} />),
    saveOnEnd: true,
  },
];

const Brief = () => {

  // Contexts
  const { userInfos, resetBrief, avatarId, teamId, connaissancesScore } = useGame();

  // Reset all infos
  const resetOnStart = useCallback(() => {
    resetBrief();
  }, [resetBrief]);

  return <SessionWrapper
    progression={gameProgression}
    progressionTo={gameProgressionTo}
    page={gamePage}
    steps={gameSteps}
    chapter={gameChapter}
    background={gameBackground}
    progressBar={gameProgressBar}
    frame={gameFrame}
    resetOnStart={resetOnStart}
    valuesToSave={{
      userInfos,
      userAvatar: avatarId,
      userTeam: teamId,
      userConnaissances: connaissancesScore,
    }}
    nextPage={toPage}
  />;
}

export default Brief;
