import AssetsService from "../services/assets.service";

const Background = ({ children, id }) => {
  return (
    <div>
      {/**
       * MODE = {process.env.NODE_ENV}
       * ROOT= {process.env.REACT_APP_ROOT}
       */}
      
      <div className={`bg-no-repeat bg-cover bg-center bg-fixed min-h-screen`} style={{ backgroundImage: `url('${AssetsService.getImageUrl(`backgrounds/${id}.jpg`)}')` }}>
        <div>{children}</div>
      </div>
      <div className={`hidden`} style={{ backgroundImage: `url('${AssetsService.getImageUrl('backgrounds/01.jpg')}')` }}/>
      <div className={`hidden`} style={{ backgroundImage: `url('${AssetsService.getImageUrl('backgrounds/02.jpg')}')` }}/>
      <div className={`hidden`} style={{ backgroundImage: `url('${AssetsService.getImageUrl('backgrounds/03.jpg')}')` }}/>
      <div className={`hidden`} style={{ backgroundImage: `url('${AssetsService.getImageUrl('backgrounds/04.jpg')}')` }}/>
      <div className={`hidden`} style={{ backgroundImage: `url('${AssetsService.getImageUrl('backgrounds/05.jpg')}')` }}/>
      <div className={`hidden`} style={{ backgroundImage: `url('${AssetsService.getImageUrl('backgrounds/06.jpg')}')` }}/>
      <div className={`hidden`} style={{ backgroundImage: `url('${AssetsService.getImageUrl('backgrounds/07.jpg')}')` }}/>
      <div className={`hidden`} style={{ backgroundImage: `url('${AssetsService.getImageUrl('backgrounds/08.jpg')}')` }}/>
      <div className={`hidden`} style={{ backgroundImage: `url('${AssetsService.getImageUrl('backgrounds/09.jpg')}')` }}/>
      <div className={`hidden`} style={{ backgroundImage: `url('${AssetsService.getImageUrl('backgrounds/10.jpg')}')` }}/>

    </div>
  );
}
export default Background;
