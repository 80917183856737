import { useCallback, useEffect, useState } from 'react';

// Providers
import { useGame } from '../../providers/GameProvider';
import { useThemeToast } from '../../providers/ThemeProvider';

// Services
import JobsService from '../../services/jobs.service';

// Components
import { VCenter, VButton, VPage, VSpace, VSticker, VLoading, VCard, VButtonLink } from "../../components/Vues";
import { CheckboxText } from '../CheckboxText';
import { CheckboxGrid } from '../CheckboxGrid';
import ToastMessage from '../ToastMessage';

// Icons
import { FaArrowLeft } from 'react-icons/fa6';

const stepsLabels = {
  step1: {
    title: 'Étape 1',
    description: <>
      <p className="text-2xl font-medium">Sélectionne les activités qui te plairaient !</p>
      <p className="">Et laisse de côté celles qui ne t’intéressent pas ou te sont indifférentes.</p>
    </>,
  },
  step2: {
    title: 'Étape 2',
    description: <>
      <p className="text-2xl font-medium">Sélectionne les activités qui te plairaient !</p>
      <p className="">Et laisse de côté celles qui ne t’intéressent pas ou te sont indifférentes.</p>
    </>,
  },
  step3: {
    title: 'Étape 3',
    description: <>
      <p className="text-2xl font-medium">Sélectionne les activités qui te plairaient !</p>
      <p className="">Et laisse de côté celles qui ne t’intéressent pas ou te sont indifférentes.</p>
    </>,
  },
};

const ReperagesEnquete = ({ onEnd, isNextLoading }) => {

  // Contexts
  const { appDebugMode, configJobsIsLoaded, jobsQuestions, userJobs, setUserJobs } = useGame();

  // States
  const [isLoading, setIsLoading] = useState(false);
  const [step3IsNeeded, setStep3IsNeeded] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [currentStep, setCurrentStep] = useState(null);
  const [currentStepIsComplete, setCurrentStepIsComplete] = useState(false);
  const [questionsAnswers, setQuestionsAnswers] = useState(null);
  const [stepQuestions, setStepQuestions] = useState([]);

  // Toasts
  const showToastLot1 = useThemeToast(<ToastMessage image="01"><p>Ça se précise… Merci pour ces informations, je vais pouvoir te proposer un rôle sur mesure !</p></ToastMessage>);
  const showToastLot3 = useThemeToast(<ToastMessage image="01"><p>Je vais avoir besoin d’une dernière précision.</p></ToastMessage>);

  const step = `step${currentStep}`;

  // Wait for config (questions selection)
  useEffect(() => {
    setIsLoading(!configJobsIsLoaded);
  }, [configJobsIsLoaded]);

  // init current step
  useEffect(() => {
    if (jobsQuestions && Object.keys(jobsQuestions).length && jobsQuestions.step1.length) {
      setQuestionsAnswers(prevQuestionsAnswers => {
        if (prevQuestionsAnswers !== null) {
          return prevQuestionsAnswers;
        }
        setCurrentStep(1);
        setStep3IsNeeded(false);
        setCurrentStepIsComplete(false);
        return {
          step1: jobsQuestions.step1.map(answer => ({ ...answer, checked: false })),
          step2: jobsQuestions.step2.map(answer => ({ ...answer, checked: false })),
          step3: jobsQuestions.step3.map(answer => ({ ...answer, checked: false })),
        };
      });
    }
  }, [jobsQuestions, setCurrentStep, setQuestionsAnswers, setStep3IsNeeded]);

  const getValuesFromStep = useCallback((step, newQuestionsAnswers) => {
    return (newQuestionsAnswers ? newQuestionsAnswers : questionsAnswers)[step].filter(answer => answer.checked).map(answer => answer.taskId);
  }, [questionsAnswers]);

  const setValuesForStep = useCallback((questionsAnswers, step, values) => {
    return questionsAnswers[step].map(answer => ({
      ...answer,
      checked: values.includes(answer.taskId),
    }));
  }, []);

  // Update current step isComplete state
  useEffect(() => {
    setCurrentStepIsComplete(currentStep ? getValuesFromStep(`step${currentStep}`, questionsAnswers).length > 0 : false);
  }, [currentStep, questionsAnswers, getValuesFromStep, setCurrentStepIsComplete]);

  // Sync userJobs with questionsAnswers
  useEffect(() => {
    // const newUserJobs = currentStep && questionsAnswers && userJobs ? JobsService.computeWithAnswers(userJobs, questionsAnswers, true, 10) : userJobs;
    setUserJobs(prevUserJobs => {
      if (!(prevUserJobs && questionsAnswers)) {
        return prevUserJobs;
      }
      // force reset / favorite state
      const jobs = JobsService.computeWithAnswers(prevUserJobs, questionsAnswers, currentStep === 3);
      Object.keys(jobs).forEach(jobId2 => {
        jobs[jobId2] = {
          ...jobs[jobId2],
          favorite: false,
          liked: false,
          viewed: false,
        };
      });
      return jobs;
    });
  }, [currentStep, questionsAnswers, setUserJobs]);

  // Answers of current step
  useEffect(() => {
    if (step && userJobs && questionsAnswers) {
      if (step === 'step3') {
        const neededTasks = JobsService.getJobsTasksNeeded(JobsService.getJobsThatNeedsStep3(userJobs, questionsAnswers));
        setStepQuestions(questionsAnswers[step].filter(answer => neededTasks.includes(answer.taskId)));
      } else {
        setStepQuestions(questionsAnswers[step]);
      }
    }
  }, [appDebugMode, step, userJobs, questionsAnswers, setStepQuestions]);

  useEffect(() => {
    if (userJobs && questionsAnswers && appDebugMode) {
      console.log('jobs step 3', JobsService.getJobsThatNeedsStep3(userJobs, questionsAnswers));
      // console.log('sorted 8 jobs', JobsService.populate(JobsService.sort(userJobs, 8, 'axes')));
    }
  }, [appDebugMode, userJobs, questionsAnswers]);

  // Sync step 3 needed with userJobs
  useEffect(() => {
    setStep3IsNeeded(currentStep && questionsAnswers && userJobs ? JobsService.isStep3Needed(userJobs, questionsAnswers) : false);
  }, [currentStep, userJobs, questionsAnswers, setStep3IsNeeded]);

  // on checkbox click
  const onAnswersChange = useCallback((step) => (value) => {
    const newQuestionsAnswers = {
      step1: step === 'step1' ? setValuesForStep(questionsAnswers, step, value) : questionsAnswers.step1,
      step2: step === 'step2' ? setValuesForStep(questionsAnswers, step, value) : questionsAnswers.step2,
      step3: step === 'step3' ? setValuesForStep(questionsAnswers, step, value) : questionsAnswers.step3.map(answer => ({ ...answer, checked: false })), // reset step 3
    };
    // console.log(newQuestionsAnswers, step, value);
    setQuestionsAnswers(newQuestionsAnswers);
  }, [questionsAnswers, setQuestionsAnswers, setValuesForStep]);

  // toasts
  useEffect(() => {
    if (currentStep === 1 && currentStepIsComplete) {
      showToastLot1();
    } else if (currentStep === 2 && step3IsNeeded && currentStepIsComplete) {
      showToastLot3();
    }
  }, [currentStep, currentStepIsComplete, step3IsNeeded, showToastLot1, showToastLot3]);

  // finish test
  useEffect(() => {
    const isFinalStep = (step3IsNeeded && currentStep >= 3) || (!step3IsNeeded && currentStep >= 2);
    setIsComplete(isFinalStep && getValuesFromStep(`step${currentStep}`, questionsAnswers).length > 0);
  }, [currentStep, step3IsNeeded, questionsAnswers, getValuesFromStep, setIsComplete]);

  const goToPreviousStep = useCallback((e) => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1); // currentStep 1-based
    }
  }, [currentStep]);

  const goToNextStep = useCallback((e) => {
    if ((step3IsNeeded && currentStep < 3) || (!step3IsNeeded && currentStep < 2)) {
      setCurrentStep(currentStep + 1); // currentStep 1-based
      setCurrentStepIsComplete(false);
    }
  }, [currentStep, step3IsNeeded]);

  const goToNext = useCallback(() => {
    onEnd();
  }, [onEnd]);

  return (

    <VPage>

      <VCenter>

        <div className="!w-full md:!w-[540px] lg:!w-[880px] m-0 py-8">

          {!isLoading && questionsAnswers && currentStep ? (
            <VCard className="w-auto">
              <VCenter>

                <VSticker className="w-40 pb-6">{stepsLabels[step].title}</VSticker>

                <div className="py-8 overflow-hidden">{stepsLabels[step].description}</div>

                <CheckboxGrid onValueChange={onAnswersChange(step)} value={getValuesFromStep(step)}>
                  {stepQuestions && stepQuestions.map((answer) => (
                    <CheckboxText key={answer.taskId} value={answer.taskId} size='lg'>
                      {answer.phrase}
                      {appDebugMode && (<>
                        <br /><small className='text-black/50'>{answer.taskId} - {answer.jobs.join(',')}</small>
                      </>)}
                    </CheckboxText>
                  ))}
                </CheckboxGrid>

                <VSpace className="pt-6 mb-12 flex items-center justify-evenly w-full px-7 gap-8">
                  {currentStep > 1 && (<VButtonLink onPress={goToPreviousStep}><FaArrowLeft size="15" /> Revenir</VButtonLink>)}
                  {/* {Object.keys(questionsAnswers).map((a) => (<p key={a}>{questionsAnswers[a]}</p>))} */}
                  {((step3IsNeeded && currentStep < 3) || (!step3IsNeeded && currentStep < 2)) && (<VButton onPress={goToNextStep} isDisabled={!currentStepIsComplete}>Valider</VButton>)}
                  {((step3IsNeeded && currentStep >= 3) || (!step3IsNeeded && currentStep >= 2)) && (<VButton onPress={goToNext} isLoading={isNextLoading} isDisabled={!isComplete}>Terminer</VButton>)}
                </VSpace>

              </VCenter>
            </VCard>
          ) : <VLoading label="CHARGEMENT" />}
        </div>

        {appDebugMode && !isLoading && userJobs && <ul className='text-left w-[700px]'>
          {JobsService.populate(JobsService.sort(userJobs)).map((userJob, index) => (
            <li className={`flex justify-between gap-4 border-b-[1px]${index >= 8 ? ' opacity-60' : ''}`} key={userJob.id}>
              <span className='grow basis-0'>
                {userJob.id} - {userJob.name}
                <br /><small className='text-white/50'>Poids {userJob.weight}</small>
              </span>
              <span className='grow basis-0'>
                <span className='flex justify-between gap-4 border-b-[1px]'>
                  <span className='grow basis-0'>Global<br />{Math.round(userJob.score * 10000) / 10000}</span>
                  <span className='grow basis-0'>Axes<br />{Math.round(userJob.scoreAxes * 10000) / 10000}</span>
                  <span className='grow basis-0'>{Object.keys(userJob.scorePerProfile).map(profileId => `${profileId}: ${Math.round(userJob.scorePerProfile[profileId] * 10000) / 10000}`).join(' / ')}</span>
                  <span className='grow basis-0'>Tasks<br />{Math.round(userJob.scoreTasks * 10000) / 10000}</span>
                </span>
              </span>
            </li>
          ))}
        </ul>}

      </VCenter >

    </VPage >
  );
}

export default ReperagesEnquete;