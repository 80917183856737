import { useCallback } from "react";

// Components
import { VCenter, VButton, VPage, VSpace } from "../../components/Vues";
import { VideoPlayer } from "../VideoPlayer";

const AccueilEnd = ({ onEnd, isNextLoading }) => {

  const goToNext = useCallback(() => {
    onEnd();
  }, [onEnd]);

  return (
    <VPage>

      <VCenter>

        <VideoPlayer className={`w-full border-2 border-ifpass-purple bg-black/50 mb-4`} src='/videos/common/Presentation_IFCAST.mp4' poster='/videos/common/Presentation_IFCAST.png'>
          <track default={false} kind="captions" srcLang="Sous-titrage français" src='/videos/common/Presentation_IFCAST.vtt' />
        </VideoPlayer>


        {/* <VTitle>
          <p>FAISONS <span className="font-bold">CONNAISSANCE</span></p>
        </VTitle>

        <VCard className="p-6 mb-0">

          <VCenter>

            <VImage src="tutor/01.png" alt="Bienvenue" className="w-[300px]" />
            <div className="relative top-[-16px]">
              <VSticker>DIRECTEUR DE CASTING </VSticker>
            </div>
            <p className="font-bold text-3xl">YVES !</p>

          </VCenter>

        </VCard>

        <VMessenger audio="01_01.mp3" >
          Bienvenue dans Ifcast, le Serious Game qui t’accompagne à la découverte de ton parcours sur mesure dans l’univers de l’assurance.
          Moi c’est Yves, je suis le directeur de casting de l’Ifcast. Mon boulot c’est de trouver le meilleur rôle à chacun des candidats que je reçois ici.
          Je sais que ce n’est pas toujours évident de s’orienter vers un métier, de savoir si ça va nous plaire, ou quelle formation suivre. Et surtout, surtout… la question qu’on me pose à chaque convocation : par où commencer ?
          La réponse c’est : ici et maintenant ! On y va ?
        </VMessenger>

        <p className="text-2xl font-medium">A toi de trouver ton rôle !</p> */}

        <VSpace />

        <VButton onPress={goToNext} isLoading={isNextLoading}>Continuer</VButton>

      </VCenter>

    </VPage>
  );
}

export default AccueilEnd;