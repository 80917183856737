import { useCallback } from "react";

// Components
import { VCenter, VTitle, VButton, VPage, VSpace, VMessenger, VCard, VImage, VIcon, VVideo } from "../../components/Vues";

// Icons
import { FaPersonRays } from "react-icons/fa6";

const BriefTassures = ({ onEnd, isNextLoading }) => {

  const goToNext = useCallback(() => {
    onEnd();
  }, [onEnd]);

  return (

    <VPage>

      <VCenter>

        <VTitle>
          <p><span className="font-bold">T'ASSURES !</span></p>
        </VTitle>

        <VMessenger audio="02_06.mp3"  >
          Ce qu’il faut retenir de tout ça, c’est que l’assurance, c’est un domaine centré sur l’humain avant tout… Allez, on enchaîne ? D’ailleurs sais-tu pourquoi on s’assure ?
        </VMessenger>

        <VCenter>
          <VImage src="tutor/10.png" alt="Bienvenue" className="w-[200px]" />
        </VCenter>

        <VCard className="py-4 mb-4">
          <VCenter>
            <p className="text-2xl font-bold leading-3">L’assurance un domaine</p>
            <p className="text-4xl font-bold">centré sur l’humain</p>
            <VIcon className="w-32 h-32" icon={<FaPersonRays className=" w-20 h-20" />} />
          </VCenter>
        </VCard>

        <VTitle>
          <p>POURQUOI <span className="font-bold">ON S'ASSURE ?</span></p>
        </VTitle>

        <VVideo id="Brief01" controls={true} autoPlay={false} />

        <VSpace>
          <VButton onPress={goToNext} isLoading={isNextLoading}>continuer</VButton>
        </VSpace>

      </VCenter>

    </VPage>
  );
}

export default BriefTassures;