const PlacesService = {
  places: {},
  populateFromConfig: ({ places }) => {
    places.forEach(place => {
      PlacesService.places[place.id] = {
        id: place.id,
        ...place,
      };
    }); 
  },
  getPlaces: () => Object.values(PlacesService.places),
  getPlacesIds: () => Object.keys(PlacesService.places),
  getPlacesKeyed: () => PlacesService.places,
  getPlace: id => PlacesService.places[id] || null,
};

export default PlacesService;