const AssetsService = {
  rootPath: process.env.REACT_APP_ROOT || '/',
  removeLeadingSlashes: str => str.replace(/^\/+/g, ''),
  removeTrailingSlashes: str => str.replace(/\/+$/g, ''),
  removeHostname: str => str.replace(/^[a-z]{4,5}:\/{2}[a-z.]{1,}:?[0-9]{0,4}\/?(.*)/, '$1'),
  getUrl: (basePath) => `${AssetsService.removeTrailingSlashes(AssetsService.rootPath)}/${basePath}`,
};
AssetsService.rootPathNormalized = AssetsService.removeLeadingSlashes(AssetsService.removeTrailingSlashes(AssetsService.rootPath));
AssetsService.routerBase = `/${AssetsService.removeHostname(AssetsService.rootPathNormalized)}`;
AssetsService.baseUrl = AssetsService.rootPath.slice(0, -1 * AssetsService.routerBase.length);
AssetsService.baseImagesPath = AssetsService.removeTrailingSlashes(`${AssetsService.rootPathNormalized}/${AssetsService.removeHostname(process.env.REACT_APP_IMAGES_ROOT) || `${AssetsService.rootPathNormalized}/images/`}/`);
AssetsService.baseAudiosPath = AssetsService.removeTrailingSlashes(`${AssetsService.rootPathNormalized}/${AssetsService.removeHostname(process.env.REACT_APP_AUDIOS_ROOT) || `${AssetsService.rootPathNormalized}/audios/`}/`);
AssetsService.baseVideosPath = AssetsService.removeTrailingSlashes(`${AssetsService.rootPathNormalized}/${AssetsService.removeHostname(process.env.REACT_APP_VIDEOS_ROOT) || `${AssetsService.rootPathNormalized}/videos/`}/`);

AssetsService.getImageUrl = (imagePath) => imagePath.includes('images/') ? imagePath : `${AssetsService.baseImagesPath}/${imagePath}`;
AssetsService.getAudioUrl = (audioPath) => audioPath.includes('audios/') ? audioPath : `${AssetsService.baseAudiosPath}/${audioPath}`;
AssetsService.getVideoUrl = (videoPath) => videoPath.includes('videos/') ? videoPath : `${AssetsService.baseVideosPath}/${videoPath}`;

export default AssetsService;
