// Providers
import { useGame } from '../../providers/GameProvider';

// Components
import { VCenter, VPage, VLoading } from "../Vues";
import DebriefingExplorer from '../../components/game/DebriefingExplorer';
import DebriefingBravo from '../../components/game/DebriefingBravo';

const DebriefingConvaincuResults = ({ onEnd, isNextLoading }) => {

  // Contexts
  const { userInfos } = useGame();

  return userInfos ? <>
    {userInfos.wantsContact === null ? '' : <VPage>
      {userInfos.wantsContact ? <DebriefingBravo onEnd={onEnd} isNextLoading={isNextLoading} /> : <DebriefingExplorer onEnd={onEnd} isNextLoading={isNextLoading} />}
    </VPage>
    }
  </> : <>
    <VPage>
      <VCenter>
        <VLoading label="CHARGEMENT" />
      </VCenter>
    </VPage>
  </>;
}

export default DebriefingConvaincuResults;