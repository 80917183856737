import { useCallback } from "react";

// Components
import { VCenter, VTitle, VSticker, VButton, VPage, VTutor, VMessenger } from "../../components/Vues";

const ReperagesHome = ({ onEnd, isNextLoading }) => {

  const goToNext = useCallback(() => {
    onEnd();
  }, [onEnd]);

  return (

    <VPage>

      <VCenter>
        <VTitle>
          <p>À LA RECHERCHE <span className="font-bold">DE TON RÔLE</span></p>
        </VTitle>

        <VSticker>C'est parti</VSticker>
        <VTutor id="01" />

        <VMessenger audio="04_01.mp3" >
          J’ai en tête pas mal de rôles qui pourraient te correspondre… Il va me falloir un peu plus d’infos sur ce que tu aimes faire…
          Attends que je retrouve le scénario… Voilà.. Alors… Qu’est-ce que j’ai pour toi ?... Tu es prêt ?
        </VMessenger>

        <VButton onPress={goToNext} isLoading={isNextLoading}>démarrer</VButton>

      </VCenter>

    </VPage>
  );
}

export default ReperagesHome;