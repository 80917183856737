const avatars = [
  {
    id: 1,
    name: "Avatar 1",
    src: "avatars/01.png",
  },
  {
    id: 2,
    name: "Avatar 2",
    src: "avatars/02.png",
  },
  {
    id: 3,
    name: "Avatar 3",
    src: "avatars/03.png",
  }
];
const AvatarsService = {
  avatars: {},
  getAvatars: () => Object.values(AvatarsService.avatars),
  getAvatarsIds: () => Object.keys(AvatarsService.avatars),
  getAvatarsKeyed: () => AvatarsService.avatars,
  getAvatar: id => AvatarsService.avatars[id] || null,
};
avatars.forEach(avatar => {
  AvatarsService.avatars[avatar.id] = {
    id: avatar.id,
    ...avatar,
  };
}); 

export default AvatarsService;