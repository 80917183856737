import { useGame } from '../../providers/GameProvider';
import { useAuth } from '../../providers/AuthProvider';

import { gameProgression as briefProgression } from './Brief';
import { gameProgression as auditionsProgression } from './Auditions';
import { gameProgression as reperagesProgression } from './Reperages';
import { gameProgression as coachingProgression } from './Coaching';
import { gameProgression as debriefingProgression } from './Debriefing';
import { gameProgression as findujeuProgression } from './FinDuJeu';
import { useEffect, useState } from 'react';
import { VLoading } from '../../components/Vues';
import { Navigate } from 'react-router-dom';

const Index = () => {
    const { isUserRegistered } = useAuth();
    const { isGameRestoredFromApi, maxProgression } = useGame();

    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        if (isUserRegistered && isGameRestoredFromApi) {
            setLoading(false);
        }
    }, [maxProgression, isGameRestoredFromApi, isUserRegistered, setLoading]);

    return <>
        {isLoading
            ? <VLoading />
            : <>
                {maxProgression >= findujeuProgression
                    ? <Navigate to="/game/findujeu" replace />
                    : <>
                        {maxProgression >= debriefingProgression
                            ? <Navigate to="/game/debriefing" replace />
                            : <>
                                {maxProgression >= coachingProgression
                                    ? <Navigate to="/game/coaching" replace />
                                    : <>
                                        {maxProgression >= reperagesProgression
                                            ? <Navigate to="/game/reperages" replace />
                                            : <>
                                                {maxProgression >= auditionsProgression
                                                    ? <Navigate to="/game/auditions" replace />
                                                    : <>
                                                        {maxProgression >= briefProgression
                                                            ? <Navigate to="/game/brief" replace />
                                                            : <>
                                                                <Navigate to="/game/accueil" replace />
                                                            </>}
                                                    </>}
                                            </>}
                                    </>}
                            </>}
                    </>}
            </>}
    </>
};

export default Index;