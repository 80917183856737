// Components
import { VCenter, VTitle, VMessenger, VCard, VImage } from "../../components/Vues";

const BriefVideoInter = () => {

  return (

    <VCenter>

      <VTitle>
        <p>L'ASSURANCE, UNE <span className="font-bold">INFINITÉ</span></p>
        <p><span className="font-bold"> DE MÉTIERS</span></p>
      </VTitle>

      <VMessenger audio="02_07.mp3"  >
        Ok, maintenant tu sais pourquoi on s’assure. Mais l’assurance, c’est une infinité de métiers ! Pour commencer à voir quel rôle pourrait te correspondre, il va falloir que j’identifie à quelle grande équipe tu pourrais appartenir…
      </VMessenger>

      <VCard className="py-6">
        <VCenter>
          <VImage src="tutor/10.png" alt="Bienvenue" className="w-[300px]" />
        </VCenter>
      </VCard>

    </VCenter>
    
  );
}

export default BriefVideoInter;