import ApiService from "./api.service";
import CursusService from "./cursus.service";
import JobsService from "./jobs.service";

const ProgressService = {
  getLocalStorageKey: key => key.slice(0, 4) === 'user' ? key : `user${key.charAt(0).toUpperCase()}${key.slice(1)}`,
  getAPIKey: key => key.slice(0, 4) === 'user' ? `${key.charAt(4).toLowerCase()}${key.slice(5)}` : key,
  isSimple: value => !(value !== null && (typeof value === 'object' || Array.isArray(value))),
  get: (key, varDefault) => {
    const apiKey = ProgressService.getAPIKey(key);
    const defaultValue = varDefault || ProgressService.defaultProgress[apiKey];
    const isSimple = ProgressService.isSimple(defaultValue);
    const saved = localStorage.getItem(ProgressService.getLocalStorageKey(key));
    const value = (saved && saved !== 'null' ? (isSimple ? saved : JSON.parse(saved)) : null) || defaultValue;
    if (apiKey === 'volume' || apiKey === 'maxProgression') {
      return parseFloat(value);
    }
    if (apiKey === 'connaissances' || apiKey === 'debugEnabled') {
      return parseInt(value);
    }
    return value;
  },
  set: (key, value) => {
    const isSimple = ProgressService.isSimple(value);
    localStorage.setItem(ProgressService.getLocalStorageKey(key), isSimple ? value : JSON.stringify(value));
  },
  getProgressionForScreen: (gameProgression, gameProgressionTo, gameSteps, currentScreen) => {
    return gameProgression + ((gameProgressionTo - gameProgression) / gameSteps.length * currentScreen);
  },
  determineParametersForScreen: (gameSteps, currentScreen, defaultBackground, defaultProgressBar, defaultFrame) => {
    const screen = gameSteps[currentScreen] ? currentScreen : currentScreen - 1; // check on last screen
    const parameters = {
      background: defaultBackground,
      progressBar: defaultProgressBar,
      frame: defaultFrame,
      award: null,
    };
    gameSteps.forEach((gameStep, index) => {
      if (index === screen) {
        parameters.background = typeof gameStep.background != 'undefined' ? gameStep.background : parameters.background;
        parameters.progressBar = typeof gameStep.progressBar != 'undefined' ? gameStep.progressBar : parameters.progressBar;
        parameters.frame = typeof gameStep.frame != 'undefined' ? gameStep.frame : parameters.frame;
        parameters.award = typeof gameStep.award != 'undefined' ? gameStep.award : parameters.award;
      }
    });
    return parameters;
  },
  determineBackgroundForScreen: (parameters, prevBackground) => {
    return parameters.background !== prevBackground ? parameters.background : prevBackground;
  },
  determineProgressBarForScreen: (parameters, prevProgressBar) => {
    return parameters.progressBar !== prevProgressBar ? parameters.progressBar : prevProgressBar;
  },
  determineFrameForScreen: (parameters, prevFrame) => {
    return parameters.frame !== prevFrame ? parameters.frame : prevFrame;
  },
  determineBookForScreen: (parameters, prevBook) => {
    return parameters.book !== prevBook ? parameters.book : prevBook;
  },
  determineVideosForScreen: (parameters, prevVideos) => {
    return parameters.videos !== prevVideos ? parameters.videos : prevVideos;
  },
  determineAwardForScreen: (parameters, prevAward) => {
    return parameters.award !== prevAward ? parameters.award : prevAward;
  },
  saveProgressionOnStep: (gameSteps, currentScreen, values, { onAlways, onSuccess, onError }) => {
    const screen = gameSteps[currentScreen] ? currentScreen : currentScreen - 1; // save on last screen
    const progressMethod = /*!!gameSteps[screen].saveOnEnd ? 'save' : */'set';
    if (Object.keys(values).length) {
      Object.keys(values).forEach(valueKey => {
        ProgressService[progressMethod](valueKey, values[valueKey]);
      });
    }
    if (!!gameSteps[screen].saveOnEnd) {
      ProgressService.saveToAPI({
        onSuccess: (message) => {
          onAlways && onAlways();
          onSuccess && onSuccess(message);
        },
        onError: (message) => {
          onAlways && onAlways();
          onError && onError(message);
        },
      });
    } else {
      onAlways();
      onSuccess();
    }
  },
  saveToAPI: ({ onSuccess, onError }) => {
    const data = {};
    ProgressService.allSaveableProgressKeys.forEach((key) => {
      data[ProgressService.getAPIKey(key)] = ProgressService.get(key);
    });
    const jobFavorite = data.jobFavorite ? JobsService.getJob(data.jobFavorite) : null;
    data.jobFavoriteLabel = jobFavorite && jobFavorite.name ? jobFavorite.name : '';
    data.jobLikesLabel = [];
    data.jobViewedLabel = [];
    if (data.jobs && Object.keys(data.jobs).length) {
      Object.values(data.jobs).forEach((job) => {
        const realJob = JobsService.getJob(job.id);
        if (realJob) {
          if (job.liked) {
            data.jobLikesLabel.push(realJob.name);
          }
          if (job.viewed) {
            data.jobViewedLabel.push(realJob.name);
          }
        }
      });
    }
    data.cursusLikesLabel = [];
    if (data.cursusLikes && data.cursusLikes.length) {
      data.cursusLikes.forEach((cursusId) => {
        const cursus = CursusService.getCursus(cursusId);
        if (cursus) {
          data.cursusLikesLabel.push(cursus.name);
        }
      });
    }
    console.log('save data', data);
    ApiService.updateData(data, { onSuccess, onError });
  },
  restoreFromApi: (values) => {
    const restorable = values || {};
    ProgressService.allSaveableProgressKeys.forEach((key) => {
      ProgressService.set(key, restorable[key] || ProgressService.defaultProgress[key]);
    });
    console.log('restore data', restorable, ProgressService.allSaveableProgressKeys);
  },
  defaultProgress: {
    debugEnabled: 0,
    theme: 'normal',
    volume: 0.2,
    awards: [],
    videos: [],
    maxProgression: 0,
    infos: {
      firstName: '',
      lastName: '',
      age: null,
      studies: null,
      email: null,
      wantsContact: null,
    },
    avatar: null,
    team: null,
    connaissances: 0,
    axes: {},
    jobs: {},
    jobFavorite: null,
    competencesRating: {},
    cursusLikes: [],
  },
  allSaveableProgressKeys: [
    // 'debugEnabled',
    // 'theme',
    // 'volume',
    'awards',
    // 'videos',
    'maxProgression',
    'infos',
    'avatar',
    'team',
    'connaissances',
    'axes',
    'jobs',
    'jobFavorite',
    'competencesRating',
    'cursusLikes',
  ],
};

export default ProgressService;