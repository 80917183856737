import { useCallback, useEffect, useState } from "react";

// Providers
import { useGame } from '../../providers/GameProvider';
import { useThemeToast } from "../../providers/ThemeProvider";

// Services
import TeamsService from "../../services/teams.service";

// Components
import { RadioGroup, cn } from "@nextui-org/react";
import { VCenter, VTitle, VButton, VPage, VSpace, VCard, VImage, VSubTitle } from "../../components/Vues";
import RadioImage from "../RadioImage";
import ToastMessage from "../ToastMessage";

const BriefTeam = ({ onEnd, isNextLoading }) => {

  // Contexts
  const { team, changeTeam } = useGame();

  // Toasts
  const showToast = useThemeToast(<ToastMessage image="01"><p>Merci, ça devrait pouvoir m’aider !</p></ToastMessage>);

  // States
  const [teamList, setTeamList] = useState([]);

  // Datalist
  useEffect(() => {
    setTeamList(TeamsService.getTeams());
  }, []);

  // Events
  const handleChange = useCallback(teamValue => {
    showToast(!!teamValue);
    changeTeam(teamValue);
  }, [changeTeam, showToast]);

  const goToNext = useCallback(() => {
    onEnd();
  }, [onEnd]);

  return (

    <VPage>

      <VCenter>

        <VTitle>
          <p>CHOISIS <span className="font-bold">TON ÉQUIPE</span></p>
        </VTitle>

        <VSubTitle>A priori, dans quelle équipe te classerais-tu ?</VSubTitle>

        <VSpace />

        <VCard className="w-full py-8 font-medium">
          <VCenter >

            <RadioGroup
              label=""
              value={team ? team.id : null}
              onValueChange={handleChange}
              classNames={{
                base: cn(
                  " flex ",
                ),
                wrapper: " grid grid-cols-3 gap-4 pb-4 ",
              }}
            >
              {teamList.map(team => (
                <RadioImage value={team.id} key={team.id} description={team.name} classNames={{ labelWrapper: " m-0 " }}>
                  <VImage src={team.src} width={140} radius="none" />
                </RadioImage>
              ))}
            </RadioGroup>

            <VButton onPress={goToNext} isLoading={isNextLoading} isDisabled={!team || !team.id}>valider</VButton>

          </VCenter >
        </VCard>

        <VSpace />

      </VCenter>

    </VPage>
  );
}

export default BriefTeam;