import { useCallback } from 'react';

// Providers
import { useGame } from '../../providers/GameProvider';

// Services
import JobsService from '../../services/jobs.service';

// Components
import SessionWrapper from './SessionWrapper';
import { gamePage as toPage } from './FinDuJeu';
import DebriefingHome from '../../components/game/DebriefingHome';
import DebriefingConvaincu from '../../components/game/DebriefingConvaincu';
import DebriefingConvaincuResults from '../../components/game/DebriefingConvaincuResults';

export const gameProgression = 90;
export const gameProgressionTo = 90;
export const gameChapter = '05';
export const gameBackground = '04';
export const gamePage = 'debriefing';
export const gameProgressBar = true;
export const gameFrame = true;

export const gameSteps = [
  {
    id: 'home',
    component: (props) => (<><DebriefingHome {...props} /><DebriefingConvaincu {...props} /><DebriefingConvaincuResults {...props} /></>),
    saveOnEnd: false,
  },
];

const Debriefing = () => {

  // Contexts
  const { userInfos, avatarId, teamId, awards, userAxes, userJobFavorite, coachingCompetencesRating, cursusLikes, resetBookWithData, resetDebriefing, changeUserInfos, userJobs, addVideos } = useGame();

  // Reset all infos
  const resetOnStart = useCallback(() => {
    resetDebriefing();
    // ensure book is completed
    if (userInfos) {
      resetBookWithData({
        infos: userInfos,
        avatar: avatarId,
        team: teamId,
        awards,
        axes: userAxes,
        job: userJobFavorite ? JobsService.getJob(userJobFavorite) : null,
        withCursus: true,
        competencesRating: coachingCompetencesRating,
        cursusLikes,
      });
      // changeUserInfos('email', null);
      changeUserInfos('wantsContact', null);
    }
    // ensure all jobs videos
    if (userJobs) {
      const jobsVideosIds = ['Brief01', 'Brief02'];
      JobsService.populate(userJobs).forEach((job) => {
        if (job.media) {
          jobsVideosIds.push(job.media);
        }
      });
      if (jobsVideosIds.length) {
        addVideos(jobsVideosIds);
      }
    }
  }, [userInfos, avatarId, teamId, awards, userAxes, userJobFavorite, coachingCompetencesRating, cursusLikes, changeUserInfos, resetBookWithData, resetDebriefing, userJobs, addVideos]);

  return <SessionWrapper
    progression={gameProgression}
    progressionTo={gameProgressionTo}
    page={gamePage}
    steps={gameSteps}
    chapter={gameChapter}
    background={gameBackground}
    progressBar={gameProgressBar}
    frame={gameFrame}
    resetOnStart={resetOnStart}
    valuesToSave={{
      userInfos,
    }}
    nextPage={toPage}
  />;
}

export default Debriefing;
