import { useCallback, useEffect, useRef, useState } from 'react';

// Providers
import { useGame } from '../../providers/GameProvider';
import { useTheme, useThemeToast } from '../../providers/ThemeProvider';

// Services
import ProgressService from '../../services/progress.service';

// Components
import { VLoading } from "../../components/Vues";
import ToastMessage from '../../components/ToastMessage';

const SessionWrapper = ({
  progression,
  progressionTo,
  page,
  steps,
  background,
  progressBar,
  frame,
  chapter,
  valuesToSave,
  resetOnStart,
  awardOnStart,
  awardOnEnd,
  nextPage,
}) => {

  // Contexts
  const { isGameRestoredFromApi, appDebugMode, routeTo, confIsLoaded, updateProgression, maxProgression, awards, addAward, checkIfAwardNeedsToBeShown, needsCurrentSessionReload, setNeedsCurrentSessionReload } = useGame();
  const { changeBackground, setHasProgressBar, setHasFrame, setCurrentChapter } = useTheme();

  // Toasts
  const showToastApiError = useThemeToast(<ToastMessage><p>Il y a eu une erreur, merci de réessayer.</p></ToastMessage>);

  // States
  const [isLoading, setIsLoading] = useState(false);
  const [isNextLoading, setIsNextLoading] = useState(false);
  const [currentScreen, setCurrentScreen] = useState(null);
  const [lastSavedScreen, setLastSavedScreen] = useState(null);

  // Wait for config (questions selection)
  useEffect(() => {
    setIsLoading(!confIsLoaded);
    if (confIsLoaded) {
      setCurrentScreen(prevScreen => prevScreen === null ? 0 : prevScreen);
    }
  }, [confIsLoaded]);

  // Change chapter
  useEffect(() => {
    setCurrentChapter(chapter);
  }, [chapter, setCurrentChapter]);

  // Reset callback
  const onReset = useCallback(() => {
    if (resetOnStart) {
      resetOnStart();
    }
  }, [resetOnStart]);

  // Reload needed
  useEffect(() => {
    if (needsCurrentSessionReload) {
      setNeedsCurrentSessionReload(false);
      if (currentScreen > 0 && steps[0]) {
        onReset();
        setCurrentScreen(0);
      }
    }
  }, [needsCurrentSessionReload, setNeedsCurrentSessionReload, steps, currentScreen, onReset]);

  // On 1st time, reset if needed
  const resetOnlyOnce = useRef(null);
  useEffect(() => {
    if (!(resetOnlyOnce && resetOnlyOnce.current)) {
      onReset();
      resetOnlyOnce.current = true;
    }
  }, [onReset]);
  const resetOnlyOnceAfterApi = useRef(null);
  useEffect(() => {
    if (!(resetOnlyOnceAfterApi && resetOnlyOnceAfterApi.current) && isGameRestoredFromApi) {
      onReset();
      resetOnlyOnce.current = true;
      resetOnlyOnceAfterApi.current = true;
    }
  }, [isGameRestoredFromApi, onReset]);

  // init
  useEffect(() => {
    background && changeBackground(background);
  }, [background, changeBackground]);
  useEffect(() => {
    setHasProgressBar(progressBar);
  }, [progressBar, setHasProgressBar]);
  useEffect(() => {
    setHasFrame(frame);
  }, [frame, setHasFrame]);

  // Save
  const saveProgression = useCallback((newScreen, { onSuccess, onError }) => {
    if (lastSavedScreen !== newScreen) {
      const newProgression = ProgressService.getProgressionForScreen(progression, progressionTo, steps, newScreen);
      updateProgression(newProgression);
      const values = { userMaxProgression: maxProgression, ...(valuesToSave || {}) };
      if (appDebugMode) {
        console.log('progression', newProgression, ' - ', progression, ' à ', progressionTo, newScreen, awards, values);
      }
      ProgressService.saveProgressionOnStep(steps, newScreen, values, {
        onAlways: () => {
          const pageParameters = ProgressService.determineParametersForScreen(steps, newScreen, background, progressBar, frame);
          changeBackground(prevBackground => ProgressService.determineBackgroundForScreen(pageParameters, prevBackground));
          setHasProgressBar(prevProgressBar => ProgressService.determineProgressBarForScreen(pageParameters, prevProgressBar));
          setHasFrame(prevFrame => ProgressService.determineFrameForScreen(pageParameters, prevFrame));
          setLastSavedScreen(newScreen);
        },
        onSuccess: (message) => {
          const pageParameters = ProgressService.determineParametersForScreen(steps, newScreen, background, progressBar, frame);
          const awardId = ProgressService.determineAwardForScreen(pageParameters, null);
          if (awardId) {
            addAward(awardId, false, false);
          }
          onSuccess && onSuccess(message);
        },
        onError: (message) => {
          onError && onError(message);
        },
      });
    }
  }, [appDebugMode, lastSavedScreen, updateProgression, maxProgression, awards, addAward, progression, progressionTo, steps, valuesToSave, changeBackground, background, setHasProgressBar, progressBar, setHasFrame, frame]);

  useEffect(() => {
    if (awardOnStart) {
      addAward(awardOnStart, false, false);
    }
  }, [awardOnStart, addAward]);

  useEffect(() => {
    if (currentScreen !== null) {
      saveProgression(currentScreen, {});
      checkIfAwardNeedsToBeShown();
    }
  }, [currentScreen, saveProgression, checkIfAwardNeedsToBeShown]);

  // Navigate
  const onEnd = useCallback(() => {
    setIsNextLoading(true);
    if (steps[currentScreen + 1]) {
      setCurrentScreen(prevScreen => {
        if (steps[prevScreen + 1] && steps[prevScreen + 1].component) {
          return prevScreen + 1;
        }
        return prevScreen;
      });
      setIsNextLoading(false);
    } else {
      saveProgression(currentScreen + 1, {
        onSuccess: () => {
          setIsNextLoading(false);
          if (awardOnEnd) {
            addAward(awardOnEnd, false, true);
          }
          routeTo(`/game/${nextPage}`);
        },
        onError: () => {
          setIsNextLoading(false);
          showToastApiError();
        },
      });
    }
  }, [routeTo, currentScreen, setCurrentScreen, saveProgression, steps, nextPage, addAward, awardOnEnd, showToastApiError]);

  return (<>
    {!isLoading && steps[currentScreen] && steps[currentScreen].component ? <>
      {steps[currentScreen].component({ onEnd, isNextLoading })}
      {appDebugMode && <p className="text-center opacity-50 mb-7">Screen : {page} / {steps[currentScreen] && steps[currentScreen].id}</p>}
    </> : <VLoading label="CHARGEMENT" />}
  </>);
}

export default SessionWrapper;