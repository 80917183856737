const chapters = [
  {
    id: "00",
    name: "Accueil",
    url: "/game/accueil",
    displayInMenu: true,
  },
  {
    id: "01",
    name: "Briefing",
    url: "/game/brief",
    displayInMenu: true,
  },
  {
    id: "02",
    name: "Auditions",
    url: "/game/auditions",
    displayInMenu: true,
  },
  {
    id: "03",
    name: "Repérages",
    url: "/game/reperages",
    displayInMenu: true,
  },
  {
    id: "04",
    name: "Coaching",
    url: "/game/coaching",
    displayInMenu: true,
  },
  {
    id: "05",
    name: "Débriefing",
    url: "/game/debriefing",
    displayInMenu: true,
  },
  {
    id: "06",
    name: "Fin du jeu",
    url: "/game/fin",
    displayInMenu: true,
  }
];
const ChaptersService = {
  chapters: {},
  getChapters: () => Object.values(ChaptersService.chapters),
  getChaptersIds: () => Object.keys(ChaptersService.chapters),
  getChaptersKeyed: () => ChaptersService.chapters,
  getChapter: id => ChaptersService.chapters[id] || null,
};
chapters.forEach((chapter, index) => {
  ChaptersService.chapters[chapter.id] = {
    id: chapter.id,
    index,
    ...chapter,
  };
});

export default ChaptersService;