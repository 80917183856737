const profiles = [
  {
    id: "PG",
    name: "Gestionnaire",
    image: "profiles/05.png",
  },
  {
    id: "PA",
    name: "Analyste",
    image: "profiles/04.png",
  },
  {
    id: "PM",
    name: "Manager",
    image: "profiles/03.png",
  },
  {
    id: "PE",
    name: "Entrepreneur",
    image: "profiles/01.png",
  },
  {
    id: "PC",
    name: "Commercial",
    image: "profiles/02.png",
  },
];
const ProfilesService = {
  profiles: {},
  getProfiles: () => Object.values(ProfilesService.profiles),
  getProfilesIds: () => Object.keys(ProfilesService.profiles),
  getProfilesKeyed: () => ProfilesService.profiles,
  getProfile: id => ProfilesService.profiles[id] || null,
};
profiles.forEach(profile => {
  ProfilesService.profiles[profile.id] = {
    id: profile.id,
    ...profile,
  };
}); 

export default ProfilesService;