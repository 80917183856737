import { useCallback, useEffect, useState } from "react";

// Providers
import { useGame } from "../../providers/GameProvider";

// Services
import StudiesService from "../../services/studies.service";

// Components
import { VCenter, VTitle, VButton, VPage, VSpace, VInput, VSelect, VImage, VSticker, VCard, VMessenger } from "../../components/Vues";
import { SelectItem } from "@nextui-org/react";
import { FaCircleArrowDown, FaVolumeHigh } from "react-icons/fa6";

export const ageMin = 10;
export const ageMax = 99;

const AccueilInfos = ({ onEnd, isNextLoading }) => {

  // Contexts
  const { userInfos, changeUserInfos } = useGame();

  // States
  const [listAges, setListAges] = useState([]);
  const [listStudies, setListStudies] = useState([]);

  // Lists
  useEffect(() => {
    const ages = [];
    let iloop = ageMin;
    while (iloop < ageMax) {
      ages.push({ label: String(iloop), value: String(iloop) });
      iloop++;
    }
    setListAges(ages);
    setListStudies(StudiesService.getStudies());
  }, []);

  // States
  const [firstName, setFirstName] = useState(userInfos.firstName);
  const [lastName, setLastName] = useState(userInfos.lastName);
  const [age, setAge] = useState(new Set([]));
  const [studies, setStudies] = useState(new Set([]));

  // Load values
  useEffect(() => {
    setFirstName(userInfos.firstName);
    setLastName(userInfos.lastName);
    setAge(new Set(userInfos.age ? [`${userInfos.age}`] : []));
    setStudies(new Set(userInfos.studies ? [`${userInfos.studies}`] : []));
  }, [userInfos, setFirstName, setLastName, setAge, setStudies]);

  // Events
  const onChangeFirstName = useCallback((e) => {
    changeUserInfos('firstName', e.target.value);
  }, [changeUserInfos]);
  const onChangeLastName = useCallback((e) => {
    changeUserInfos('lastName', e.target.value);
  }, [changeUserInfos]);
  const onChangeAge = useCallback((e) => {
    let value = new Set(e.target.value.length > 0 ? e.target.value.split(',') : []);
    changeUserInfos('age', !value.size ? null : parseInt(Array.from(value)[0], 10));
  }, [changeUserInfos]);
  const onChangeStudies = useCallback((e) => {
    let value = new Set(e.target.value.length > 0 ? e.target.value.split(',') : []);
    changeUserInfos('studies', !value.size ? null : Array.from(value)[0]);
  }, [changeUserInfos]);

  const goToNext = useCallback(() => {
    onEnd();
  }, [onEnd]);

  return (
    <VPage>

      <VCenter>

        <VImage width={300} src="logo-ifcast.png" alt="Logo Ifcast"></VImage>

        <VTitle>BIENVENUE</VTitle>

        <div className="text-2xl pb-2">
          <p>Bienvenue dans « IfCast », le serious game qui t’accompagne à la découverte de ton parcours sur mesure dans l’univers de l’assurance.</p>
        </div>

        <VSticker><FaVolumeHigh className="h-8" /></VSticker>

        <VCard className="font-medium">
          <VSpace>
            <p>Ce serious game comporte du son.</p>
            <p >Pense à régler le niveau sonore.</p>
          </VSpace>
        </VCard>

        <VMessenger audio="00.mp3" autoPlay={false} />

        <div className="text-2xl">
          <p className="pb-4 ">Prêt à découvrir la place qui t’attend ? </p>
        </div>

        <VSpace />

        <VTitle>
          <p>FAISONS <span className="font-bold">CONNAISSANCE</span></p>
        </VTitle>

        <VSpace />

        <p className="font-medium">Complète les champs suivants :</p>

        <VInput
          type="text"
          label="Ton prénom"
          aria-label="Ton prénom"
          labelPlacement="inside"
          radius="lg"
          size="sm"
          isInvalid={false}
          value={firstName || ''}
          onChange={onChangeFirstName}
        />

        <VInput
          type="text"
          label="Ton nom"
          aria-label="Ton nom"
          isInvalid={false}
          value={lastName || ''}
          onChange={onChangeLastName}
        />

        <VSelect
          label="Ton âge"
          aria-label="Ton âge"
          disableSelectorIconRotation
          selectorIcon={<div><FaCircleArrowDown className="text-ifpass-purple" /></div>}
          items={listAges}
          selectionMode="single"
          selectedKeys={age}
          onChange={onChangeAge}
        >
          {(listAges) => <SelectItem key={listAges.value}>{listAges.label}</SelectItem>}
        </VSelect>

        <VSelect
          label="Diplôme le plus élevé obtenu"
          aria-label="Ton diplôme le plus évelé obtenu"
          disableSelectorIconRotation
          selectorIcon={<div><FaCircleArrowDown className="text-ifpass-purple" /></div>}
          items={listStudies}
          selectionMode="single"
          selectedKeys={studies}
          onChange={onChangeStudies}
        >
          {(listStudies) => <SelectItem key={listStudies.value}>{listStudies.label}</SelectItem>}

        </VSelect>

        <VSpace />

        <VButton onPress={goToNext} isLoading={isNextLoading} isDisabled={!((firstName !== "") && (lastName !== "") && (age.size > 0) && (studies.size > 0))}>valider</VButton>

      </VCenter>

    </VPage>
  );
}

export default AccueilInfos;