import { useCallback, useEffect, useRef, useState } from 'react';

// Providers
import { useGame } from '../../providers/GameProvider';

// Services
import AxesService from '../../services/axes.service.js';

// Components
import { VCenter, VTitle, VButton, VPage, VSpace, VMessenger, VImage, VLoading, VCard } from "../../components/Vues";
import { profilesBgColors, profilesBgLightColors } from '../ProfileProgress.js';

// -- Swiper
import { Pagination, A11y, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// import Carousel effect module
import EffectCarousel from '../../components/swiper/effect-carousel.esm.js';
// import Carousel effect styles
import '../../components/swiper/effect-carousel.css';

const ProfileDescriptionLead = ({ children, id }) => (
  <p className={`${profilesBgColors[id] || '!border-primary/50'} bg-black/25 border-0 text-white rounded-large font-bold text-md px-2 py-2 mt-2 mb-2`}>{children}</p>
);

const profilesInfos = {
  PE: {
    description: <>
      <p>Tu as de l’ambition et ça se sait ! Très à l’aise sur le plan relationnel, tu n’as aucune difficulté à t’exprimer et à prendre des décisions. Convaincre, diriger, vendre, motiver : ça te plait. Le secteur de l’assurance offre de belles opportunités de carrière pour les profils enthousiastes comme le tien&nbsp;!</p>
      <ProfileDescriptionLead id="PE">Ce qui te permet de progresser : un environnement compétitif et l’expérimentation en situation&nbsp;!</ProfileDescriptionLead>
    </>,
    audio: {
      file: '03_06.mp3',
      description: <>
        Tu as de l’ambition et ça se sait&nbsp;! Très à l’aise sur le plan relationnel, tu n’as aucune difficulté à t’exprimer et à prendre des décisions. Convaincre, diriger, vendre, motiver : ça te plait. Le secteur de l’assurance offre de belles opportunités de carrière pour les profils enthousiastes comme le tien&nbsp;! Ce qui te permet de progresser : un environnement compétitif et l’expérimentation en situation&nbsp;!
      </>
    },
  },
  PC: {
    description: <>
      <p>Débrouillard et fonceur, tu es un véritable optimiste&nbsp;! Ton caractère enthousiaste et chaleureux te permet d’être à l’écoute des besoins des clients et de concrétiser des ventes. Ton énergie et ton goût du challenge sont des qualités rares qui te permettent de communiquer, de conseiller et persuader. </p>
      <ProfileDescriptionLead id="PC">Ce qui te permet de progresser : le terrain et atteindre tes objectifs&nbsp;!</ProfileDescriptionLead>
    </>,
    audio: {
      file: '03_03.mp3',
      description: <>
        Débrouillard et fonceur, tu es un véritable optimiste&nbsp;! Ton caractère enthousiaste et chaleureux te permet d’être à l’écoute des besoins des clients et de concrétiser des ventes. Ton énergie et ton goût du challenge sont des qualités rares qui te permettent de communiquer, de conseiller et persuader. Ce qui te permet de progresser :  le terrain et atteindre tes objectifs&nbsp;!
      </>
    },
  },
  PM: {
    description: <>
      <p>Tu possèdes un grand sens du relationnel. Ton truc à toi, c’est l’esprit d’équipe. Tu es persuadé qu’à plusieurs on va plus loin, et que les différences sont une force. Cette ouverture d’esprit et tes capacités à comprendre, encourager et fédérer sont une vraie force dans tout un panel de métiers du secteur de l’assurance.</p>
      <ProfileDescriptionLead id="PM">Ce qui te permet de progresser : la collaboration</ProfileDescriptionLead>
    </>,
    audio: {
      file: '03_07.mp3',
      description: <>
        Tu possèdes un grand sens du relationnel. Ton truc à toi, c’est l’esprit d’équipe. Tu es persuadé qu’à plusieurs on va plus loin, et que les différences sont une force. Cette ouverture d’esprit et tes capacités à comprendre, encourager et fédérer sont une vraie force dans tout un panel de métiers du secteur de l’assurance. Ce qui te permet de progresser : la collaboration&nbsp;!
      </>
    },
  },
  PA: {
    description: <>
      <p>Tu as besoin de comprendre&nbsp;! Sans cesse à la recherche de sens, ta curiosité te pousse à analyser et trouver des solutions. Tu es à l’aise quand il s’agit de rechercher des informations, de traiter des données, bref tu es à l’aise avec les activités intellectuelles. Le monde de l’assurance est un monde d’idées et de stratégies, tu vas pouvoir y contribuer&nbsp;!</p>
      <ProfileDescriptionLead id="PA">Ce qui te permet de progresser : les connaissances théoriques&nbsp;!</ProfileDescriptionLead>
    </>,
    audio: {
      file: '03_04.mp3',
      description: <>
        Tu as besoin de comprendre&nbsp;! Sans cesse à la recherche de sens, ta curiosité te pousse à analyser et trouver des solutions. Tu es à l’aise quand il s’agit de rechercher des informations, de traiter des données, bref tu es à l’aise avec les activités intellectuelles. Le monde de l’assurance est un monde d’idées et de stratégies, tu vas pouvoir y contribuer&nbsp;! Ce qui te permet de progresser : les connaissances théoriques&nbsp;!
      </>
    },
  },
  PG: {
    description: <>
      <p>Ordonné et méticuleux, tu préfères le back office (la gestion) au front office (le commercial). Tu es une personne structurée, consciencieuse et organisée. Ton sérieux et ton autonomie sont des qualités recherchées dans bon nombre de métiers de l’assurance.</p>
      <ProfileDescriptionLead id="PG">Ce qui te permet de progresser : des consignes et des méthodes claires&nbsp;!</ProfileDescriptionLead>
    </>,
    audio: {
      file: '03_05.mp3',
      description: <>
        Ordonné et méticuleux, tu préfères le back office (la gestion) au front office (le commercial). Tu es une personne structurée, consciencieuse et organisée. Ton sérieux et ton autonomie sont des qualités recherchées dans bon nombre de métiers de l’assurance. Ce qui te permet de progresser : des consignes et des méthodes claires&nbsp;!
      </>
    },
  },
};

const AuditionsProfiles = ({ onEnd, isNextLoading }) => {

  // Contexts
  const { confIsLoaded, config, addAward, userAxes } = useGame();

  // States
  const [isLoading, setIsLoading] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [activeProfile, setActiveProfile] = useState(null);
  const [viewedScreens, setViewedScreens] = useState([]);

  // Wait for config (questions selection)
  useEffect(() => {
    setIsLoading(!confIsLoaded);
  }, [confIsLoaded]);

  // Build profiles infos
  useEffect(() => {
    const profiles = AxesService.sort(AxesService.compute(userAxes, profilesInfos));
    setProfiles(profiles);
  }, [config, userAxes]);

  // Default profile
  useEffect(() => {
    setActiveProfile(prevProfile => {
      return prevProfile && profiles[prevProfile] ? prevProfile : (profiles.length ? 0 : null);
    });
  }, [profiles]);

  // useEffect(() => {
  //   console.log(profiles, profiles[activeProfile]);
  // }, [profiles, activeProfile]);

  useEffect(() => {
    if (viewedScreens.length === 3) {
      addAward('rigueur', true);
    }
  }, [viewedScreens, addAward]);

  const onChangeSlide = useCallback(({ realIndex }) => {
    setActiveProfile(profiles[realIndex] ? realIndex : 0);
    if (!viewedScreens.includes(realIndex)) {
      setViewedScreens([...viewedScreens, realIndex]);
    }
  }, [profiles, viewedScreens]);

  // Refs
  const swiperProfilesRef = useRef(null);

  const onClickSlide = useCallback((index) => (e) => {
    if (swiperProfilesRef.current) {
      swiperProfilesRef.current.swiper.slideTo(index);
    }
  }, []);

  const goToNext = useCallback(() => {
    onEnd();
  }, [onEnd]);

  const [waitFewSeconds, setWaitFewSeconds] = useState(false);
  useEffect(() => {
    // Use setTimeout to update the message after 2000 milliseconds (2 seconds)
    const timeoutIdFewSeconds = setTimeout(() => {
      setWaitFewSeconds(true);
    }, 7000);

    // Cleanup function to clear the timeout if the component unmounts
    return () => clearTimeout(timeoutIdFewSeconds);
  }, []); // Empty dependency array ensures the effect runs only once

  return (

    <VPage>
      {(waitFewSeconds === true) &&

        <VCenter>
          <VTitle>
            <p>TON PROFIL <span className="font-bold">EN DÉTAIL</span></p>
          </VTitle>

          <div className="w-full">
            <VCenter>

              {!isLoading && activeProfile !== null && profiles && profiles[activeProfile] ? <>

                <Swiper
                  ref={swiperProfilesRef}
                  onSlideChange={onChangeSlide}
                  modules={[Pagination, Navigation, A11y, EffectCarousel]}
                  navigation={true}
                  allowTouchMove={true}
                  simulateTouch={true}
                  spaceBetween={50}
                  slidesPerView={2}
                  pagination={{ clickable: true }}
                  effect={'carousel'}
                  speed={500}
                  loop={false}
                  grabCursor={true}
                >

                  {profiles.map((profile, index) => (
                    <SwiperSlide key={profile.id}
                      virtualIndex={index}
                      className={`${profilesBgColors[profile.id] || '!bg-primary'} rounded-lg px-4 sm:px-12 py-2 text-white font-bold text-sm sm:text-lg uppercase`}
                      onClick={onClickSlide(index)}
                    >
                      <div className="absolute left-4 text-left">
                        <p>{profile.label.toUpperCase()}</p>
                        <p className="text-2xl font-medium relative top-[-4px]">{Math.round(profile.score * 100)}%</p>
                      </div>
                      <VCenter>
                        <VImage src={profile.image} width={140} radius="none" className="bg-white rounded-full my-8" />
                      </VCenter>
                    </SwiperSlide>
                  ))}

                </Swiper>

                {profiles[activeProfile].description || profiles[activeProfile].audio ? <>
                  <VCard className="mt-6 bg-white/80 text-black px-6 py-4 w-auto max-w-[440px]">
                    <div className="font-medium text-md">
                      <p className={`uppercase font-bold text-2xl mb-2`}>{profiles[activeProfile].label}</p>
                      <div className={`${profilesBgLightColors[profiles[activeProfile].id] || '!bg-primary'} h-[2px] w-full mb-3`} />
                      {profiles[activeProfile].description}
                    </div>
                  </VCard>
                  {profiles[activeProfile].audio.file && <VMessenger audio={profiles[activeProfile].audio.file}>{profiles[activeProfile].audio.description}</VMessenger>}
                </> : <></>}

              </> : <VLoading label="CHARGEMENT" />}

            </VCenter>
          </div>

          <VSpace>
            <VButton onPress={goToNext} isLoading={isNextLoading}>Continuer</VButton>
          </VSpace>

        </VCenter >
      }
    </VPage >

  );
}

export default AuditionsProfiles;