import axios from 'axios';

import ApiService from './api.service';
import AssetsService from './assets.service';

const configKey = 'ifpassGameConfig';
const configSourceKey = 'ifpassGameConfigSource';
const configExpiresKey = 'ifpassGameConfigExpires';

const ConfigService = {
    currentConfig: null,
    isLoading: false,
    waiters: [],
    load: (cb) => {
        if (ConfigService.currentConfig !== null && !Object.keys(ConfigService.currentConfig).length) {
            cb && cb(ConfigService.currentConfig);
        } else {
            ConfigService.loadFromLocalStorage((config) => {
                if (!config || !Object.keys(config).length) {
                    ConfigService.loadFromApi((configFromApi) => {
                        ConfigService.currentConfig = configFromApi;
                        console.log('from api 2', configFromApi);
                        cb && cb(configFromApi);
                    });
                } else {
                    ConfigService.currentConfig = config;
                    cb && cb(config);
                }
            });
        }
    },
    loadFromLocalStorage: (cb) => {
        try {
            let config = JSON.parse(localStorage.getItem(configKey) || '{}');
            config = !Object.keys(config).length ? null : config;
            const expires = localStorage.getItem(configExpiresKey);
            if (expires && expires <= (new Date()).getTime()) {
                config = null;
                // console.log('Config from local storage has expired');
            }
            // console.log('loaded from local storage');
            cb && cb(config);
        } catch(e) {
            cb && cb(null);
        }
    },
    loadFromPublicJson: (cb) => {
        axios.get(AssetsService.getUrl('config.json')).then((json) => {
            const config = json.data;
            localStorage.setItem(configKey, JSON.stringify(config));
            localStorage.setItem(configSourceKey, 'json');
            localStorage.setItem(configExpiresKey, (new Date()).getTime() + 1000 * 60 * 60 * 1); // Expires in 1 hour from public json
            // console.log('loaded from API', config);
            cb && cb(config);
        });
    },
    loadFromApi: (cb) => {
        if (ConfigService.isLoading) {
            ConfigService.waiters.push(cb);
        } else {
            ConfigService.isLoading = true;
            ConfigService.waiters = [];
            ConfigService.waiters.push(cb);
            const callWaiters = (config) => {
                ConfigService.waiters.forEach((waiterCb) => {
                    waiterCb && waiterCb(config);
                });
                ConfigService.isLoading = false;
                ConfigService.waiters = [];
            };
            ApiService.getConfig({
                onSuccess: (config) => {
                    localStorage.setItem(configKey, JSON.stringify(config));
                    localStorage.setItem(configSourceKey, 'api');
                    localStorage.setItem(configExpiresKey, (new Date()).getTime() + 1000 * 60 * 10); // Expires in 10 minutes from API
                    callWaiters(config);
                },
                onError: (message) => {
                    // console.log('error from API', message);
                    callWaiters(null);
                }
            });
        }
    },
};

export default ConfigService;