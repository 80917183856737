import { useCallback, useState, useEffect, useRef } from "react";

// Providers
import { useGame } from '../../providers/GameProvider';
import { useThemeToast } from "../../providers/ThemeProvider";

// Services
import JobsService from "../../services/jobs.service";

// Components
import { Button, ModalBody, ModalContent, ModalFooter, RadioGroup } from "@nextui-org/react";
import { VCenter, VMessenger, VSpace, VSticker, VTutor, VModalConfirm, VInput } from "../Vues";
import { RadioText } from "../RadioText";
import ToastMessage from "../ToastMessage";

const DebriefingConvaincu = ({ onEnd, isNextLoading }) => {

  // Contexts
  const { configJobsIsLoaded, userJobs, userJobFavorite, userInfos, changeUserInfos, addAward } = useGame();

  // Toasts
  const showToast = useThemeToast(<ToastMessage image="01"><p>Si tu hésites encore, je peux te conseiller d’autres pistes !</p></ToastMessage>);

  // States
  const [isLoading, setIsLoading] = useState(true);
  const [job, setJob] = useState(null);
  const [convaincu, setConvaincu] = useState(null);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [email, setEmail] = useState(null);
  const [emailError, setEmailError] = useState(null);

  // email
  useEffect(() => {
    setEmail(prevEmail => !prevEmail && userInfos.email ? userInfos.email : (!prevEmail ? '' : prevEmail));
  }, [userInfos, setEmail]);

  // Get favorite job
  useEffect(() => {
    if (configJobsIsLoaded) {
      setJob({
        ...(userJobs[userJobFavorite] || {}),
        ...(JobsService.getJob(userJobFavorite)),
      });
    }
  }, [configJobsIsLoaded, userJobs, userJobFavorite, setJob]);

  // Finish loading state
  useEffect(() => {
    if (job) {
      setIsLoading(false);
    }
  }, [job, setIsLoading]);

  // validate email
  useEffect(() => {
    if (email) {
      const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      setEmailError(!emailRegex.test(email) ? 'Ton adresse email n\'est pas valide.' : null);
    }
  }, [email, setEmailError]);

  // show toast
  const timerTooLong = useRef(null);
  useEffect(() => {
    if (timerTooLong && timerTooLong.current) {
      clearTimeout(timerTooLong.current);
    }
    timerTooLong.current = setTimeout(() => {
      showToast();
    }, 3000);
    return () => {
      clearTimeout(timerTooLong.current);
    };
  }, [showToast]);

  // Events

  const openEmailModal = useCallback(() => {
    setIsEmailModalOpen(true);
  }, [setIsEmailModalOpen]);

  const onChangeAnswer = useCallback((selected) => {
    setConvaincu(selected);
    changeUserInfos('wantsContact', selected === 'oui');
    if (selected === 'oui') {
      addAward('communication', true, false);
    }
    if (!email && selected === 'oui') {
      openEmailModal();
    }
  }, [email, setConvaincu, changeUserInfos, openEmailModal, addAward]);

  const onTypeEmail = useCallback(({ target: { value } }) => {
    setEmail(String(value).toLowerCase());
  }, [setEmail]);

  const validateEmailAndGoToNext = useCallback(() => {
    if (!emailError) {
      changeUserInfos('email', String(email).toLowerCase());
      setIsEmailModalOpen(false);
    } else {
      setEmailError('Tu dois saisir une adresse email pour être recontacté.');
    }
  }, [email, emailError, changeUserInfos, setIsEmailModalOpen]);

  const [waitFewSeconds, setWaitFewSeconds] = useState(false);
  useEffect(() => {
    // Use setTimeout to update the message after 2000 milliseconds (2 seconds)
    const timeoutIdFewSeconds = setTimeout(() => {
      setWaitFewSeconds(true);
    }, 10000);

    // Cleanup function to clear the timeout if the component unmounts
    return () => clearTimeout(timeoutIdFewSeconds);
  }, []); // Empty dependency array ensures the effect runs only once

  return (

    <div>

      {(waitFewSeconds === true) &&

        <VCenter>

          <VMessenger audio="06_02.mp3"  >
            Es-tu convaincu par ton rôle ?
          </VMessenger>

          <VSticker>Convaincu par ton rôle ?</VSticker>
          <VTutor id="10" />

          {!isLoading && <VCenter>

            {job && <p className="text-4xl uppercase font-bold my-8">{job.name}</p>}

            <RadioGroup onValueChange={onChangeAnswer} value={convaincu}>
              <RadioText classNames={{ base: 'bg-white/90 hover:bg-white/70' }} key='oui' value='oui'>Oui, j’ai envie de tenter ma chance et d’être contacté par email par l’équipe de l’Ifpass.</RadioText>
              <RadioText classNames={{ base: 'bg-white/90 hover:bg-white/70' }} key='non' value='non'>Pas vraiment, j’hésite encore.</RadioText>
            </RadioGroup>

            <VSpace size="5" />

          </VCenter>}

        </VCenter >

      }

      <VModalConfirm isOpen={isEmailModalOpen}>
        <ModalContent>
          <ModalBody>
            <p className="text-2xl leading-5 font-semibold">Saisis ton adresse email pour être recontacté</p>
            <VInput
              type="text"
              label="Ton adresse email"
              aria-label="Ton adresse email"
              labelPlacement="inside"
              radius="lg"
              size="md"
              isInvalid={email && emailError !== null}
              value={email}
              onChange={onTypeEmail}
            />
            {(email && emailError) && <p className="text-white -mt-5">{emailError}</p>}
          </ModalBody>
          <ModalFooter>
            <Button color="light" variant="light" className='text-md font-medium uppercase' onPress={() => setIsEmailModalOpen(false)}>Annuler</Button>
            <Button color="primary" className='text-md font-medium uppercase' onPress={validateEmailAndGoToNext} isDisabled={!email || email === '' || emailError !== null}>Confirmer</Button>
          </ModalFooter>
        </ModalContent>
      </VModalConfirm>

    </div >

  );
}

export default DebriefingConvaincu;