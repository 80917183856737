import { useEffect, useState } from 'react';
import { Avatar } from "@nextui-org/react";

// Providers
import { useGame } from '../../providers/GameProvider';

// Components
import { VCenter, VTitle, VCard, VMessenger, VSticker, VLoading } from "../../components/Vues";
import AxesService from '../../services/axes.service';
import ProfileProgress from '../ProfileProgress';

const AuditionsScores = () => {

  // Contexts
  const { confIsLoaded, config, awards, userAxes, mergeWithBook } = useGame();

  // States
  const [isLoading, setIsLoading] = useState(false);
  const [profiles, setProfiles] = useState([]);

  // Wait for config (questions selection)
  useEffect(() => {
    setIsLoading(!confIsLoaded);
  }, [confIsLoaded]);

  // Per profile score
  useEffect(() => {
    const profiles = AxesService.compute(userAxes);
    setProfiles(AxesService.sort(profiles));
  }, [config, userAxes]);

  // Update book
  useEffect(() => {
    if (userAxes) {
      mergeWithBook({
        axes: userAxes,
        awards,
      });
    }
  }, [awards, userAxes, mergeWithBook]);

  return (

    <VCenter>
      <VTitle>
        <p>DÉCOUVRE <span className="font-bold">TON PROFIL</span></p>
      </VTitle>

      <VMessenger audio="03_02.mp3"  >
        Quel profil étonnant ! Tu as quelque chose de singulier qui me plait !
      </VMessenger>

      <VSticker>BILAN</VSticker>
      <VCard className="py-6 px-6">
        <VCenter>

          {!isLoading ? <>
            {profiles.map(profile => (
              <div className='flex w-full gap-2' key={profile.id}>
                <Avatar src={"/images/" + profile.image} className="w-12" />
                <ProfileProgress key={profile.id} profile={profile}
                  classNames={{
                    base: "mb-3 !gap-0",
                    label: "font-medium text-black",
                    value: "font-medium text-black ",
                  }}
                />
              </div>
            ))}
          </> : <VLoading label="CHARGEMENT" />}

        </VCenter>
      </VCard>

    </VCenter>

  );
}

export default AuditionsScores;