// Components
import { VCenter, VTitle, VTutor, VMessenger } from "../../components/Vues";

const ReperagesEnqueteIntro = () => {

  return (

    <VCenter>
      <VTitle>
        <p>L'ENQUÊTE <span className="font-bold">MÉTIER</span></p>
      </VTitle>

      <VTutor id="01" />

      <VMessenger audio="04_02.mp3" >
        Le principe est simple : je te propose des activités que j’ai spécialement sélectionnées pour toi. Si ça te plait, tu cliques. Si ça ne te tente pas ou que ça te laisse indifférent, on laisse tomber !
      </VMessenger>

    </VCenter>

  );
}

export default ReperagesEnqueteIntro;