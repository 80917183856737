import { useCallback } from "react";

// Components
import { VCenter, VImage, VTitle, VSticker, VCard, VButton, VPage, VSpace, VMessenger } from "../../components/Vues";
import { FaArrowRight, FaClapperboard } from "react-icons/fa6";

const AuditionsEnd = ({ onEnd, isNextLoading }) => {

  const goToNext = useCallback(() => {
    onEnd();
  }, [onEnd]);

  return (

    <VPage>

      <VCenter>
        <VTitle>
          <p><span className="font-bold">BRAVO !</span></p>
        </VTitle>

        <VMessenger audio="03_08.mp3" >
          C’est confirmé, ton profil m’intéresse ! Tu as passé avec succès les auditions. La première partie de ton book est complète ! On passe au repérage ?
        </VMessenger>


        <VCard className="mb-0">
          <VCenter>
            <VImage src="tutor/11.png" alt="Bravo" className="w-[300px]" />
            <div className="relative top-[-80px]">
              <VSticker>Étape terminée !</VSticker>
            </div>
          </VCenter>
        </VCard>

        <VSpace className="my-8">
          <VButton onPress={goToNext} isLoading={isNextLoading} endContent={<div className="flex gap-2"><FaArrowRight /><FaClapperboard /></div>}>Passer aux repérages</VButton>
        </VSpace>

      </VCenter >

    </VPage>
  );
}

export default AuditionsEnd;